import PageHeader from "../../components/PageHeader";
import { Container, Tabs, Tab, Row, Col, Button } from "react-bootstrap";
import Loader from "../../components/Loader/Loader";
import TaskDetailsModal from "../../components/Tasks/TaskDetailsModal";
import React, { useState, useContext } from "react";
import TasksTable from "../../components/Tasks/TasksTable";
import useFetchTasks from "../../hooks/tasks/useFetchTasks";
import useUpdateTask from "../../hooks/tasks/useUpdateTask";
import AuthContext from "../../context/AuthProvider";
import TaskFilters from "../../components/Tasks/TaskFilters";
import { Search } from "react-bootstrap-icons";
import SearchTasksModal from "../../components/Tasks/SearchTasksModal";
const TasksPage = () => {
  const pageTitle = "Tasks";
  const [activeTab, setActiveTab] = useState("all");
  const [selectedTask, setSelectedTask] = useState(null);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [filters, setFilters] = useState({
    viewed: false,
    notViewed: false,
    type: false,
    priority: false,
    createdByMe: false,
    assignedToMe: false,
    completedByMe: false,
  });
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");

  const { auth } = useContext(AuthContext);
  const { isLoading, error, data } = useFetchTasks(
    convertFiltersToParams(filters, activeTab, auth, sortField, sortOrder)
  );
  const filteredData = data?.filter(task => task.status !== "Deleted");
  const handleShowDetails = (task) => {
    setSelectedTask(task);
    setShowDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedTask(null);
  };
  const { mutate } = useUpdateTask();

  const handleViewTask = (task) => {
    mutate({
      _id: task._id,
      seen: {
        viewed: true,
        last_viewed_date: new Date(),
      },
    });
  };

  const handleArchive = (task) => {
    mutate({ _id: task._id, status: "Deleted" });
  };

  const handleResumeArchive = (task) => {
    mutate({ _id: task._id, status: "Todo" });
  };
  const handleStatusChange = (task, newStatus) => {
    mutate({ _id: task._id, status: newStatus });
  };
  const handlePriorityChange = (task, newPriority) => {
    mutate({ _id: task._id, priority: newPriority });
  };

  const handleFilterChange = (e) => {
    const { name, checked, value, type } = e.target;
    console.log(checked);
    setFilters({
      ...filters,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSortFieldChange = (e) => {
    setSortField(e.target.value);
  };

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  };

  if (isLoading) return <Loader />;

  if (error) return "An error occured: " + error.message;
  return (
    <>
      <Container className="page-container">
        <PageHeader title={pageTitle} className="ems-page-title"></PageHeader>
        <Button
          variant="outline-secondary iconButton"
          onClick={() => setShowSearchModal(true)}
        >
          <Search /> Search
        </Button>
        <Row className="mb-3">
          <Col className="d-flex justify-content-end">
            <TaskFilters
              filters={filters}
              setFilters={setFilters}
              handleFilterChange={handleFilterChange}
              handleSortFieldChange={handleSortFieldChange}
              handleSortOrderChange={handleSortOrderChange}
            />
          </Col>
        </Row>
        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
          <Tab eventKey="all" title="All">
            <TasksTable
              data={filteredData}
              handleShowDetails={handleShowDetails}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
              handleStatusChange={handleStatusChange}
              handlePriorityChange={handlePriorityChange}
              handleViewTask={handleViewTask}
            />
          </Tab>
          <Tab eventKey="Todo" title="Todo">
            <TasksTable
              data={data}
              handleShowDetails={handleShowDetails}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
              handleStatusChange={handleStatusChange}
              handlePriorityChange={handlePriorityChange}
              handleViewTask={handleViewTask}
            />
          </Tab>
          <Tab eventKey="InProgress" title="InProgress">
            <TasksTable
              data={data}
              handleShowDetails={handleShowDetails}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
              handleStatusChange={handleStatusChange}
              handlePriorityChange={handlePriorityChange}
              handleViewTask={handleViewTask}
            />
          </Tab>
          <Tab eventKey="Completed" title="Completed">
            <TasksTable
              data={data}
              handleShowDetails={handleShowDetails}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
              handleStatusChange={handleStatusChange}
              handlePriorityChange={handlePriorityChange}
              handleViewTask={handleViewTask}
            />
          </Tab>
          <Tab eventKey="Deleted" title="Deleted">
            <TasksTable
              data={data}
              handleShowDetails={handleShowDetails}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
              handleStatusChange={handleStatusChange}
              handlePriorityChange={handlePriorityChange}
              handleViewTask={handleViewTask}
            />
          </Tab>
        </Tabs>
        <TaskDetailsModal
          show={showDetailsModal}
          handleClose={handleCloseDetailsModal}
          task={selectedTask}
        />
        <SearchTasksModal
          show={showSearchModal}
          handleClose={() => setShowSearchModal(false)}
        />
      </Container>
    </>
  );
};

const convertFiltersToParams = (
  filters,
  status,
  auth,
  sortField,
  sortOrder
) => {
  let params = {};
  if (status && status !== "all") params["status"] = status;
  if (filters.viewed) params["seen.viewed"] = true;
  if (filters.notViewed) params["seen.viewed"] = false;
  if (filters.priority) params["priority"] = filters.priority;
  if (filters.type) params["type"] = filters.type;
  if (filters.createdByMe) params["created_by"] = auth?.user?.id;
  if (filters.completedByMe) params["completed_by"] = auth?.user?.id;
  if (filters.assignedToMe) params["assigned_to"] = auth?.user?.id;
  params["sortBy"] = sortField;
  params["sortOrder"] = sortOrder;

  return params;
};

export default TasksPage;
