import { Modal, Button } from "react-bootstrap";
import React from "react";
import ConsultantDetailsTable from "./ConsultantDetailsTable";
import "../../css/DetailsModal.css";
//component to display consultant details in a modal
const ConsultantDetailsModal = ({ show, handleClose, consultant }) => {
  if (!consultant) return null;
  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Consultant Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ConsultantDetailsTable
            consultant={consultant}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConsultantDetailsModal;
