import React from 'react';
import { Badge } from 'react-bootstrap';
//component to display the status of the engagement in a badge
const EngagementStatusBadge = ({ state, is_extended}) => {
  let bg = null;
  switch (state) {
    case 'Offer':
      bg = 'info';
      break;
    case 'Initiation':
      bg = 'primary';
      break;
    case 'Active':
      bg = 'success';
      break;
    case 'Closed':
      bg = 'dark';
      break;
    default:
      bg = null;
  }

  return (
    <>
      {bg && (<Badge bg={bg} className="me-2">
        {state}
      </Badge>
      )}
      {is_extended && (
        <Badge bg="success" className="me-2">
          Extended
        </Badge>
      )}
    </>
  );
};

export default EngagementStatusBadge;
