// src/pages/DashboardPage.js
import React, { useState, useContext } from 'react';
import { Container } from "react-bootstrap";
import PageHeader from "../../components/PageHeader";
import TasksTable from '../../components/Tasks/TasksTable';
import Loader from '../../components/Loader/Loader';
import TaskDetailsModal from '../../components/Tasks/TaskDetailsModal';
import EngagementDetailsModal from '../../components/Engagements/EngagementDetailsModal';
import CloseReasonModal from '../../components/Engagements/CloseReasonModal';
import EngagementsTable from '../../components/Engagements/EngagementsTable';
import DiscussionsTable from '../../components/Discussions/DiscussionsTable';
import DiscussionDetailsModal from '../../components/Discussions/DiscussionDetailsModal';
import useFetchTasks from '../../hooks/tasks/useFetchTasks';
import useFetchEngagements from '../../hooks/engagements/useFetchEngagements';
import useFetchDiscussions from '../../hooks/discussions/useFetchDiscussions';
import useUpdateTask from "../../hooks/tasks/useUpdateTask";
import useUpdateEngagement from "../../hooks/engagements/useUpdateEngagement";
import useUpdateDiscussion from "../../hooks/discussions/useUpdateDiscussion";
import AuthContext from "../../context/AuthProvider";

const isDueThisWeek = (deadline) => {
    const now = new Date();
    const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
    startOfWeek.setHours(0, 0, 0, 0);
    const endOfWeek = new Date(now.setDate(startOfWeek.getDate() + 6));
    endOfWeek.setHours(23, 59, 59, 999);

    return deadline >= startOfWeek && deadline <= endOfWeek;
};

const DashboardPage = () => {
    const { isLoading: tasksLoading, error: tasksError, data: tasksData } = useFetchTasks();
    const { isLoading: engagementsLoading, error: engagementsError, data: engagementsData } = useFetchEngagements({
        sortBy: 'updatedAt',
        sortOrder: 'desc',
        limit: 5,
    });
    const { isLoading: discussionsLoading, error: discussionsError, data: discussionsData } = useFetchDiscussions({
        sortBy: 'updatedAt',
        sortOrder: 'desc',
        limit: 5,
    });
    const [selectedTask, setSelectedTask] = useState(null);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedEngagement, setSelectedEngagement] = useState(null);
    const [showEngagementDetailsModal, setShowEngagementDetailsModal] = useState(false);
    const [selectedDiscussion, setSelectedDiscussion] = useState(null);
    const [showDiscussionDetailsModal, setShowDiscussionDetailsModal] = useState(false);
    const [showReasonModal, setShowReasonModal] = useState(false);

    const { auth } = useContext(AuthContext);
    const { mutate: updateTask } = useUpdateTask();
    const { mutate: updateEngagement } = useUpdateEngagement();
    const { mutate: updateDiscussion } = useUpdateDiscussion();

    const handleShowTaskDetails = (task) => {
        setSelectedTask(task);
        setShowDetailsModal(true);
    };

    const handleShowEngagementDetails = (engagement) => {
        setSelectedEngagement(engagement);
        setShowEngagementDetailsModal(true);
    };

    const handleShowDiscussionDetails = (discussion) => {
        setSelectedDiscussion(discussion);
        setShowDiscussionDetailsModal(true);
    };

    const handleCloseDetailsModal = () => {
        setShowDetailsModal(false);
        setSelectedTask(null);
        setShowEngagementDetailsModal(false);
        setSelectedEngagement(null);
        setShowDiscussionDetailsModal(false);
        setSelectedDiscussion(null);
    };

    const handleArchiveTask = (task) => {
        updateTask({ _id: task._id, state: 'Deleted' });
    };

    const handleResumeArchiveTask = (task) => {
        updateTask({ _id: task._id, status: 'Todo' });
    };

    const handleStatusChangeTask = (task, newStatus) => {
        updateTask({ _id: task._id, status: newStatus });
    };

    const handlePriorityChangeTask = (task, newPriority) => {
        updateTask({ _id: task._id, priority: newPriority });
    };

    const handleViewTask = (task) => {
        updateTask({ _id: task._id, seen: { viewed: true, last_viewed_date: new Date() } });
    };

    const handleArchiveEngagement = (engagement) => {
        updateEngagement({ _id: engagement._id, state: 'Deleted' });
    };

    const handleResumeArchiveEngagement = (engagement) => {
        updateEngagement({ _id: engagement._id, state: 'Offer' });
    };

    const handleStateChangeWithReasonEngagement = (engagement, newState) => {
        if (newState === "Closed") {
            setSelectedEngagement(engagement);
            setShowReasonModal(true);
        } else {
            updateEngagement({ _id: engagement._id, state: newState });
        }
    };

    const handleReasonSubmitEngagement = (reason) => {
        updateEngagement({ _id: selectedEngagement._id, state: 'Closed', reason_for_close: reason });
        setShowReasonModal(false);
        setSelectedEngagement(null);
    };

    const handleArchiveDiscussion = (discussion) => {
        updateDiscussion({ _id: discussion._id, status: 'Deleted' });
    };

    const handleResumeArchiveDiscussion = (discussion) => {
        updateDiscussion({ _id: discussion._id, status: 'Active' });
    };

    const tasksDueThisWeek = tasksData ? tasksData.filter(task => isDueThisWeek(new Date(task.deadline))) : [];

    if (tasksLoading || engagementsLoading || discussionsLoading) return <Loader />;
    if (tasksError) return <div>An error occurred: {tasksError.message}</div>;
    if (engagementsError) return <div>An error occurred: {engagementsError.message}</div>;
    if (discussionsError) return <div>An error occurred: {discussionsError.message}</div>;

    return (
        <Container className="page-container">
            <PageHeader className="ems-page-title" />
            <h5><b>Tasks Due This Week</b></h5>
            <TasksTable
                data={tasksDueThisWeek}
                handleShowDetails={handleShowTaskDetails}
                handleArchive={handleArchiveTask}
                handleResumeArchive={handleResumeArchiveTask}
                handleStatusChange={handleStatusChangeTask}
                handlePriorityChange={handlePriorityChangeTask}
                handleViewTask={handleViewTask}
            />
            <h5><b>Recent Engagements</b></h5>
            <EngagementsTable
                data={engagementsData}
                handleShowDetails={handleShowEngagementDetails}
                handleArchive={handleArchiveEngagement}
                handleResumeArchive={handleResumeArchiveEngagement}
                handleStateChangeWithReason={handleStateChangeWithReasonEngagement}
            />
            <h5><b>Recent Discussions</b></h5>
            <DiscussionsTable
                data={discussionsData}
                handleShowDetails={handleShowDiscussionDetails}
                handleArchive={handleArchiveDiscussion}
                handleResumeArchive={handleResumeArchiveDiscussion}
            />
            <TaskDetailsModal
                show={showDetailsModal}
                handleClose={handleCloseDetailsModal}
                task={selectedTask}
            />
            <EngagementDetailsModal
                show={showEngagementDetailsModal}
                handleClose={handleCloseDetailsModal}
                engagement={selectedEngagement}
            />
            <DiscussionDetailsModal
                show={showDiscussionDetailsModal}
                handleClose={handleCloseDetailsModal}
                discussion={selectedDiscussion}
            />
            <CloseReasonModal
                show={showReasonModal}
                handleSubmit={handleReasonSubmitEngagement}
            />
        </Container>
    );
};

export default DashboardPage;
