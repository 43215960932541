import React, { useState, useContext } from "react";
import PageHeader from "../../components/PageHeader";
import { Container, Tabs, Tab, Button, Row, Col } from "react-bootstrap";
import Loader from "../../components/Loader/Loader";
import OrganizationDetailsModal from "../../components/Organizations/OrganizationDetailsModal";
import OrganizationsTable from "../../components/Organizations/OrganizationsTable";
import SearchOrganizationsModal from "../../components/Organizations/SearchOrganizationsModal"; // Import the SearchOrganizationsModal component
import useFetchOrganizations from "../../hooks/organizations/useFetchOrganizations";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../../context/AuthProvider";
import useUpdateOrganization from "../../hooks/organizations/useUpdateOrganization";
import { Search } from "react-bootstrap-icons";
const OrganizationsPage = () => {
  const pageTitle = "Organizations";
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false); // State for showing search modal
  const [activeTab, setActiveTab] = useState("all");
  const { auth } = useContext(AuthContext);
  const { isLoading, error, data } = useFetchOrganizations(
    convertFiltersToParams(activeTab, auth)
  );
  const filteredData = data?.filter(organization => organization.status !== "Deleted");  
  const handleShowDetails = (organization) => {
    setSelectedOrganization(organization);
    setShowDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedOrganization(null);
  };

  const { mutate } = useUpdateOrganization();

  const handleArchive = (organization) => {
    mutate({ _id: organization._id, status: "Deleted" });
  };

  const handleResumeArchive = (organization) => {
    mutate({ _id: organization._id, status: "Organization" });
  };

  const handleStatusChange = (organization, newStatus) => {
    mutate({ _id: organization._id, status: newStatus });
  };

  if (isLoading) return <Loader />;
  if (error) return "An error occured: " + error.message;

  return (
    <>
      <Container className="page-container">
        <PageHeader title={pageTitle} className="ems-page-title" />
        <Row className="mb-3">
          <Col>
            <Button
              variant="outline-secondary iconButton"
              onClick={() => setShowSearchModal(true)}
            >
              <Search /> Search
            </Button>
          </Col>
        </Row>
        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
          <Tab eventKey="all" title="All">
            <OrganizationsTable
              data={filteredData}
              handleShowDetails={handleShowDetails}
              handleStatusChange={handleStatusChange}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
            />
          </Tab>
          <Tab eventKey="Client" title="Client">
            <OrganizationsTable
              data={data}
              handleShowDetails={handleShowDetails}
              handleStatusChange={handleStatusChange}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
            />
          </Tab>
          <Tab eventKey="Organization" title="Organization">
            <OrganizationsTable
              data={data}
              handleShowDetails={handleShowDetails}
              handleStatusChange={handleStatusChange}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
            />
          </Tab>
          <Tab eventKey="Deleted" title="Deleted">
            <OrganizationsTable
              data={data}
              handleShowDetails={handleShowDetails}
              handleStatusChange={handleStatusChange}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
            />
          </Tab>
        </Tabs>
        <OrganizationDetailsModal
          show={showDetailsModal}
          handleClose={handleCloseDetailsModal}
          organization={selectedOrganization}
        />
        <SearchOrganizationsModal
          show={showSearchModal}
          handleClose={() => setShowSearchModal(false)}
        />
      </Container>
    </>
  );
};

const convertFiltersToParams = (status, auth) => {
  let params = {};
  if (status && status !== "all") params["status"] = status;
  return params;
};

export default OrganizationsPage;
