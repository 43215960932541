import React from "react";
import { Form } from "react-bootstrap";
import SearchEngagements from "./SearchEngagements";
// Component to manage the selected engagement
const EngagementSection = ({ selectedEngagement, setSelectedEngagement }) => {
  return (
    <>
      <Form.Group className="mb-3" controlId="engagement">
        <Form.Label>Engagement</Form.Label>
        <SearchEngagements handleResult={setSelectedEngagement} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="selectedEngagement">
        <Form.Label>Selected Engagements</Form.Label>
          <Form.Control
            type="text"
            value={selectedEngagement?.displayName || ""}
            readOnly
          />
      </Form.Group>
    </>
  );
};

export default EngagementSection;
