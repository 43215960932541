import React, { useState, useEffect, useRef } from "react";
import EngagementDetailsTable from "../../components/Engagements/EngagementDetailsTable";
import useFetchTasksByEngagement from "../../hooks/tasks/useFetchTasksByEngagement";
import useFetchDiscussionsByEngagement from "../../hooks/discussions/useFetchDiscussionsByEngagement";
import useFetchEngagementById from "../../hooks/engagements/useFetchEngagementById";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import ReactQuill from "react-quill";
import useUpdateEngagement from "../../hooks/engagements/useUpdateEngagement";
import ConsultantDetailsTable from "../../components/Consultants/ConsultantDetailsTable";
import OrganizationDetailsTable from "../../components/Organizations/OrganizationDetailsTable";
import StakeholderSummaryTable from "../../components/Stakeholders/StakeholderSummaryTable";
import ModificationHistoryTable from "../../components/ModificationHistoryTable";
import useFetchOrganizationById from "../../hooks/organizations/useFetchOrganizationById";
import useFetchConsultantById from "../../hooks/consultants/useFetchConsultantById";
import "react-quill/dist/quill.snow.css";
import { debounce } from "lodash";
import TaskSummaryTable from "../../components/Tasks/TaskSummaryTable";
import DiscussionSummaryTable from "../../components/Discussions/DiscussionSummaryTable";

const EngagementDetailsPage = () => {
  const { id } = useParams();
  const [notes, setNotes] = useState("");
  const { mutate } = useUpdateEngagement();

  const {
    isLoading: isLoadingEngagement,
    error: errorEngagement,
    data: engagement,
  } = useFetchEngagementById(id);
console.log(engagement);
  const {
    isLoading: isLoadingConsultant,
    error: errorConsultant,
    data: consultant,
  } = useFetchConsultantById(engagement?.consultant?._id);

  const {
    isLoading: isLoadingClient,
    error: errorClient,
    data: client,
  } = useFetchOrganizationById(engagement?.client?._id);

  const {
    isLoading: isLoadingTasks,
    error: errorTasks,
    data: related_tasks,
  } = useFetchTasksByEngagement(id);

  const {
    isLoading: isLoadingDiscussions,
    error: errorDiscussions,
    data: related_discussions,
  } = useFetchDiscussionsByEngagement(id);

  useEffect(() => {
    if (engagement) {
      setNotes(engagement?.notes || "");
    }
  }, [engagement]);

  const debouncedMutate = useRef(
    debounce((newNotes) => {
      mutate({ _id: id, notes: newNotes });
    }, 300)
  ).current;

  const handleNoteChange = (value) => {
    setNotes(value);
    debouncedMutate(value);
  };

  const isLoading = isLoadingEngagement || isLoadingTasks || isLoadingDiscussions;
  const error = errorEngagement || errorTasks || errorDiscussions;

  if (isLoading) return <Loader />;
  if (error) return <p>An error occurred: {error.message}</p>;

  return (
    <Container className="mt-3">
      <Row className="mt-3">
        <Col>
          <h4>Engagement Details</h4>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Tabs
            defaultActiveKey="details"
            id="engagement-details-tabs"
            className="mb-3"
          >
            <Tab eventKey="details" title="Details">
              <ReactQuill
                value={notes}
                onChange={handleNoteChange}
                theme="snow"
              />
              <EngagementDetailsTable engagement={engagement} />
            </Tab>
            {engagement?.consultant && (
              <Tab eventKey="consultant" title="Consultant">
                {isLoadingConsultant ? <Loader /> : <ConsultantDetailsTable consultant={consultant} />}
                {errorConsultant && <p>An error occurred: {errorConsultant.message}</p>}
              </Tab>
            )}
            {engagement?.client && (
              <Tab eventKey="client" title="Client">
                {isLoadingClient ? <Loader /> : <OrganizationDetailsTable organization={client} />}
                {errorClient && <p>An error occurred: {errorClient.message}</p>}
              </Tab>
            )}
            {engagement?.contacts && (
              <Tab eventKey="contacts" title="Contacts">
                <StakeholderSummaryTable data={engagement?.contacts} />
              </Tab>
            )}
            {engagement?.stakeholders && (
              <Tab eventKey="stakeholders" title="Stakeholders">
                <StakeholderSummaryTable data={engagement?.stakeholders} />
              </Tab>
            )}
            <Tab eventKey="tasks" title="Tasks">
              {related_tasks ? <TaskSummaryTable data={related_tasks} /> : <p>No related tasks</p>}
            </Tab>
            <Tab eventKey="discussions" title="Discussions">
              {related_discussions ? <DiscussionSummaryTable data={related_discussions} /> : <p>No related discussions</p>}
            </Tab>
            <Tab eventKey="modification_history" title="Modification History">
              <ModificationHistoryTable element={engagement} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default EngagementDetailsPage;
