import React, { useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useUpdateOrganization from "../../hooks/organizations/useUpdateOrganization";
import "react-toastify/dist/ReactToastify.css";
import CreateOrganizationForm from "./CreateOrganizationForm";
import "../../css/DetailsModal.css";
//modal component to update organization
const UpdateOrganizationModal = ({ show, handleClose, organization }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: organization,
  });

  useEffect(() => {
    if (organization) {
      setValue("organization_name", organization.organization_name);
      setValue("status", organization.status);
      setValue("division", organization.division);
      setValue("department", organization.department);
      setValue("acronym", organization.acronym);
      setValue("office_locations", organization.office_locations);
      setValue("service_regions", organization.service_regions);
    }
  }, [organization, setValue]);

  const filterNullValues = (data) => {
    return Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== null)
    );
  };
  const clearModal = () => {
    reset();
  };
  const handleCloseUpdate = () => {
    clearModal();
    handleClose();
  };
  const submitForm = (data) => {
    data = {
      ...data,
      _id: organization._id,
    };
    const filteredData = filterNullValues(data);
    mutate(filteredData);
  };

  const { mutate } = useUpdateOrganization();

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleCloseUpdate}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(submitForm)}>
            <CreateOrganizationForm errors={errors} register={register} />
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="outline-secondary" onClick={clearModal}>
                Clear
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={handleSubmit(submitForm)}
              >
                Update
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateOrganizationModal;
