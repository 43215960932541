import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { Funnel } from "react-bootstrap-icons";
import SortOrderDropdownList from "../SortOrderDropdownList";
import SortTaskDropdownList from "./SortTaskDropdownList";
import FilterTaskDropdownList from "./FilterTaskDropdownList";
// Dropdown list component to filter and sort tasks
const TaskFilters = ({
  filters,
  setFilters,
  handleFilterChange,
  handleSortOrderChange,
  handleSortFieldChange,
}) => {
  return (
    <DropdownButton
      id="dropdown-basic-button"
      title={<Funnel />}
      variant="outline-secondary"
      className="ms-auto"
    >
      <FilterTaskDropdownList
        filters={filters}
        handleFilterChange={handleFilterChange}
      />
      <Dropdown.Divider />
      <SortTaskDropdownList
        handleSortFieldChange={handleSortFieldChange}
      />
      <SortOrderDropdownList
        sortOrder={filters.sortOrder}
        handleSortOrderChange={handleSortOrderChange}
      />
    </DropdownButton>
  );
};

export default TaskFilters;
