import React from 'react';
import { Form } from 'react-bootstrap';
import SearchUsers from './SearchUsers';
// Account Manager Section component
const AccountManagerSection = ({ selectedUser, setSelectedUser }) => {
    return (
        <>
            <Form.Group className="mb-3" controlId="account_manager">
                <Form.Label>Account Manager<span style={{ color: "red" }}>*</span></Form.Label>
                <SearchUsers handleResult={setSelectedUser} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="account_manager">
                <Form.Label>Selected Account Manager</Form.Label>
                <Form.Control
                    type="text"
                    value={selectedUser?.username || ""}
                    readOnly
                />
            </Form.Group>
        </>
    );
};

export default AccountManagerSection;
