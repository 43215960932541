import { useQuery } from "@tanstack/react-query";
import { apiGetEngagementsByConsultant } from "../../api/engagements";
import useAxiosPrivate from "../useAxiosPrivate";
import { toast } from 'react-toastify';

const useFetchEngagementsByConsultant = (consultant_id) => {
    const axiosPrivate = useAxiosPrivate();

    return useQuery({
        queryKey: ['engagements', consultant_id],
        queryFn: async () => apiGetEngagementsByConsultant(axiosPrivate, consultant_id),
        enabled: !!consultant_id,
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error fetching engagements');
            }
        }
    });
};

export default useFetchEngagementsByConsultant;
