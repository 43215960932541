import { Modal, Form, Button, InputGroup, Table } from "react-bootstrap"
import { useState } from "react"
import { Search, XLg } from "react-bootstrap-icons"
import { apiSearchOpps } from "../../api/opps"
import { useQuery } from "@tanstack/react-query"

import "../../utils/multiModal.css"
import "./searchOppModal.css"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"

const SearchOppsModal = ({ show, setShow, handleResult }) => {

    const numItems = 5;
    const batchNum = 0;

    const [selectedOpp, setSelectedOpp] = useState(null)
    const [searchTerm, setSearchTerm] = useState("")
    const axiosPrivate = useAxiosPrivate()

    const {isLoading, isFetching, fetchStatus, error, data, refetch, isError} = useQuery({
        queryKey: ['oppSearch', searchTerm],
        enabled: false,
        queryFn: async () => apiSearchOpps(axiosPrivate, searchTerm, numItems, batchNum),
        onError: (error) =>{
            
        }
    })

    const handleSubmit = () => {
        handleResult(selectedOpp)
        setShow(false)
        clearSearch()
    }
    
    const handleClose = () => {
        setShow(false)
        clearSearch()
    }

    const handleSearch = () => {
        refetch()
    }

    const clearSearch = () => {
        setSearchTerm("")
        setSelectedOpp(null)
    }

    return (
        <Modal show={show} onHide={handleClose}  backdrop="static" size = "lg">
            <Modal.Header closeButton>
                <Modal.Title>Search Opportunities</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                <InputGroup className="mb-3">
                    <Form.Control placeholder="Search..." aria-label="Search" aria-describedby="basic-addon2" value={searchTerm} onChange={(e)=>setSearchTerm(e.target.value)}/>
                    <Button variant="outline-secondary iconButton" id="button-addon2" onClick={handleSearch}>
                    <Search/>
                    </Button>
                    <Button variant="outline-secondary iconButton" id="button-addon2" onClick={clearSearch}>
                    <XLg/>
                    </Button>
                </InputGroup>
                
                <div className="searchResults">
                    {fetchStatus != "idle" && !data ? <p>Loading...</p> : 
                    <Table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Client</th>
                                <th>Project</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map(opp => <tr key={opp._id}>
                                <td>{opp.system_id}</td>
                                <td>{opp.client}</td>
                                <td><Button size="sm" variant="outline-success" onClick={() => setSelectedOpp(opp)}>Select</Button></td>
                            </tr>)}
                        </tbody>
                    </Table>}
                </div>
               {!!selectedOpp ? <div className="selectedOpp">
                <h6>Selected</h6>
                   <p>{selectedOpp.system_id}</p>
                </div>:<></>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SearchOppsModal