import React from 'react';
import { Table } from 'react-bootstrap';
import { ArrowRightSquare } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import ConsultantStatusBadge from './ConsultantStatusBadge';
//table component to display consultants summary.
const ConsultantSummaryTable = ({ data }) => {
    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {data?.map((consultant) => (
                <tr key={consultant?._id}>
                  <td>{consultant?.name} <ConsultantStatusBadge status={consultant?.status} /></td>
                  <td>{consultant?.email}</td>
                  <td>{consultant?.phone_number}</td>
                  <td>
                  <Link
                        to={`/consultants/${consultant._id}`}
                        className="btn btn-outline-secondary me-2"
                        target="_blank"
                      >
                        <ArrowRightSquare />
                      </Link>
                      </td>
                </tr>
              ))}
            </tbody>
        </Table>
    );
};

export default ConsultantSummaryTable;
