import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import EngagementStatusBadge from "./EngagementStatusBadge";
import UpdateEngagementModal from "./UpdateEngagementModal";
import { PencilSquare, ArrowRightSquare } from "react-bootstrap-icons";
//component to display engagement details in a table
const EngagementDetailsTable = ({ engagement }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const handleUpdateEngagement = () => {
    setShowEditModal(true);
  };
  const handleCloseUpdateModal = () => {
    setShowEditModal(false);
  };
  return (
    <>
      <Table bordered>
        <tbody>
          <tr>
            <th></th>
            <td>
              <Button
                variant="outline-secondary"
                className="me-2"
                onClick={() => handleUpdateEngagement()}
              >
                <PencilSquare />
              </Button>
              <Link to={`/engagements/${engagement?._id}`} className="btn btn-outline-secondary me-2" target="_blank">
                                <ArrowRightSquare />
                            </Link>
            </td>
          </tr>
          <tr>
            <th>Name</th>
            <td>
              {engagement?.displayName}{" "}
              <EngagementStatusBadge
                state={engagement?.state}
                is_extended={engagement?.is_extended}
              />
            </td>
          </tr>
          <tr>
            <th>Creator</th>
            <td>{engagement?.creator?.username}</td>
          </tr>
          <tr>
            <th>Account Manager</th>
            <td>{engagement?.account_manager?.username}</td>
          </tr>
          {engagement?.state === "closed" && (
            <tr>
              <th>Reason for Close</th>
              <td>{engagement?.reason_for_close}</td>
            </tr>
          )}
          <tr>
            <th>Date Awarded</th>
            <td>
              {engagement?.dateAwarded
                ? new Date(engagement?.dateAwarded).toISOString().split("T")[0]
                : ""}
            </td>
          </tr>
          <tr>
            <th>Billing Type</th>
            <td>{engagement?.billingType}</td>
          </tr>
          <tr>
            <th>Statement of Work</th>
            <td>{engagement?.statement_of_work}</td>
          </tr>
          <tr>
            <th>Start Date</th>
            <td>
              {engagement?.startDate
                ? new Date(engagement?.startDate).toISOString().split("T")[0]
                : ""}
            </td>
          </tr>
          <tr>
            <th>End Date</th>
            <td>
              {engagement?.endDate
                ? new Date(engagement?.endDate).toISOString().split("T")[0]
                : ""}
            </td>
          </tr>
          <tr>
            <th>Opp</th>
            <td>{engagement?.opp}</td>
          </tr>
          <tr>
            <th>Notes</th>
            <td dangerouslySetInnerHTML={{ __html: engagement?.notes }}></td>
          </tr>
        </tbody>
      </Table>
      <UpdateEngagementModal
        show={showEditModal}
        handleClose={handleCloseUpdateModal}
        engagement={engagement}
      />
    </>
  );
};

export default EngagementDetailsTable;
