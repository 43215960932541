import { Modal, Button, Form } from "react-bootstrap";
import React, { useState } from "react";
//modal component to select a reason for closing an engagement
const CloseReasonModal = ({ show, handleSubmit }) => {
  const reasonOptions = ["Completed", "Canceled", "Terminated"];
  const [reason, setReason] = useState("");

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleFormSubmit = () => {
    handleSubmit(reason);
    setReason("");
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Reason for Closing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="reason">
          <Form.Label>Reason</Form.Label>
          <Form.Select value={reason} onChange={handleReasonChange}>
            <option value="">Select a reason</option>
            {reasonOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleFormSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CloseReasonModal;
