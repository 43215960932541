import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiCreateOrganization } from "../../api/organizations";
import { toast } from 'react-toastify';
import useAxiosPrivate from "../useAxiosPrivate";

const useCreateOrganization = () => {
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (organizationData) => apiCreateOrganization(axiosPrivate, organizationData),
        onSuccess: (newOrganization) => {
            toast.success("Organization created successfully!");
            queryClient.setQueryData(["organizations", newOrganization._id], newOrganization);
            queryClient.invalidateQueries(["organizations"]);
        },
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error creating organization');
            }
        }
    });
};

export default useCreateOrganization;
