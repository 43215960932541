import React from 'react';
import { Badge } from 'react-bootstrap';
//component to display the type and the status of the discussion in a badge
const DiscussionStatusBadge = ({ type, status }) => {
  let bg = null;

  switch (type) {
    case 'Phone Call':
      bg = 'info';
      break;
    case 'Meeting':
      bg = 'success';
      break;
    case 'Email':
        bg = 'warning';
      break;
    default:
      bg = null;
  }
  if (status === 'Deleted') {
    bg = null;
  }
  return (
    <>
      {bg && (<Badge bg={bg} className="me-2">
        {type}
      </Badge>
      )}
    </>
  );
};

export default DiscussionStatusBadge;
