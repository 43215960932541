import { Modal, Button, Form } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useCreateStakeholder from "../../hooks/stakeholders/useCreateStakeholder";
import CreateStakeholderForm from "./CreateStakeholderForm";
import "../../css/DetailsModal.css";
//modal component to create/update stakeholder
const CreateStakeholderModal = ({ show, setShow, handleResult, role }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [showCreateOrganizationModal, setShowCreateOrganizationModal] = useState(false);
    const clearModal = () => {
        reset();
        setSelectedOrganization(null);
    };

    const submitForm = (data) => {
        const dataWithRole = {
            ...data,
            organization: selectedOrganization?._id,
            ...(role && { role })

        };
        mutate(dataWithRole);
        if (handleResult) {
            handleResult(dataWithRole);
          }
    };

    const handleClose = () => {
        setShow(false);
        clearModal();
    };
    const { mutate } = useCreateStakeholder();
    const handleModalSubmit = (event) => {
        event.stopPropagation(); // Prevent the event from bubbling up to parent elements
        handleSubmit(submitForm)(event);
    };
    return (
        <>
            <Modal size="lg" show={show} onHide={handleClose} backdrop="static" keyboard={false} dialogClassName="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Create</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleModalSubmit}>
                        <CreateStakeholderForm register={register} errors={errors} role={role} selectedOrganization={selectedOrganization} setSelectedOrganization={setSelectedOrganization} showCreateOrganizationModal={showCreateOrganizationModal} setShowCreateOrganizationModal={setShowCreateOrganizationModal}/>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="outline-secondary" onClick={clearModal}>
                                Clear
                            </Button>
                            <Button variant="primary" type="submit">
                                Create Contact/Stakeholder
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CreateStakeholderModal;
