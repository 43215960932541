import React from 'react';
import { Table } from 'react-bootstrap';
import { ArrowRightSquare } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import StakeholderStatusBadge from './StakeholderStatusBadge';
//table component to display stakeholders summary
const StakeholderSummaryTable = ({ data }) => {
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Organization</th>
                    <th>Position</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {data?.map(stakeholder => (
                    <tr key={stakeholder?._id}>
                        <td>
                            {stakeholder?.name}
                            <StakeholderStatusBadge status={stakeholder?.status} role={stakeholder?.role} />
                        </td>
                        <td>{stakeholder?.email}</td>
                        <td>{stakeholder?.phone_number}</td>
                        <td>{stakeholder?.organization?.organization_name}</td>
                        <td>{stakeholder?.position}</td>
                        <td className="table-actions">
                            <Link
                to={`/stakeholders/${stakeholder?._id}`}
                className="btn btn-outline-secondary me-2"
                target="_blank"
              >
                <ArrowRightSquare />
              </Link>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default StakeholderSummaryTable;
