import {useLocation, Navigate, Outlet} from "react-router-dom"

const ProtectedRoute = ({isAllowed, redirectPath="/", children}) => {

    const location = useLocation();

    if (!isAllowed) {
        return <Navigate to={redirectPath} state={{from:location}} replace />;
    }
    
    return children ? children : <Outlet />;
}

export default ProtectedRoute;