import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';
import SearchOrganizations from './SearchOrganizations';
import CreateOrganizationModal from './CreateOrganizationModal';
// Component to manage the selected organization
const ClientSection = ({ selectedOrganization, setSelectedOrganization, showCreateOrganizationModal, setShowCreateOrganizationModal }) => {

    return (
        <>
            <Form.Group className="mb-3" controlId="client">
                <Form.Label>Client </Form.Label>
                <SearchOrganizations handleResult={setSelectedOrganization} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="createClient">
                <Button variant="link" onClick={() => setShowCreateOrganizationModal(true)}>
                    <PlusCircle /> Create new Organization
                </Button>
            </Form.Group>
            <Form.Group className="mb-3" controlId="selectedClient">
                <Form.Label>Selected Organization</Form.Label>
                <Form.Control
                    type="text"
                    value={selectedOrganization?.organization_name || ""}
                    readOnly
                />
        </Form.Group>
        <CreateOrganizationModal show={showCreateOrganizationModal} setShow={setShowCreateOrganizationModal} handleResult={setSelectedOrganization} />
        </>
    );
};

export default ClientSection;
