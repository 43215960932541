import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useUpdateStakeholder from "../../hooks/stakeholders/useUpdateStakeholder";
import "react-toastify/dist/ReactToastify.css";
import CreateStakeholderForm from "./CreateStakeholderForm";
import "../../css/DetailsModal.css";
//modal component to update stakeholder
const UpdateStakeholderModal = ({ show, handleClose, stakeholder }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: stakeholder,
  });
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [showCreateOrganizationModal, setShowCreateOrganizationModal] =
    useState(false);
  useEffect(() => {
    if (stakeholder) {
      setValue("name", stakeholder?.name);
      setValue("phone_number", stakeholder?.phone_number);
      setValue("status", stakeholder?.status);
      setValue("role", stakeholder?.role);
      setValue("email", stakeholder?.email);
      setValue("position", stakeholder?.position);
      setSelectedOrganization(stakeholder?.organization);
    }
  }, [stakeholder, setValue]);

  const filterNullValues = (data) => {
    return Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== null)
    );
  };
  const clearModal = () => {
    reset();
    setSelectedOrganization(null);
    setShowCreateOrganizationModal(false);
  };
  const handleCloseUpdate = () => {
    clearModal();
    handleClose();
  };
  const { mutate } = useUpdateStakeholder();
  const submitForm = (data) => {
    data = {
      ...data,
      _id: stakeholder?._id,
      organization: selectedOrganization._id,
    };
    const filteredData = filterNullValues(data);
    mutate(filteredData);
    setShowCreateOrganizationModal(false);
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleCloseUpdate}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Stakeholder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(submitForm)}>
            <CreateStakeholderForm
              register={register}
              errors={errors}
              role={stakeholder?.role}
              selectedOrganization={selectedOrganization}
              setSelectedOrganization={setSelectedOrganization}
              showCreateOrganizationModal={showCreateOrganizationModal}
              setShowCreateOrganizationModal={setShowCreateOrganizationModal}
            />
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="outline-secondary" onClick={clearModal}>
                Clear
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={handleSubmit(submitForm)}
              >
                Update
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateStakeholderModal;
