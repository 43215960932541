import React from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import SearchUsers from './SearchUsers';
// User Array Section component
const UserArraySection = ({ selectedUsers, setSelectedUsers }) => {
    const handleAddUser = (user) => {
        setSelectedUsers([...selectedUsers, user]);
    };

    const handleRemoveUser = (index) => {
        setSelectedUsers(selectedUsers.filter((_, i) => i !== index));
    };

    return (
        <>
            <Form.Group className="mb-3" controlId="lead">
                <Form.Label>Participate Users</Form.Label>
                <SearchUsers handleResult={handleAddUser} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="lead">
                <Form.Label>Selected Users</Form.Label>
                {selectedUsers.map((user, index) => (
                    <InputGroup className="mb-2" key={index}>
                        <Form.Control
                            type="text"
                            value={user?.username || ""}
                            readOnly
                        />
                        <Button variant="outline-danger" onClick={() => handleRemoveUser(index)}>
                            <XLg />
                        </Button>
                    </InputGroup>
                ))}
            </Form.Group>
        </>
    );
};

export default UserArraySection;
