import React from "react";
import { Table } from "react-bootstrap";
import { ArrowRightSquare } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import DiscussionStatusBadge from "./DiscussionStatusBadge";
//component to display discussions summary.
const DiscussionSummaryTable = ({ data }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Title</th>
          <th>Length</th>
          <th>Date</th>
          <th>Notes</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((discussion) => (
          <tr key={discussion?._id}>
            <td>
              {discussion?.title}
              <DiscussionStatusBadge
                type={discussion?.type}
                status={discussion?.status}
              />
            </td>
            <td>{discussion.length}</td>
            <td>
              {new Date(discussion.discussion_date).toISOString().split("T")[0]}
            </td>
            <td dangerouslySetInnerHTML={{ __html: discussion?.notes }}></td>
            <td className="table-actions">
              <Link
                to={`/discussions/${discussion?._id}`}
                className="btn btn-outline-secondary me-2"
                target="_blank"
              >
                <ArrowRightSquare />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DiscussionSummaryTable;
