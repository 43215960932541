//fetch discussions with filters
export const apiGetDiscussions = async (axiosPrivate, filters) => {
    const api_route = "/discussions"
    const urlParams = new URLSearchParams(filters).toString();
    const fullUrl = `${api_route}?${urlParams}`;  
    console.log(`Full API Route: ${fullUrl}`);
    const response = await axiosPrivate.get(api_route, { params: filters })

    const data = response.data
    
    if(data.success)
        return data.data.discussions

    
    return []   
}

//get discussions by engagement id
export const apiGetDiscussionsByEngagement = async (axiosPrivate, engagement_id) => {
    const api_route = `/discussions?related_engagement=${engagement_id}`;
    console.log(`Full API Route: ${api_route}`);
    const response = await axiosPrivate.get(api_route)

    const data = response.data
    
    if(data.success)
        return data.data.discussions

    
    return []   
}

//get discsussions by participant id and type
export const apiGetDiscussionsByParticipant = async (axiosPrivate, participant_id, participant_type) => {
    let api_route = '';
    if (participant_type === 'user') {
        api_route = `/discussions?participated_user=${participant_id}`;
    } else if (participant_type === 'consultant') {
        api_route = `/discussions?participated_consultant=${participant_id}`;
    } else if (participant_type === 'stakeholder') {
        api_route = `/discussions?participated_stakeholder=${participant_id}`;
    }
    
    console.log(`Full API Route: ${api_route}`);

    const response = await axiosPrivate.get(api_route);

    const data = response.data;
    
    if (data.success) {
        return data.data.discussions;
    }

    return [];
};

//get discussion by id 
export const apiGetDiscussionByID = async (axiosPrivate, ID) => {
    const api_route = `/discussions/${ID}`;
    const response = await axiosPrivate.get(api_route)

    const data = response.data
    
    if(data.success)
        return data.data.discussion

    
    return []   
}

//create new discussion
export const apiCreateDiscussion = async (axiosPrivate, discussionData) => {
    console.log("apiCreateDiscussion", discussionData)
    const api_route = "/discussions"

    const response = await axiosPrivate.post(api_route, discussionData)

    const data = response.data
    console.log(data)

    if(data.success)
        return data.discussion

    return null
}

//search discussions by search term
export const apiSearchDiscussions = async (axiosPrivate, searchTerm) => {
    const api_route = "/discussions/search?searchTerm=" + searchTerm;
    console.log(`Full API Route: ${api_route}`);
    try {
        const response = await axiosPrivate.get(api_route);
        const data = response.data;

        if (data && data.success) {
            return data.data.discussions;
        } 
    } catch (error) {
        console.error("Error fetching discussions:", error);
        return [];
    }
};

//update discussion by id   
export const apiUpdateDiscussion = async (axiosPrivate, ID, discussionData) => {
    console.log("apiUpdateDiscussion", discussionData)
    const api_route = `/discussions/${ID}`;

    const response = await axiosPrivate.patch(api_route, discussionData)

    const data = response.data
    console.log(data)

    if(data.success)
        return data.data.discussion

    return null
}