import { useQuery } from "@tanstack/react-query";
import { apiGetEngagementsByClient } from "../../api/engagements";
import useAxiosPrivate from "../useAxiosPrivate";
import { toast } from 'react-toastify';

const useFetchEngagementsByClient = (client_id) => {
    const axiosPrivate = useAxiosPrivate();

    return useQuery({
        queryKey: ['engagements', client_id],
        enabled: !!client_id,
        queryFn: async () => apiGetEngagementsByClient(axiosPrivate, client_id),
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error fetching engagements');
            }
        }
    });
};

export default useFetchEngagementsByClient;
