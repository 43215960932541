import React from 'react';
import { Form } from 'react-bootstrap';
import SearchUsers from './SearchUsers';
// User Section component
const UserSection = ({ selectedUser, setSelectedUser }) => {
    return (
        <>
            <Form.Group className="mb-3" controlId="user">
                <Form.Label>Lead</Form.Label>
                <SearchUsers handleResult={setSelectedUser} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="userr">
                <Form.Label>Selected User</Form.Label>
                <Form.Control
                    type="text"
                    value={selectedUser?.username || ""}
                    readOnly
                />
            </Form.Group>
        </>
    );
};

export default UserSection;
