import React from "react";
import { Form } from "react-bootstrap";
import InputMask from "react-input-mask";
// Form to create/update a consultant
const CreateConsultantForm = ({ errors, register }) => {
  return (
    <>
      <Form.Group className="mb-3" controlId="name">
        <Form.Label>
          Name <span style={{ color: "red" }}>*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Name"
          {...register("name", { required: "Name is required" })}
          isInvalid={!!errors.name}
        />
        <Form.Control.Feedback type="invalid">
          {errors.name && errors.name.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="phone_number">
        <Form.Label>Phone Number</Form.Label>
        <InputMask
          mask="(999) 999-9999"
          {...register("phone_number", {
            pattern: {
              value: /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/,
              message: "Invalid phone number format",
            },
          })}
        >
          {(inputProps) => (
            <Form.Control
              type="text"
              placeholder="Enter Phone Number"
              isInvalid={!!errors.phone_number}
              {...inputProps}
            />
          )}
        </InputMask>
        <Form.Control.Feedback type="invalid">
          {errors.phone_number && errors.phone_number.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter Email"
          {...register("email", {
            pattern: {
              value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
              message: "Invalid email format",
            },
          })}
          isInvalid={!!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email && errors.email.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="company_name">
        <Form.Label>
          Company Name
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Company Name"
          {...register("company_name")}
          isInvalid={!!errors.company_name}
        />
        <Form.Control.Feedback type="invalid">
          {errors.company_name && errors.company_name.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="oms_id">
        <Form.Label>
          OMS ID <span style={{ color: "red" }}>*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter OMS ID"
          {...register("oms_id", { required: "OMS ID is required" })}
          isInvalid={!!errors.oms_id}
        />
        <Form.Control.Feedback type="invalid">
          {errors.oms_id && errors.oms_id.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="status">
        <Form.Label>Status</Form.Label>
        <Form.Select aria-label="status" {...register("status")}>
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
          <option value="Pending">Pending</option>
        </Form.Select>
      </Form.Group>
    </>
  );
};

export default CreateConsultantForm;
