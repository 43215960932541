import React from 'react';
import { Form } from "react-bootstrap";
import AssignedToSection from '../Users/AssignedToSection';
import EngagementSection from '../Engagements/EngagementSection';
import DiscussionSection from '../Discussions/DiscussionSection';
//form component to create/update a task
const CreateTaskForm = ({ 
    errors, 
    register, 
    control, 
    selectedUsers, 
    setSelectedUsers, 
    selectedEngagement, 
    setSelectedEngagement, 
    selectedDiscussion, 
    setSelectedDiscussion, 
}) => {

    return (
        <>
            <Form.Group className="mb-3" controlId="title" data-test-id="form-group-title">
                <Form.Label> Title <span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Control 
                    type="text" 
                    placeholder="Enter Title" 
                    {...register("title", { required: "Title is required" })}
                    isInvalid={!!errors.title} 
                />
                <Form.Control.Feedback type="invalid" data-test-id="title-feedback">
                    {errors.title?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="type">
                <Form.Label>Type <span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Select 
                    aria-label="Type" 
                    {...register("type", { required: "Task type is required" })}
                    isInvalid={!!errors.type} 
                >
                    <option value="Regular">Regular</option>
                    <option value="Recurring">Recurring</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.type?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="priority">
                <Form.Label>Priority<span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Select 
                    aria-label="Priority" 
                    {...register("priority", { required: "Priority is required" })}
                    isInvalid={!!errors.priority} 
                >
                    <option value="High">High</option>
                    <option value="Medium">Medium</option>
                    <option value="Low">Low</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.priority?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="deadline">
                <Form.Label>Deadline<span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Control 
                    type="date" 
                    {...register("deadline", { required: "Deadline is required" })} 
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="reminder">
                <Form.Label>Reminder</Form.Label>
                <Form.Control 
                    type="date" 
                    {...register("reminder")} 
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="repeatedPeriod">
                <Form.Label>Repeated Period</Form.Label>
                <Form.Control 
                    type="number" 
                    placeholder="Enter Repeated Period in Days" 
                    {...register("repeated_period")} 
                />
            </Form.Group>
            <AssignedToSection
                selectedUser={selectedUsers}
                setSelectedUser={setSelectedUsers}
            />
            <EngagementSection
                selectedEngagement={selectedEngagement}
                setSelectedEngagement={setSelectedEngagement}
            />
            <DiscussionSection
                selectedDiscussion={selectedDiscussion}
                setSelectedDiscussion={setSelectedDiscussion}
            />         
        </>
    );
};

export default CreateTaskForm;
