import { Form, Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
import CreateEngagementForm from "./CreateEngagementForm";
import useUpdateEngagement from "../../hooks/engagements/useUpdateEngagement";
import "../../css/DetailsModal.css";
//modal component to update an engagement
const UpdateEngagementModal = ({ show, handleClose, engagement }) => {
    const [showSearchOppModal, setShowSearchOppModal] = useState(false);
    const [showCreateConsultantModal, setShowCreateConsultantModal] = useState(false);
    const [showCreateOrganizationModal, setShowCreateOrganizationModal] = useState(false);
    const { register, handleSubmit, reset, formState: { errors }, control, setValue } = useForm();
    const [selectedConsultant, setSelectedConsultant] = useState(null);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedOpp, setSelectedOpp] = useState(null);
    const [stakeholders, setStakeholders] = useState([]);
    const [showCreateStakeholderModal, setShowCreateStakeholderModal] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [showCreateContactModal, setShowCreateContactModal] = useState(false);


    useEffect(() => {
        if (engagement) {
            const formatDate = (dateString) => {
                const date = new Date(dateString);
                return date.toISOString().split('T')[0];
            };
                      
            setValue("displayName", engagement?.displayName);
            setValue("dateAwarded", formatDate(engagement?.dateAwarded));
            setValue("billingType", engagement?.billingType);
            setValue("statement_of_work", engagement?.statement_of_work);
            setValue("startDate", formatDate(engagement?.startDate));
            setValue("endDate", formatDate(engagement?.endDate));
            setValue("is_extened", engagement?.is_extended);
            setSelectedConsultant(engagement?.consultant);
            setSelectedOrganization(engagement?.client);
            setSelectedUser(engagement?.account_manager);
            setSelectedOpp(engagement?.opp);
            setStakeholders(engagement?.stakeholders);
            setContacts(engagement?.contacts);
        }
    }, [engagement, setValue]);

    const filterNullValues = (data) => {
        return Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== null));
    };


    const clearModal = () => {
        reset();
        setSelectedConsultant(null);
        setSelectedOrganization(null);
        setStakeholders([]);
        setContacts([]);
        setSelectedOpp(null);
        setSelectedUser(null);
        setShowSearchOppModal(false);
        setShowCreateConsultantModal(false);
        setShowCreateOrganizationModal(false);
        setShowCreateStakeholderModal(false);
        setShowCreateContactModal(false);
    };

    const handleCloseUpdate = () => {
        clearModal();
        handleClose();
    };

    const submitForm = (data) => {
        data = { 
            ...data,
            _id: engagement?._id, 
            opp: selectedOpp?._id, 
            consultant: selectedConsultant?._id, 
            client: selectedOrganization?._id, 
            account_manager: selectedUser?._id, 
            contacts: contacts.map(contact => contact._id), 
            stakeholders: stakeholders.map(stakeholder => stakeholder._id)
        };
        const filteredData = filterNullValues(data);
        mutate(filteredData);
        setShowSearchOppModal(false);
        setShowCreateConsultantModal(false);
        setShowCreateOrganizationModal(false);
        setShowCreateStakeholderModal(false);
        setShowCreateContactModal(false);
    };
    const { mutate } = useUpdateEngagement();
    return (
        <>
            <Modal size="lg" show={show} onHide={handleCloseUpdate} backdrop="static" keyboard={false} dialogClassName="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Engagement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form onSubmit={handleSubmit(submitForm)}>
                    <CreateEngagementForm
                        onSubmit={handleSubmit(submitForm)}
                        errors={errors}
                        register={register}
                        control={control}
                        selectedConsultant={selectedConsultant}
                        setSelectedConsultant={setSelectedConsultant}
                        selectedOrganization={selectedOrganization}
                        setSelectedOrganization={setSelectedOrganization}
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                        selectedOpp={selectedOpp}
                        setSelectedOpp={setSelectedOpp}
                        showSearchOppModal={showSearchOppModal}
                        setShowSearchOppModal={setShowSearchOppModal}
                        showCreateConsultantModal={showCreateConsultantModal}
                        setShowCreateConsultantModal={setShowCreateConsultantModal}
                        showCreateOrganizationModal={showCreateOrganizationModal}
                        setShowCreateOrganizationModal={setShowCreateOrganizationModal}
                        contacts={contacts}
                        setContacts={setContacts}
                        showCreateContactModal={showCreateContactModal}
                        setShowCreateContactModal={setShowCreateContactModal}
                        stakeholders={stakeholders}
                        setStakeholders={setStakeholders}
                        showCreateStakeholderModal={showCreateStakeholderModal}
                        setShowCreateStakeholderModal={setShowCreateStakeholderModal}
                    />
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="outline-secondary" onClick={clearModal}>
                        Clear
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(submitForm)}>
                        Update Engagement
                    </Button>
                </Modal.Footer>
                </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default UpdateEngagementModal;
