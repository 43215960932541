import React from "react";
import { Table } from "react-bootstrap";
import { ArrowRightSquare } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import EngagementStatusBadge from "./EngagementStatusBadge";
//table component to display engagements summary
const EngagementSummaryTable = ({ data }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((engagement) => (
          <tr key={engagement?._id}>
            <td>
              {engagement?.displayName}
              <EngagementStatusBadge
                state={engagement?.state}
                is_extended={engagement?.is_extended}
              />
              {engagement?.state === "Closed" && (
                <div>{engagement?.reason_for_close}</div>
              )}
            </td>
            <td>
              {engagement?.startDate
                ? new Date(engagement?.startDate).toISOString().split("T")[0]
                : ""}
            </td>
            <td>
              {engagement?.endDate
                ? new Date(engagement?.endDate).toISOString().split("T")[0]
                : ""}
            </td>
            <td className="table-actions">
              <Link
                to={`/engagements/${engagement?._id}`}
                className="btn btn-outline-secondary me-2"
                target="_blank"
              >
                <ArrowRightSquare />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default EngagementSummaryTable;
