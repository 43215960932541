import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { Funnel } from "react-bootstrap-icons";
import SortOrderDropdownList from "../SortOrderDropdownList";
import SortDiscussionDropdownList from "./SortDiscussionDropdownList";
import FilterDiscussionDropdownList from "./FilterDiscussionDropdownList";
//component to display discussion filters
const DiscussionFilters = ({
  filters,
  setFilters,
  handleFilterChange,
  handleSortOrderChange,
  handleSortFieldChange,
}) => {
  return (
    <DropdownButton
      id="dropdown-basic-button"
      title={<Funnel />}
      variant="outline-secondary"
      className="ms-auto"
    >
      <FilterDiscussionDropdownList
        filters={filters}
        handleFilterChange={handleFilterChange}
      />
      <Dropdown.Divider />
      <SortDiscussionDropdownList
        handleSortFieldChange={handleSortFieldChange}
      />
      <SortOrderDropdownList
        sortOrder={filters.sortOrder}
        handleSortOrderChange={handleSortOrderChange}
      />
    </DropdownButton>
  );
};

export default DiscussionFilters;
