import { Modal, Button } from "react-bootstrap";
import React from "react";
import DiscussionDetailsTable from "./DiscussionDetailsTable";
import EngagementDetailsTable from "../Engagements/EngagementDetailsTable";
import useFetchEngagementById from "../../hooks/engagements/useFetchEngagementById";
import Loader from "../Loader/Loader";
import "../../css/DetailsModal.css";
//modal component to display the details of a discussion
const DiscussionDetailsModal = ({ show, handleClose, discussion }) => {
 const relatedEngagementId = discussion?.related_engagement?._id;
  const {
    isLoading,
    error,
    data: related_engagement,
  } = useFetchEngagementById(relatedEngagementId || '');

  if (!discussion) return null;
  if ((isLoading && relatedEngagementId)) return <Loader />;
  if (error) return <p>An error occurred: {error.message}</p>;

  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Discussion Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DiscussionDetailsTable discussion={discussion} />
          {relatedEngagementId && <h5>
            <b>Related Engagement</b>
          </h5>}
          {relatedEngagementId && <EngagementDetailsTable engagement={related_engagement} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DiscussionDetailsModal;
