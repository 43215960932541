import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUpdateDiscussion } from "../../api/discussions";
import { toast } from 'react-toastify';
import useAxiosPrivate from "../useAxiosPrivate";

const useUpdateDiscussion = () => {
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (discussionData) => apiUpdateDiscussion(axiosPrivate, discussionData._id, discussionData),
        onSuccess: (updatedDiscussion) => {
            toast.success("Discussion updated successfully!");
            queryClient.setQueryData(["discussions", updatedDiscussion._id], updatedDiscussion);
            queryClient.invalidateQueries(["discussions"]);
        },
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error updating Discussion.');
            }
        }
    });
};

export default useUpdateDiscussion;
