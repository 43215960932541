import axios from '../api/axios';
import useAuth from './useAuth';
import {useNavigate, useLocation} from "react-router-dom"

const useLogout = () => {
    const { setAuth } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const logout = async () => {
        const response = await axios.get('/auth/logout', {
            withCredentials: true,
        });
        console.log(response)
        setAuth({});
        localStorage.removeItem('auth');
        navigate('/login', { state: { from: location }, replace: true});
        return response.data.success
    }

    return logout;
};

export default useLogout;