import React from "react";
import { Table, Button, Form } from "react-bootstrap";
import { Trash, ArrowRightSquare } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import TaskStatusBadge from "./TaskStatusBadge";
// Table component to display the tasks in task page
const TasksTable = ({
  data,
  handleArchive,
  handleResumeArchive,
  handleStatusChange,
  handleShowDetails,
  handlePriorityChange,
  handleViewTask,
}) => {
  const statusOptions = ["Todo", "InProgress", "Completed", "Deleted"];
  const priorityOptions = ["High", "Medium", "Low"];
  
  const getBorderColor = (priority) => {
    switch (priority) {
      case "High":
        return "red";
      case "Medium":
        return "yellow";
      case "Low":
        return "green";
      default:
        return "black";
    }
  };

  const handleDeleteClick = (task) => {
    if (window.confirm("Are you sure you want to delete this task?")) {
        handleArchive(task);
    }
  };

  const handleView = (task) => {
    handleViewTask(task);
    handleShowDetails(task);
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Title</th>
          <th>Status</th>
          <th>Type</th>
          <th>Assigned To</th>
          <th>Completed By</th>
          <th>Deadline</th>
          <th>Priority</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((task) => (
          <tr
            key={task._id}
            style={{ border: `2px solid ${getBorderColor(task.priority)}` }}
          >
            <td>
              <Button
                variant="link"
                onClick={() => handleView(task)}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                {task.title}
              </Button>
              <TaskStatusBadge type={task?.type} status={task?.status}  viewed={task?.seen.viewed} />
            </td>
            <td>
              <Form.Select
                value={task.status}
                onChange={(e) => handleStatusChange(task, e.target.value)}
              >
                {statusOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
              {task.status === "Completed" && <div>{task.completed_by?.username}</div>}
            </td>
            <td>{task.type}</td>
            <td>
              {task.assigned_to && task.assigned_to.length > 0
                ? task.assigned_to.map((person) => person.username).join(", ")
                : ""}
            </td>
            <td>{task.completed_by?.username}</td>
            <td>{new Date(task.deadline).toISOString().split("T")[0]}</td>
            <td>
              <Form.Select
                value={task.priority}
                onChange={(e) => handlePriorityChange(task, e.target.value)}
              >
                {priorityOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </td>
            <td className="table-actions">
              <Button
                variant="outline-secondary"
                className="me-2"
                onClick={() =>
                  task.status === "Deleted"
                    ? handleResumeArchive(task)
                    : handleDeleteClick(task)
                }
              >
                <Trash />
              </Button>
              <Link
                to={`/tasks/${task?._id}`}
                className="btn btn-outline-secondary me-2"
                target="_blank"
                onClick={() => handleViewTask(task)}
              >
                <ArrowRightSquare />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TasksTable;
