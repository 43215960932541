import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import useSearchTasks from '../hooks/tasks/useSearchTasks';
import useSearchStakeholders from '../hooks/stakeholders/useSearchStakeholders';
import useSearchOrganizations from '../hooks/organizations/useSearchOrganizations';
import useSearchEngagements from '../hooks/engagements/useSearchEngagements';
import useSearchDiscussions from '../hooks/discussions/useSearchDiscussions';
import useSearchConsultants from '../hooks/consultants/useSearchConsultants';
import TaskSummaryTable from '../components/Tasks/TaskSummaryTable';
import EngagementSummaryTable from '../components/Engagements/EngagementSummaryTable';
import StakeholderSummaryTable from '../components/Stakeholders/StakeholderSummaryTable';
import DiscussionSummaryTable from '../components/Discussions/DiscussionSummaryTable';
import ConsultantSummaryTable from '../components/Consultants/ConsultantSummaryTable';
import OrganizationSummaryTable from '../components/Organizations/OrganizationSummaryTable';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SearchResultsPage = () => {
  const location = useLocation();
  const query = useQuery();
  const initialSearchTerm = query.get('query') || '';
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  const { isFetching: isFetchingTasks, data: tasks, refetch: refetchTasks } = useSearchTasks(searchTerm);
  const { isFetching: isFetchingStakeholders, data: stakeholders, refetch: refetchStakeholders } = useSearchStakeholders(searchTerm);
  const { isFetching: isFetchingOrganizations, data: organizations, refetch: refetchOrganizations } = useSearchOrganizations(searchTerm);
  const { isFetching: isFetchingEngagements, data: engagements, refetch: refetchEngagements } = useSearchEngagements(searchTerm);
  const { isFetching: isFetchingDiscussions, data: discussions, refetch: refetchDiscussions } = useSearchDiscussions(searchTerm);
  const { isFetching: isFetchingConsultants, data: consultants, refetch: refetchConsultants } = useSearchConsultants(searchTerm);

  useEffect(() => {
    if (searchTerm) {
      refetchTasks();
      refetchStakeholders();
      refetchOrganizations();
      refetchEngagements();
      refetchDiscussions();
      refetchConsultants();
    }
  }, [searchTerm, refetchTasks, refetchStakeholders, refetchOrganizations, refetchEngagements, refetchDiscussions, refetchConsultants]);

  useEffect(() => {
    setSearchTerm(query.get('query') || '');
  }, [location.search]);

  const renderResults = (data, component, title) => {
    if (data && data.length > 0) {
      return (
        <>
          <h5>{title}</h5>
          {component}
        </>
      );
    }
    return null;
  };

  return (
    <Container>
      <Row>
        <Col>
          <h3>Search Results</h3>
        </Col>
      </Row>
      {renderResults(tasks, (
        <TaskSummaryTable data={tasks} />
      ), 'Tasks')}
      {renderResults(stakeholders, (
        <StakeholderSummaryTable data={stakeholders} />
      ), 'Stakeholders')}
      {renderResults(organizations, (
        <OrganizationSummaryTable data={organizations} />
      ), 'Organizations')}
      {renderResults(engagements, (
        <EngagementSummaryTable data={engagements} />
      ), 'Engagements')}
      {renderResults(discussions, (
        <DiscussionSummaryTable data={discussions} />
      ), 'Discussions')}
      {renderResults(consultants, (
        <ConsultantSummaryTable data={consultants} />
      ), 'Consultants')}
      {!isFetchingTasks && !tasks?.length && !isFetchingStakeholders && !stakeholders?.length && !isFetchingOrganizations && !organizations?.length && !isFetchingEngagements && !engagements?.length && !isFetchingDiscussions && !discussions?.length && !isFetchingConsultants && !consultants?.length && (
        <div>
          No results found.
        </div>
      )}
    </Container>
  );
};

export default SearchResultsPage;
