import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import useFetchUsersNotifications from "../hooks/users/useFetchUserNotifications";
import Loader from "../components/Loader/Loader";

const NotificationsPage = () => {
  let params = {};
  params["sortBy"] = "createdAt";
  params["sortOrder"] = "desc";
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const { isLoading, error, data } = useFetchUsersNotifications(
    auth?.user?.id,
    params
  );
  if (isLoading) return <Loader />;

  if (error) return "An error occured: " + error.message;

  const handleViewNotification = (notification) => {
    navigate(notification.link);
  };

  return (
    <div>
      <h1>All Notifications</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Type</th>
            <th>Message</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {data.map((notification) => (
            <tr
              key={notification._id}
              onClick={() => handleViewNotification(notification)}
              style={{
                fontWeight: notification.seen ? "normal" : "bold",
                cursor: "pointer",
              }}
            >
              <td>{notification.type}</td>
              <td>{notification.message}</td>
              <td>{new Date(notification.createdAt).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default NotificationsPage;
