import { useQuery } from "@tanstack/react-query";
import { apiGetUserByID } from "../../api/users";
import useAxiosPrivate from "../useAxiosPrivate";
import { toast } from 'react-toastify';

const useFetchUserById = (Id) => {
    const axiosPrivate = useAxiosPrivate();

    return useQuery({
        queryKey: ['users', Id],
        enabled: !!Id,
        queryFn: async () => apiGetUserByID(axiosPrivate, Id),
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error fetching discussions');
            }
        }
    });
};

export default useFetchUserById;
