import React, { useState, useEffect, useRef } from "react";
import DiscussionDetailsTable from "../../components/Discussions/DiscussionDetailsTable";
import useFetchDiscussionById from "../../hooks/discussions/useFetchDiscussionById";
import useFetchEngagementById from "../../hooks/engagements/useFetchEngagementById";
import useFetchTasksByDiscussion from "../../hooks/tasks/useFetchTasksByDiscussion";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { Container, Row, Col, Tabs, Tab, Button } from "react-bootstrap";
import ReactQuill from "react-quill";
import useUpdateDiscussion from "../../hooks/discussions/useUpdateDiscussion";
import ModificationHistoryTable from "../../components/ModificationHistoryTable";
import EngagementDetailsTable from "../../components/Engagements/EngagementDetailsTable";
import TaskSummaryTable from "../../components/Tasks/TaskSummaryTable";
import "react-quill/dist/quill.snow.css";
import { debounce } from "lodash";
import { JournalPlus } from "react-bootstrap-icons";
import CreateTaskModal from "../../components/Tasks/CreateTaskModal";

const DiscussionDetailsPage = () => {
  const { id } = useParams();
  const [notes, setNotes] = useState("");
  const { mutate } = useUpdateDiscussion();
  const [showCreateTask, setShowCreateTask] = useState(false);
  const [selectedEngagement, setSelectedEngagement] = useState(null);
  const [selectedDiscussion, setSelectedDiscussion] = useState(null);
  // Fetch the discussion details
  const {
    isLoading: isDiscussionLoading,
    error: discussionError,
    data: discussion,
  } = useFetchDiscussionById(id);

  // Conditionally fetch the related engagement only if discussion is available and has related_engagement
  const relatedEngagementId = discussion?.related_engagement?._id;
  const {
    isLoading: isEngagementLoading,
    error: engagementError,
    data: relatedEngagement,
  } = useFetchEngagementById(relatedEngagementId || "");

  // Fetch the tasks related to the discussion
  const {
    isLoading: isTasksLoading,
    error: tasksError,
    data: relatedTasks,
  } = useFetchTasksByDiscussion(id);

  useEffect(() => {
    if (discussion) {
      setNotes(discussion?.notes);
    }
  }, [discussion]);
  useEffect(() => {
    if (discussion) {
      setSelectedEngagement(discussion?.related_engagement);
      setSelectedDiscussion(discussion);
    }
  }, discussion);
  const debouncedMutate = useRef(
    debounce((newNotes) => {
      mutate({ _id: id, notes: newNotes });
    }, 300)
  ).current;

  const handleNoteChange = (value) => {
    setNotes(value);
    debouncedMutate(value);
  };

  if (
    isDiscussionLoading ||
    (relatedEngagementId && isEngagementLoading) ||
    isTasksLoading
  ) {
    return <Loader />;
  }
  if (discussionError)
    return <p>An error occurred: {discussionError.message}</p>;
  if (engagementError)
    return <p>An error occurred: {engagementError.message}</p>;
  if (tasksError) return <p>An error occurred: {tasksError.message}</p>;

  return (
    <>
      <Container className="mt-3">
        <Row className="mt-3">
          <Col>
            <h4>Discussion Details</h4>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button
              variant="outline-success"
              onClick={() => setShowCreateTask(true)}
            >
              <JournalPlus size={16} /> Create Task
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Tabs
              defaultActiveKey="details"
              id="discussion-details-tabs"
              className="mb-3"
            >
              <Tab eventKey="details" title="Details">
                <ReactQuill
                  value={notes}
                  onChange={handleNoteChange}
                  theme="snow"
                />
                <DiscussionDetailsTable discussion={discussion} />
              </Tab>
              <Tab eventKey="engagement" title="Related Engagement">
                {relatedEngagement ? (
                  <EngagementDetailsTable engagement={relatedEngagement} />
                ) : (
                  <p>No related engagement.</p>
                )}
              </Tab>
              <Tab eventKey="task" title="Related Tasks">
                {relatedTasks ? (
                  <TaskSummaryTable data={relatedTasks} />
                ) : (
                  <p>No related tasks.</p>
                )}
              </Tab>
              <Tab eventKey="modification_history" title="Modification History">
                <ModificationHistoryTable element={discussion} />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
      <CreateTaskModal
        show={showCreateTask}
        setShow={setShowCreateTask}
        selectedDiscussion={selectedDiscussion}
        selectedEngagement={selectedEngagement}
        setSelectedDiscussion={setSelectedDiscussion}
        setSelectedEngagement={setSelectedEngagement}
      />
    </>
  );
};

export default DiscussionDetailsPage;
