import React, { useState } from "react";
import { Modal} from "react-bootstrap";
import useSearchTasks from "../../hooks/tasks/useSearchTasks";
import SearchForm from "../SearchForm";
import TaskSummaryTable from "./TaskSummaryTable";
//modal component to search tasks
const SearchTasksModal = ({ show, handleClose }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const { isFetching, data, refetch } = useSearchTasks(searchTerm);

  const handleSearch = (term) => {
    setSearchTerm(term);
    refetch();
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Search Tasks</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SearchForm onSearch={handleSearch} onClear={handleClear} />
      {isFetching && <p>Loading...</p>}
      {data && data.length !== 0 && (
        <div className="searchResults">
          <TaskSummaryTable data={data} />
        </div>
      )}
      {data && data.length === 0 && <div>No tasks found.</div>}
      </Modal.Body>
    </Modal>
  );
};

export default SearchTasksModal;
