import React from 'react';
import { Table, Button, Form } from 'react-bootstrap';
import { Trash, ArrowRightSquare } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import StakeholderStatusBadge from './StakeholderStatusBadge';
//table component to display stakeholders in stakeholders page
const StakeholdersTable = ({ data, handleShowDetails, handleRoleChange, handleArchive, handleResumeArchive }) => {
    const roleOptions = ["Contact", "Other"];
    const handleDeleteClick = (stakeholder) => {
        if (window.confirm("Are you sure you want to delete this stakeholder?")) {
            handleArchive(stakeholder);
        }
    };
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Role</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Organization</th>
                    <th>Position</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {data?.map(stakeholder => (
                    <tr key={stakeholder._id}>
                        <td>
                            <Button
                                variant="link"
                                onClick={() => handleShowDetails(stakeholder)}
                                style={{ color: 'black', fontWeight: 'bold', textDecoration: 'none' }}
                            >
                                {stakeholder.name}
                            </Button>
                            <StakeholderStatusBadge status={stakeholder.status} role={stakeholder.role} />
                        </td>
                        <td>
              <Form.Select
                value={stakeholder.role}
                onChange={(e) => handleRoleChange(stakeholder, e.target.value)}
              >
                {roleOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </td>
                        <td>{stakeholder.email}</td>
                        <td>{stakeholder.phone_number}</td>
                        <td>{stakeholder.organization?.organization_name}</td>
                        <td>{stakeholder.position}</td>
                        <td className="table-actions">
                            <Button
                                variant="outline-secondary"
                                className="me-2"
                                onClick={() => stakeholder.status === 'Deleted' ? handleResumeArchive(stakeholder) : handleDeleteClick(stakeholder)}
                            >
                                <Trash />
                            </Button>
                            <Link
                to={`/stakeholders/${stakeholder?._id}`}
                className="btn btn-outline-secondary me-2"
                target="_blank"
              >
                <ArrowRightSquare />
              </Link>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default StakeholdersTable;
