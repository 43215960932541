import React from "react";
import { Form } from "react-bootstrap";
import SearchDiscussions from "./SearchDiscussions";
// Component to manage the selected discussion
const DiscussionSection = ({ selectedDiscussion, setSelectedDiscussion }) => {
  return (
    <>
      <Form.Group className="mb-3" controlId="discussion">
        <Form.Label>Discussion</Form.Label>
        <SearchDiscussions handleResult={setSelectedDiscussion} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="selectedDiscussion">
        <Form.Label>Selected Discussions</Form.Label>
          <Form.Control
            type="text"
            value={selectedDiscussion?.title || ""}
            readOnly
          />
      </Form.Group>
    </>
  );
};

export default DiscussionSection;
