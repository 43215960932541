import React from "react";
import { Table, Button, Form } from "react-bootstrap";
import { Trash, ArrowRightSquare } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import DiscussionStatusBadge from "./DiscussionStatusBadge";
//table component to display discussions in discussion page
const DiscussionsTable = ({
  data,
  handleTypeChange,
  handleArchive,
  handleResumeArchive,
  handleShowDetails,
}) => {
  const typeOptions = ["Phone Call", "Meeting", "Email"];

  const handleDeleteClick = (discussion) => {
    if (window.confirm("Are you sure you want to delete this discussion?")) {
      handleArchive(discussion);
    }
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Title</th>
          <th>Type</th>
          <th>Related Engagement</th>
          <th>Length</th>
          <th>Date</th>
          <th>Lead</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((discussion) => (
          <tr key={discussion?._id}>
            <td>
              <Button
                variant="link"
                onClick={() => handleShowDetails(discussion)}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                {discussion?.title}
              </Button>
              <DiscussionStatusBadge
                type={discussion?.type}
                status={discussion?.status}
              />
            </td>
            <td>
              <Form.Select
                value={discussion?.type}
                onChange={(e) => handleTypeChange(discussion, e.target.value)}
              >
                {typeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </td>
            <td>{discussion.related_engagement?.displayName}</td>
            <td>{discussion.length}</td>
            <td>
              {new Date(discussion.discussion_date).toISOString().split("T")[0]}
            </td>
            <td>{discussion.lead?.username}</td>
            <td className="table-actions">
              <Button
                variant="outline-secondary"
                className="me-2"
                onClick={() =>
                  discussion.status === "Deleted"
                    ? handleResumeArchive(discussion)
                    : handleDeleteClick(discussion)
                }
              >
                <Trash />
              </Button>
              <Link
                to={`/discussions/${discussion?._id}`}
                className="btn btn-outline-secondary me-2"
                target="_blank"
              >
                <ArrowRightSquare />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DiscussionsTable;
