import React from "react";
import { Dropdown, Form } from "react-bootstrap";
// Dropdown list for engagement filters
const FilterEngagementDropdownList = ({ filters, handleFilterChange }) => {
  return (
    <>
      <Dropdown.Item as="div">
        <Form.Check
          type="checkbox"
          label="Extended"
          name="extended"
          checked={filters.extended}
          onChange={handleFilterChange}
        />
      </Dropdown.Item>
      <Dropdown.Item as="div">
        <Form.Check
          type="checkbox"
          label="Created By Me"
          name="createdByMe"
          checked={filters.createdByMe}
          onChange={handleFilterChange}
        />
      </Dropdown.Item>
      <Dropdown.Item as="div">
        <Form.Check
          type="checkbox"
          label="Assigned To Me"
          name="assignedToMe"
          checked={filters.assignedToMe}
          onChange={handleFilterChange}
        />
      </Dropdown.Item>
    </>
  );
};
export default FilterEngagementDropdownList;
