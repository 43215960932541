import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
import useCreateOrganization from "../../hooks/organizations/useCreateOrganization";
import CreateOrganizationForm from "./CreateOrganizationForm";
import "../../css/DetailsModal.css";
// modal Component to create a new organization
const CreateOrganizationModal = ({ show, setShow, handleResult }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const clearModal = () => {
        reset();
    };

    const submitForm = (data) => {
        mutate(data);
        if (handleResult) {
            handleResult(data);
          }
    };

    const handleClose = () => {
        clearModal();
        setShow(false);
    };

    const handleModalSubmit = (event) => {
        event.stopPropagation(); // Prevent the event from bubbling up to parent elements
        handleSubmit(submitForm)(event);
    };

    const { mutate } = useCreateOrganization();
    return (
        <>
            <Modal size="lg" show={show} onHide={handleClose} backdrop="static" keyboard={false} dialogClassName="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Create Organization</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleModalSubmit}>
                        <CreateOrganizationForm errors={errors} register={register} />
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="outline-secondary" onClick={clearModal}>
                                Clear
                            </Button>
                            <Button variant="primary" type="submit">
                                Create Organization
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CreateOrganizationModal;
