// Import necessary modules and components from React and React Bootstrap
import React, { useState, useContext } from "react";
import {
  Nav,
  Navbar,
  Button,
  Form,
  FormControl,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  Speedometer2,
  Pen,
  JournalPlus,
  PersonWorkspace,
  ChatLeftDots,
  People,
  Building,
  ListTask,
  GearWide,
  BoxArrowRight,
  Search,
} from "react-bootstrap-icons";
import { slide as Menu } from "react-burger-menu";
import { useMediaQuery } from "react-responsive";
import useLogout from "../../hooks/useLogout";
import CreateEngagementModal from "../Engagements/CreateEngagementModal";
import CreateTaskModal from "../Tasks/CreateTaskModal";
import CreateDiscussionModal from "../Discussions/CreateDiscussionModal";
import CreateConsultantModal from "../Consultants/CreateConsultantModal";
import CreateStakeholderModal from "../Stakeholders/CreateStakeholderModal";
import CreateOrganizationModal from "../Organizations/CreateOrganizationModal";
import useFetchUsersNotifications from "../../hooks/users/useFetchUserNotifications";
import AuthContext from "../../context/AuthProvider";
import NotificationDropdown from "../Notifications/NotificationDropdown";
import logo from "../../assets/logos/twg.png";
import "./EMSNavbar.css";
import Loader from "../Loader/Loader";

// Main navigation bar component
const EMSNavbar = () => {
  const iconSize = 16;
  const logout = useLogout();
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const [showCreateEngagement, setShowCreateEngagement] = useState(false);
  const [showCreateTask, setShowCreateTask] = useState(false);
  const [selectedEngagement, setSelectedEngagement] = useState(null);
  const [selectedDiscussion, setSelectedDiscussion] = useState(null);
  const [showCreateDiscussion, setShowCreateDiscussion] = useState(false);
  const [showCreateConsultant, setShowCreateConsultant] = useState(false);
  const [showCreateContact, setShowCreateContact] = useState(false);
  const [showCreateStakeholder, setShowCreateStakeholder] = useState(false);
  const [showCreateOrganization, setShowCreateOrganization] = useState(false);
  let params = {};
  params["sortBy"] = "createdAt";
  params["sortOrder"] = "asc";
  const [searchQuery, setSearchQuery] = useState("");
  const { isLoading, error, data } = useFetchUsersNotifications(
    auth?.user?.id,
    params
  );

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // Handle search form submission
  const handleSearch = (event) => {
    event.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search-results?query=${searchQuery}`);
    }
  };

  if (isLoading) return <Loader />;
  if (error) return "An error occurred: " + error.message;

  // Menu items for navigation
  const menuItems = (
    <>
      <Nav.Link as={Link} to="/dashboard" title="Dashboard">
        <Speedometer2 size={iconSize} />
      </Nav.Link>
      <Nav.Link as={Link} to="/engagements" title="Engagements">
        <Pen size={iconSize} />
      </Nav.Link>
      <Nav.Link as={Link} to="/consultants" title="Consultants">
        <PersonWorkspace size={iconSize} />
      </Nav.Link>
      <Nav.Link as={Link} to="/stakeholders" title="Stakeholders">
        <People size={iconSize} />
      </Nav.Link>
      <Nav.Link as={Link} to="/clients" title="Clients">
        <Building size={iconSize} />
      </Nav.Link>
      <Nav.Link as={Link} to="/tasks" title="Tasks">
        <ListTask size={iconSize} />
      </Nav.Link>
      <Nav.Link as={Link} to="/discussions" title="Discussions">
        <ChatLeftDots size={iconSize} />
      </Nav.Link>
      <NotificationDropdown notifications={data} auth={auth} />
      <DropdownButton
        className="create-dropdown"
        title={<JournalPlus size={iconSize} />}
      >
        <Dropdown.Item onClick={() => setShowCreateEngagement(true)}>
          Create Engagement
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setShowCreateTask(true)}>
          Create Task
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setShowCreateDiscussion(true)}>
          Create Discussion
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setShowCreateConsultant(true)}>
          Create Consultant
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setShowCreateContact(true)}>
          Create Contact
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setShowCreateStakeholder(true)}>
          Create Stakeholder
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setShowCreateOrganization(true)}>
          Create Organization
        </Dropdown.Item>
      </DropdownButton>
      <Nav.Link as={Link} to="/settings" title="Settings">
        <GearWide size={iconSize} />
      </Nav.Link>
      <Nav.Link
        as={Link}
        onClick={() => {
          logout();
        }}
        to="/"
        title="Log Out"
      >
        <BoxArrowRight size={iconSize} />
      </Nav.Link>
    </>
  );

  return (
    <>
      {isMobile ? (
        <>
          {/* Mobile view */}
          <Menu left>
            <img src={logo} className="navlogo" alt="Logo" />
            <Nav className="emsnavlinks">{menuItems}</Nav>
          </Menu>
          <div className="nav-center">
            <img src={logo} className="navlogo" alt="Logo" />
          </div>
          <Form className="form-center" onSubmit={handleSearch}>
            <FormControl
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button variant="outline-secondary" type="submit">
              <Search size={iconSize} />
            </Button>
          </Form>
        </>
      ) : (
        <Navbar collapseOnSelect expand="lg" className="emsnav">
          {/* Desktop view */}
          <Navbar.Brand as={Link} to="/">
            <img src={logo} className="navlogo" alt="Logo" />
          </Navbar.Brand>
          <div className="product-title">EMS</div>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="emsnavlinks">{menuItems}</Nav>
            <Form className="d-flex ms-auto" onSubmit={handleSearch}>
              <FormControl
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Button variant="outline-secondary" type="submit">
                <Search size={iconSize} />
              </Button>
            </Form>
          </Navbar.Collapse>
        </Navbar>
      )}
      {/* Modals for creating various entities */}
      <CreateEngagementModal
        show={showCreateEngagement}
        setShow={setShowCreateEngagement}
      />
      <CreateTaskModal
        show={showCreateTask}
        setShow={setShowCreateTask}
        selectedDiscussion={selectedDiscussion}
        selectedEngagement={selectedEngagement}
        setSelectedDiscussion={setSelectedDiscussion}
        setSelectedEngagement={setSelectedEngagement}
      />
      <CreateDiscussionModal
        show={showCreateDiscussion}
        setShow={setShowCreateDiscussion}
      />
      <CreateConsultantModal
        show={showCreateConsultant}
        setShow={setShowCreateConsultant}
      />
      <CreateStakeholderModal
        show={showCreateContact}
        setShow={setShowCreateContact}
        role="Contact"
      />
      <CreateStakeholderModal
        show={showCreateStakeholder}
        setShow={setShowCreateStakeholder}
        role="Other"
      />
      <CreateOrganizationModal
        show={showCreateOrganization}
        setShow={setShowCreateOrganization}
      />
    </>
  );
};

export default EMSNavbar;
