import { useQuery } from "@tanstack/react-query";
import { apiSearchEngagements } from "../../api/engagements"
import useAxiosPrivate from "../useAxiosPrivate";
import { toast } from 'react-toastify';

const useSearchEngagements = (searchTerm) => {
    const axiosPrivate = useAxiosPrivate();

    return useQuery({
        queryKey: ["engagementSearch", searchTerm],
        enabled: false,
        queryFn: async () => apiSearchEngagements(axiosPrivate, searchTerm),
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error searching Engagements');
            }
        }
    });
};

export default useSearchEngagements;