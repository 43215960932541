import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiCreateDiscussion } from "../../api/discussions";
import { toast } from 'react-toastify';
import useAxiosPrivate from "../useAxiosPrivate";

const useCreateDiscussion = () => {
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (discussionData) => apiCreateDiscussion(axiosPrivate, discussionData),
        onSuccess: (newDiscussion) => {
            toast.success("Discussion created successfully!");
            queryClient.setQueryData(["discussions", newDiscussion._id], newDiscussion);
            queryClient.invalidateQueries(["discussions"]);
        },
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error creating discussion.');
            }
        }
    });
}

export default useCreateDiscussion;