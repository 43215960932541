import React, { useState } from "react";
import { Modal} from "react-bootstrap";
import useSearchDiscussions from "../../hooks/discussions/useSearchDiscussions";
import SearchForm from "../SearchForm";
import DiscussionSummaryTable from "./DiscussionSummaryTable";
//modal component to search discussions
const SearchDiscussionsModal = ({ show, handleClose}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { isFetching, data, refetch} = useSearchDiscussions(searchTerm);
  const handleSearch = (term) => {
    setSearchTerm(term);
    refetch();
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  return (
    <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Search Organizations</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <SearchForm onSearch={handleSearch} onClear={handleClear} />
      {isFetching && <p>Loading...</p>}
      {data && data.length !== 0 && (
        <div className="searchResults">
          <DiscussionSummaryTable data={data} />
        </div>
      )}
      {data && data.length === 0 && (
        <div>
          No discussions found.
        </div>
      )}
      </Modal.Body>
    </Modal>
  );
};

export default SearchDiscussionsModal;
