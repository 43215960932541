//get users by filters
export const apiGetUsers = async (axiosPrivate) => {
    const api_route = "/users"
    
    const response = await axiosPrivate.get(api_route)

    const data = response.data
    
    if(data.success)
        return data.data.users

    
    return []   
}

//update user notification by user id and notification id
export const apiUpdateUserNotification = async (axiosPrivate, ID, userId, userData) => {
    console.log("apiUpdateUserNotification", userData)
    const api_route = `/users/${userId}/notifications/${ID}`;

    const response = await axiosPrivate.patch(api_route, userData)

    const data = response.data

    if(data.success)
        return data.data.notification

    return null
}

//get user notifications by user id
export const apiGetUserNotifications = async (axiosPrivate, userId, params) => {
    const api_route = `/users/${userId}/notifications`;
    const urlParams = new URLSearchParams(params).toString();
    const fullUrl = `${api_route}?${urlParams}`;
    
    console.log(`Full API Route: ${fullUrl}`);
    const response = await axiosPrivate.get(api_route)

    const data = response.data
    
    if(data.success)
        return data.data.notifications

    
    return []   
}

//get user by ID
export const apiGetUserByID = async (axiosPrivate, ID) => {
    const api_route = `/users/${ID}`;
    const response = await axiosPrivate.get(api_route)

    const data = response.data
    
    if(data.success)
        return data.data.user

    
    return []   
}

//create new user 
export const apiCreateUser = async (axiosPrivate, userData) => {
    console.log("apiCreateUser", userData)
    const api_route = "/users"

    const response = await axiosPrivate.post(api_route, userData)

    const data = response.data
    console.log(data)

    if(data.success)
        return data.user

    return null
}

//search for users by a search term
export const apiSearchUsers = async (axiosPrivate, searchTerm) => {
    const api_route = "/users/search?searchTerm=" + searchTerm;
    try {
        const response = await axiosPrivate.get(api_route);
        const data = response.data;

        if (data && data.success) {
            return data.data.users;
        } 
    } catch (error) {
        console.error("Error fetching users:", error);
        return [];
    }
};

//update a user by ID
export const apiUpdateUser = async (axiosPrivate, ID, userData) => {
    console.log("apiUpdateUser", userData)
    const api_route = `/users/${ID}`;

    const response = await axiosPrivate.patch(api_route, userData)

    const data = response.data

    if(data.success)
        return data.data.user

    return null
}