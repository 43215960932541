import React, { useState, useContext } from 'react';
import PageHeader from "../../components/PageHeader";
import { Container, Tabs, Tab, Row, Col, Button } from "react-bootstrap";
import Loader from "../../components/Loader/Loader";
import CloseReasonModal from '../../components/Engagements/CloseReasonModal';
import EngagementDetailsModal from '../../components/Engagements/EngagementDetailsModal';
import EngagementsTable from '../../components/Engagements/EngagementsTable';
import useUpdateEngagement from "../../hooks/engagements/useUpdateEngagement";
import useFetchEngagements from "../../hooks/engagements/useFetchEngagements";
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from "../../context/AuthProvider";
import EngagementFilters from '../../components/Engagements/EngagementFilters';
import { Search } from "react-bootstrap-icons";
import SearchEngagementsModal from "../../components/Engagements/SearchEngagementsModal";
const EngagementsPage = () => {
    const pageTitle = "Engagements";
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [selectedEngagement, setSelectedEngagement] = useState(null);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [activeTab, setActiveTab] = useState('all');
    const [filters, setFilters] = useState({
        extended: false,
        createdByMe: false,
        assignedToMe: false
    });
    const [sortField, setSortField] = useState('createdAt');
    const [sortOrder, setSortOrder] = useState('desc');

    const { auth } = useContext(AuthContext);
    const { isLoading, error, data } = useFetchEngagements(convertFiltersToParams(filters, activeTab, auth, sortField, sortOrder));
    const filteredData = data?.filter(engagement => engagement.state !== "Deleted");
    const handleShowDetails = (engagement) => {
        setSelectedEngagement(engagement);
        setShowDetailsModal(true);
    };

    const handleCloseDetailsModal = () => {
        setShowDetailsModal(false);
        setSelectedEngagement(null);
    };

    const { mutate } = useUpdateEngagement();

    const handleArchive = (engagement) => {
        mutate({ _id: engagement._id, state: 'Deleted' });
    };

    const handleResumeArchive = (engagement) => {
        mutate({ _id: engagement._id, state: 'Offer' });
    };

    const handleStateChangeWithReason = (engagement, newState) => {
        if (newState === "Closed") {
            setSelectedEngagement(engagement);
            setShowReasonModal(true);
        } else {
            mutate({ _id: engagement._id, state: newState });
        }
    };

    const handleReasonSubmit = (reason) => {
        mutate({ _id: selectedEngagement._id, state: 'Closed', reason_for_close: reason });
        setShowReasonModal(false);
        setSelectedEngagement(null);
    };

    const handleFilterChange = (e) => {
        const { name, checked } = e.target;
        setFilters({
            ...filters,
            [name]: checked
        });
    };

    const handleSortFieldChange = (e) => {
        setSortField(e.target.value);
    };

    const handleSortOrderChange = (e) => {
        setSortOrder(e.target.value);
    };

    if (isLoading) return <Loader />;

    if (error) return "An error occurred: " + error.message;

    return (
        <>
            <Container className="page-container">
                <PageHeader title={pageTitle} className="ems-page-title" />
                <Button variant="outline-secondary iconButton" onClick={() => setShowSearchModal(true)}>
          <Search /> Search
        </Button>
                <Row className="mb-3">
                    <Col className="d-flex justify-content-end">
                        <EngagementFilters filters={filters} setFilters={setFilters} handleFilterChange={handleFilterChange} handleSortFieldChange={handleSortFieldChange} handleSortOrderChange={handleSortOrderChange} />
                    </Col>
                </Row>
                <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                    <Tab eventKey="all" title="All">
                        <EngagementsTable
                            data={filteredData}
                            handleShowDetails={handleShowDetails}
                            handleArchive={handleArchive}
                            handleResumeArchive={handleResumeArchive}
                            handleStateChangeWithReason={handleStateChangeWithReason}
                        />
                    </Tab>
                    <Tab eventKey="Offer" title="Offer">
                        <EngagementsTable
                            data={data}
                            handleShowDetails={handleShowDetails}
                            handleArchive={handleArchive}
                            handleResumeArchive={handleResumeArchive}
                            handleStateChangeWithReason={handleStateChangeWithReason}
                        />
                    </Tab>
                    <Tab eventKey="Initiation" title="Initiation">
                        <EngagementsTable
                            data={data}
                            handleShowDetails={handleShowDetails}
                            handleArchive={handleArchive}
                            handleResumeArchive={handleResumeArchive}
                            handleStateChangeWithReason={handleStateChangeWithReason}
                        />
                    </Tab>
                    <Tab eventKey="Active" title="Active">
                        <EngagementsTable
                            data={data}
                            handleShowDetails={handleShowDetails}
                            handleArchive={handleArchive}
                            handleResumeArchive={handleResumeArchive}
                            handleStateChangeWithReason={handleStateChangeWithReason}
                        />
                    </Tab>
                    <Tab eventKey="Closed" title="Closed">
                        <EngagementsTable
                            data={data}
                            handleShowDetails={handleShowDetails}
                            handleArchive={handleArchive}
                            handleResumeArchive={handleResumeArchive}
                            handleStateChangeWithReason={handleStateChangeWithReason}
                        />
                    </Tab>
                    <Tab eventKey="Deleted" title="Archived">
                        <EngagementsTable
                            data={data}
                            handleShowDetails={handleShowDetails}
                            handleArchive={handleArchive}
                            handleResumeArchive={handleResumeArchive}
                            handleStateChangeWithReason={handleStateChangeWithReason}
                        />
                    </Tab>
                </Tabs>
                <EngagementDetailsModal
                    show={showDetailsModal}
                    handleClose={handleCloseDetailsModal}
                    engagement={selectedEngagement}
                />
                <CloseReasonModal
                    show={showReasonModal}
                    handleSubmit={handleReasonSubmit}
                />
                <SearchEngagementsModal
          show={showSearchModal}
          handleClose={() => setShowSearchModal(false)}
        />
            </Container>
        </>
    );
};

const convertFiltersToParams = (filters, state, auth, sortField, sortOrder) => {
    let params = {};
    if (state && state !== 'all') params['state'] = state;
    if (filters.extended) params['is_extended'] = true;
    if (filters.createdByMe) params['creator'] = auth?.user?.id;
    if (filters.assignedToMe) params['account_manager'] = auth?.user?.id;
    params['sortBy'] = sortField;
    params['sortOrder'] = sortOrder;

    return params;
};

export default EngagementsPage;
