import { Modal, Button, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import CreateTaskForm from "./CreateTaskForm";
import useUpdateTask from "../../hooks/tasks/useUpdateTask";
import "../../css/DetailsModal.css";
//modal component to update task
const UpdateTaskModal = ({ show, handleClose, task }) => {
  const { register, handleSubmit, reset, formState: { errors }, control, setValue } = useForm();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedEngagement, setSelectedEngagement] = useState(null);
  const [selectedDiscussion, setSelectedDiscussion] = useState(null);

    useEffect(() => {
        if (task) {
            const formatDate = (dateString) => {
                const date = new Date(dateString);
                return date.toISOString().split('T')[0];
            };
                      
            setValue("title", task?.title);
            setValue("type", task?.type);
            setValue("status", task?.status);
            setValue("priority", task?.priority);
            setValue("deadline", formatDate(task?.deadline));
            setValue("reminder", formatDate(task?.reminder));
            setValue("repeated_period", task?.repeated_period);
            setSelectedEngagement(task?.related_engagement);
            setSelectedDiscussion(task?.related_discussion);
            setSelectedUsers(task?.assigned_to);
        }
    }, [task, setValue]);

  const filterNullValues = (data) => {
    return Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== null)
    );
  };



  const clearModal = () => {
    reset();
    setSelectedUsers([]);
    setSelectedEngagement(null);
    setSelectedDiscussion(null);
};

  const handleCloseUpdate = () => {
    clearModal();
    handleClose();
};

  const { mutate } = useUpdateTask();
  const submitForm = (data) => {
    data = { 
        ...data, 
        _id: task?._id,
        related_engagement: selectedEngagement?._id,
        related_discussion: selectedDiscussion?._id,
        assigned_to: selectedUsers?.map(user => user._id),
    };
    const filteredData = filterNullValues(data);
    mutate(filteredData);
};



  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleCloseUpdate}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(submitForm)}>
            <CreateTaskForm
              errors={errors}
              register={register}
              control={control}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              selectedEngagement={selectedEngagement}
              setSelectedEngagement={setSelectedEngagement}
              selectedDiscussion={selectedDiscussion}
              setSelectedDiscussion={setSelectedDiscussion}
            />

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="outline-secondary" onClick={clearModal}>
                Clear
              </Button>
              <Button variant="primary" onClick={handleSubmit(submitForm)}>
                Update Task
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateTaskModal;
