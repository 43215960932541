import React from "react";
import { Form } from "react-bootstrap";
import ConsultantArraySection from "../Consultants/ConsultantArraySection";
import UserSection from "../Users/UserSection";
import UserArraySection from "../Users/UserArraySection";
import EngagementSection from "../Engagements/EngagementSection";
import StakeholdersSection from "../Stakeholders/StakeholdersSection";
// form component to create/update discussion
const CreateDiscussionForm = ({
    errors,
    register,
    control,
    selectedConsultants,
    setSelectedConsultants,
    selectedLead,
    setSelectedLead,
    selectedUsers,
    setSelectedUsers,
    selectedEngagement,
    setSelectedEngagement,
    showCreateConsultantModal,
    setShowCreateConsultantModal,
    stakeholders,
    setStakeholders,
    showCreateStakeholderModal,
    setShowCreateStakeholderModal,
}) => {
    return (
        <>
            <Form.Group className="mb-3" controlId="title">
                <Form.Label>Title <span style={{ color: "red" }}>*</span></Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Title"
                    {...register("title", { required: "Title is required" })}
                    isInvalid={!!errors.title}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.title?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="discussion_date">
                <Form.Label>Discussion Date<span style={{ color: "red" }}>*</span></Form.Label>
                <Form.Control
                    type="date"
                    {...register("discussion_date", { required: "Date is required" })}
                    isInvalid={!!errors.discussion_date}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.discussion_date?.message}
                </Form.Control.Feedback>
                <Form.Group className="mb-3" controlId="type">
                <Form.Label>Type <span style={{ color: "red" }}>*</span></Form.Label>
                <Form.Select
                    aria-label="Type"
                    {...register("type", { required: "Discussion type is required" })}
                    isInvalid={!!errors.type}
                >
                    <option value="Phone Call">Phone Call</option>
                    <option value="Meeting">Meeting</option>
                    <option value="Email">Email</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.type?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="length">
                <Form.Label>Length</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Length"
                    {...register("length")}
                />
            </Form.Group>
            </Form.Group>
            <UserSection
                selectedUser={selectedLead}
                setSelectedUser={setSelectedLead}
            />
             <EngagementSection
                selectedEngagement={selectedEngagement}
                setSelectedEngagement={setSelectedEngagement}
            />
            <UserArraySection
                 selectedUsers={selectedUsers}
                 setSelectedUsers={setSelectedUsers}
             />
            <ConsultantArraySection
                 selectedConsultants={selectedConsultants}
                 setSelectedConsultants={setSelectedConsultants}
                 showCreateConsultantModal={showCreateConsultantModal}
                setShowCreateConsultantModal={setShowCreateConsultantModal}
             />
             <StakeholdersSection
                 stakeholders={stakeholders}
                 setStakeholders={setStakeholders}
                 showCreateStakeholderModal={showCreateStakeholderModal}
                 setShowCreateStakeholderModal={setShowCreateStakeholderModal}
            />
        </>
    );
};

export default CreateDiscussionForm;
