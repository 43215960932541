//get tasks by filters
export const apiGetTasks = async (axiosPrivate, filters) => {
    const api_route = "/tasks"
    const urlParams = new URLSearchParams(filters).toString();
    const fullUrl = `${api_route}?${urlParams}`;
    
    console.log(`Full API Route: ${fullUrl}`);
    const response = await axiosPrivate.get(api_route, { params: filters })

    const data = response.data
    
    if(data.success)
        return data.data.tasks

    
    return []   
}

//get tasks by engagement id
export const apiGetTasksByEngagement = async (axiosPrivate, engagement_id) => {
    const api_route = `/tasks?related_engagement=${engagement_id}`;
    console.log(`Full API Route: ${api_route}`);
    const response = await axiosPrivate.get(api_route)

    const data = response.data
    console.log(data.data.tasks)
    if(data.success)
        return data.data.tasks

    
    return []   
}

//get tasks by discussion id
export const apiGetTasksByDiscussion = async (axiosPrivate, discussion_id) => {
    const api_route = `/tasks?related_discussion=${discussion_id}`;
    console.log(`Full API Route: ${api_route}`);
    const response = await axiosPrivate.get(api_route)

    const data = response.data
    console.log(data.data.tasks)
    if(data.success)
        return data.data.tasks

    
    return []   
}

//get task by ID
export const apiGetTaskByID = async (axiosPrivate, ID) => {
  const api_route = `/tasks/${ID}`;
  const response = await axiosPrivate.get(api_route);

  const data = response.data;

  if (data.success) return data.data.task;

  return [];
};

//create new task
export const apiCreateTask = async (axiosPrivate, taskData) => {
  console.log("apiCreateTask", taskData);
  const api_route = "/tasks";

  const response = await axiosPrivate.post(api_route, taskData);

  const data = response.data;
  console.log(data);

  if (data.success) return data.task;

  return null;
};

//search for tasks by a search term
export const apiSearchTasks = async (axiosPrivate, searchTerm) => {
  const api_route = "/tasks/search?searchTerm=" + searchTerm;
  try {
    const response = await axiosPrivate.get(api_route);
    const data = response.data;

    if (data && data.success) {
      return data.data.tasks;
    }
  } catch (error) {
    console.error("Error fetching tasks:", error);
    return [];
  }
};

//update a task by ID
export const apiUpdateTask = async (axiosPrivate, ID, taskData) => {
  console.log("apiUpdateTask", taskData);
  const api_route = `/tasks/${ID}`;

  const response = await axiosPrivate.patch(api_route, taskData);

  const data = response.data;
  console.log(data);

  if (data.success) return data.data.task;

  return null;
};
