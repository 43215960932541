import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUpdateTask } from "../../api/tasks";
import { toast } from 'react-toastify';
import useAxiosPrivate from "../useAxiosPrivate";

const useUpdateTask = () => {
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (taskData) => apiUpdateTask(axiosPrivate, taskData._id, taskData),
        onSuccess: (updatedTask) => {
            queryClient.setQueryData(["tasks", updatedTask._id], updatedTask);
            queryClient.invalidateQueries(["tasks"]);
        },
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error updating task.');
            }
        }
    });
};

export default useUpdateTask;
