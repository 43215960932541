import React from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { PlusCircle, XLg } from 'react-bootstrap-icons';
import SearchStakeholders from './SearchStakeholders';
import CreateStakeholderModal from './CreateStakeholderModal';
// Stakeholders section component to add and remove selected stakeholders
const StakeholdersSection = ({ stakeholders, setStakeholders, showCreateStakeholderModal, setShowCreateStakeholderModal, role }) => {

    const handleAddStakeholder = (stakeholder) => {
        setStakeholders([...stakeholders, stakeholder]);
    };

    const handleRemoveStakeholder = (index) => {
        setStakeholders(stakeholders.filter((_, i) => i !== index));
    };

    return (
        <>
            <Form.Group className="mb-3" controlId="stakeholder">
                {(role && role === "Contact")?<Form.Label>Contact</Form.Label>:<Form.Label>Stakeholder</Form.Label>}
                <SearchStakeholders 
                    handleResult={handleAddStakeholder}
                    {...(role && { role })}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="createStakeholder">
            <Button variant="link" onClick={() => setShowCreateStakeholderModal(true)}>
                {role && role === "Contact" ? (
                    <>
                        <PlusCircle /> Create new contact
                    </>
                ) : (
                    <>
                        <PlusCircle /> Create new stakeholder
                    </>
                )}
            </Button>

            </Form.Group>
            <Form.Group className="mb-3" controlId="selectedStakeholders">
            {(role && role === "Contact")?<Form.Label>Selected Contacts</Form.Label>:<Form.Label>Selected Stakeholders</Form.Label>}
            {stakeholders?.map((stakeholder, index) => (
                <InputGroup className="mb-2" key={index}>
                    <Form.Control
                        type="text"
                        value={stakeholder?.name || ""}
                        readOnly
                    />
                    <Button variant="outline-danger" onClick={() => handleRemoveStakeholder(index)}>
                        <XLg />
                    </Button>
                </InputGroup>
            ))}
        </Form.Group>
            <CreateStakeholderModal 
                show={showCreateStakeholderModal} 
                setShow={setShowCreateStakeholderModal} 
                handleResult={handleAddStakeholder} 
                role={role || 'other'}
            />
        </>
    );
};

export default StakeholdersSection;
