import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useCreateConsultant from "../../hooks/consultants/useCreateConsultant";
import "react-toastify/dist/ReactToastify.css";
import CreateConsultantForm from "./CreateConsultantForm";
import "../../css/DetailsModal.css";
//modal component to create a new consultant
const CreateConsultantModal = ({ show, setShow, handleResult }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const clearModal = () => {
    reset();
  };

  const submitForm = (data) => {
    mutate(data);
    if (handleResult) {
      handleResult(data);
    }
  };

  const handleClose = () => {
    setShow(false);
    clearModal();
  };
  const handleModalSubmit = (event) => {
    event.stopPropagation(); // Prevent the event from bubbling up to parent elements
    handleSubmit(submitForm)(event);
  };
  const { mutate } = useCreateConsultant();
  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Consultant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleModalSubmit}>
            <CreateConsultantForm errors={errors} register={register} />
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="outline-secondary" onClick={clearModal}>
                Clear
              </Button>
              <Button variant="primary" type="submit">
                Create Consultant
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateConsultantModal;
