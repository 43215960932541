import React, { useContext, useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import { Container, Form, Button, Row, Col, Alert, Card } from "react-bootstrap";
import AuthContext from "../../context/AuthProvider";
import useFetchUserById from "../../hooks/users/useFetchUserById";
import useUpdateUser from "../../hooks/users/useUpdateUser";
import Loader from "../../components/Loader/Loader";
import { Save } from "react-bootstrap-icons";

const SettingsPage = () => {
  const pageTitle = "Settings";
  const { auth } = useContext(AuthContext);
  const { data: user, isLoading, error } = useFetchUserById(auth?.user?.id);
  const { mutate: updateUser } = useUpdateUser();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone_number: "",
    notificationSettings: {
      newEngagement: false,
      newTask: false,
      completedTask: false,
      engagementStatusChange: false,
      taskReminder: false,
    },
    deliverNotification: {
      byEmail: true,
      byText: true,
    },
  });

  useEffect(() => {
    if (user) {
      setFormData({
        username: user.username || "",
        email: user.email || "",
        phone_number: user.phone_number || "",
        notificationSettings: user.notificationSettings || {
          newEngagement: false,
          newTask: false,
          completedTask: false,
          engagementStatusChange: false,
          taskReminder: false,
        },
        deliverNotification: user.deliverNotification || {
          byEmail: true,
          byText: true,
        },
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        notificationSettings: {
          ...prevData.notificationSettings,
          [name]: checked,
        },
        deliverNotification: {
          ...prevData.deliverNotification,
          [name]: checked,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUser({ _id: user?._id, ...formData });
  };

  if (isLoading) return <Loader />;
  if (error) return <Alert variant="danger">An error occurred: {error.message}</Alert>;

  return (
    <Container className="page-container mt-3">
      <PageHeader title={pageTitle} className="ems-page-title" />
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} controlId="formUsername" className="mb-3">
              <Form.Label column sm={3}><b>Username</b></Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formEmail" className="mb-3">
              <Form.Label column sm={3}><b>Email</b></Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPhoneNumber" className="mb-3">
              <Form.Label column sm={3}><b>Phone Number</b></Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="phone_number"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formNotificationSettings" className="mb-3">
              <Form.Label column sm={3}><b>Notifications</b></Form.Label>
              <Col sm={9}>
                <Row>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      name="newEngagement"
                      label="New Engagement"
                      checked={formData.notificationSettings.newEngagement}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      name="newTask"
                      label="New Task"
                      checked={formData.notificationSettings.newTask}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      name="completedTask"
                      label="Completed Task"
                      checked={formData.notificationSettings.completedTask}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      name="engagementStatusChange"
                      label="Engagement Status Change"
                      checked={formData.notificationSettings.engagementStatusChange}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      name="taskReminder"
                      label="Task Reminder"
                      checked={formData.notificationSettings.taskReminder}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formDeliverNotification" className="mb-3">
              <Form.Label column sm={3}><b>Deliver Notifications Method</b></Form.Label>
              <Col sm={9}>
                <Row>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      name="byEmail"
                      label="By Email"
                      checked={formData.deliverNotification.byEmail}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      name="byText"
                      label="By Text"
                      checked={formData.deliverNotification.byText}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>

            <Button variant="outline-primary" type="submit" className="float-end">
              <Save /> Save
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SettingsPage;
