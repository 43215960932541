import { useQuery } from "@tanstack/react-query";
import { apiGetStakeholders } from "../../api/stakeholders";
import useAxiosPrivate from "../useAxiosPrivate";
import { toast } from 'react-toastify';

const useFetchStakeholders = (filters) => {
    const axiosPrivate = useAxiosPrivate();

    return useQuery({
        queryKey: ['stakeholders', filters],
        queryFn: async () => apiGetStakeholders(axiosPrivate, filters),
        enabled: !!filters,
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error fetching stakeholders');
            }
        }
    });
};

export default useFetchStakeholders;
