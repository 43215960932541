import React, { useState, useEffect, useRef } from "react";
import StakeholderDetailsTable from "../../components/Stakeholders/StakeholderDetailsTable";
import useFetchStakeholderById from "../../hooks/stakeholders/useFetchStakeholderById";
import useFetchEngagementsByStakeholder from "../../hooks/engagements/useFetchEngagementsByStakeholder";
import useFetchDiscussionsByParticipant from "../../hooks/discussions/useFetchDiscussionsByParticipant";
import useFetchEngagementsByContact from "../../hooks/engagements/useFetchEngagementsByContact";
import useFetchOrganizationById from "../../hooks/organizations/useFetchOrganizationById";
import OrganizationDetailsTable from "../../components/Organizations/OrganizationDetailsTable";
import EngagementSummaryTable from "../../components/Engagements/EngagementSummaryTable";
import DiscussionSummaryTable from "../../components/Discussions/DiscussionSummaryTable";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import ReactQuill from "react-quill";
import useUpdateStakeholder from "../../hooks/stakeholders/useUpdateStakeholder";
import "react-quill/dist/quill.snow.css";
import { debounce } from "lodash";

const StakeholderDetailsPage = () => {
  const { id } = useParams();
  const [notes, setNotes] = useState("");
  const { mutate } = useUpdateStakeholder();

  const {
    isLoading,
    error,
    data: stakeholder,
  } = useFetchStakeholderById(id);

  const {
    isLoading1,
    error1,
    data: related_engagements,
  } = useFetchEngagementsByStakeholder(id);

  const {
    isLoading2,
    error2,
    data: participated_discussions,
  } = useFetchDiscussionsByParticipant(id, "stakeholder");

  const {
    isLoading3,
    error3,
    data: assigned_engagements,
  } = useFetchEngagementsByContact(id);
  const organizationId = stakeholder?.organization?._id;
  const {
    isLoading4,
    error4,
    data: organization,
  } = useFetchOrganizationById(organizationId || '');
  useEffect(() => {
    if (stakeholder) {
      setNotes(stakeholder.notes);
    }
  }, [stakeholder]);

  const debouncedMutate = useRef(
    debounce((newNotes) => {
      mutate({ _id: id, notes: newNotes });
    }, 300)
  ).current;

  const handleNoteChange = (value) => {
    setNotes(value);
    debouncedMutate(value);
  };

  if (isLoading || isLoading1 || isLoading2 || isLoading3 || (isLoading4&&organizationId)) return <Loader />;
  if (error) return <p>An error occurred: {error.message}</p>;
  if (error1) return <p>An error occurred: {error1.message}</p>;
  if (error2) return <p>An error occurred: {error2.message}</p>;
  if (error3) return <p>An error occurred: {error3.message}</p>;
    if (error4) return <p>An error occurred: {error4.message}</p>;
  return (
    <Container className="mt-3">
      <Row className="mt-3">
        <Col>
          <h4>Stakeholder Details</h4>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Tabs
            defaultActiveKey="details"
            id="stakeholder-details-tabs"
            className="mb-3"
          >
            <Tab eventKey="details" title="Details">
              <ReactQuill
                value={notes}
                onChange={handleNoteChange}
                theme="snow"
              />
              <StakeholderDetailsTable stakeholder={stakeholder} />
            </Tab>
            <Tab eventKey="organization" title="organization">
            {organization && <OrganizationDetailsTable organization={organization} />}
            </Tab>
            {stakeholder?.role === "Contact" && (
              <Tab eventKey="assigned_engagements" title="Assigned Engagements">
                <EngagementSummaryTable data={assigned_engagements} />
              </Tab>
            )}
             {stakeholder?.role === "Other" && (
              <Tab eventKey="related_engagements" title="Related Engagements">
                <EngagementSummaryTable data={related_engagements} />
              </Tab>
            )}
            <Tab eventKey="discussion" title="Participated Discussions">
              <DiscussionSummaryTable data={participated_discussions} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default StakeholderDetailsPage;
