//get organizations by filters
export const apiGetOrganizations = async (axiosPrivate, filters) => {
    const api_route = "/organizations"
    const urlParams = new URLSearchParams(filters).toString();
    const fullUrl = `${api_route}?${urlParams}`;
    
    console.log(`Full API Route: ${fullUrl}`);
    const response = await axiosPrivate.get(api_route, { params: filters })

    const data = response.data
    
    if(data.success)
    return data.data.organizations

    
    return []   
}

//get organization by ID
export const apiGetOrganizationByID = async (axiosPrivate, ID) => {
    const api_route = `/organizations/${ID}`;
    const response = await axiosPrivate.get(api_route)

    const data = response.data
    
    if(data.success)
        return data.data.organization

    
    return []   
}

//create a new organization
export const apiCreateOrganization = async (axiosPrivate, organizationData) => {
    console.log("apiCreateOrganization", organizationData)
    const api_route = "/organizations"

    const response = await axiosPrivate.post(api_route, organizationData)

    const data = response.data
    console.log(data)

    if(data.success)
        return data.organization

    return null
}

//search for organizations by a search term
export const apiSearchOrganizations = async (axiosPrivate, searchTerm) => {
    const api_route = "/organizations/search?searchTerm=" + searchTerm;
    try {
        const response = await axiosPrivate.get(api_route);
        const data = response.data;

        if (data && data.success) {
            return data.data.organizations;
        } 
    } catch (error) {
        console.error("Error fetching organizations:", error);
        return [];
    }
};

//update an organization by ID
export const apiUpdateOrganization = async (axiosPrivate, ID, organizationData) => {
    console.log("apiUpdateOrganization", organizationData)
    const api_route = `/organizations/${ID}`;

    const response = await axiosPrivate.patch(api_route, organizationData)

    const data = response.data
    console.log(data)

    if(data.success)
        return data.data.organization

    return null
}