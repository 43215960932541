// Function to fetch consultants with optional filters
export const apiGetConsultants = async (axiosPrivate, filters) => {
    const api_route = "/consultants";
    const urlParams = new URLSearchParams(filters).toString();
    const fullUrl = `${api_route}?${urlParams}`; 
    console.log(`Full API Route: ${fullUrl}`);
    const response = await axiosPrivate.get(api_route, { params: filters });

    const data = response.data;
    
    if(data.success)
        return data.data.consultants;
    
    return [];
};

// Function to fetch a consultant by ID
export const apiGetConsultantByID = async (axiosPrivate, ID) => {
    const api_route = `/consultants/${ID}`;
    const response = await axiosPrivate.get(api_route);

    const data = response.data;
    
    if(data.success)
        return data.data.consultant;
    
    return [];
};

// Function to create a new consultant
export const apiCreateConsultant = async (axiosPrivate, consultantData) => {
    console.log("apiCreateConsultant", consultantData);
    const api_route = "/consultants";

    const response = await axiosPrivate.post(api_route, consultantData);

    const data = response.data;
    console.log(data);

    if(data.success)
        return data.consultant;

    return null;
};

// Function to search for consultants by a search term
export const apiSearchConsultants = async (axiosPrivate, searchTerm) => {
    const api_route = "/consultants/search?searchTerm=" + searchTerm;
    try {
        const response = await axiosPrivate.get(api_route);
        const data = response.data;

        if (data && data.success) {
            return data.data.consultants;
        } 
    } catch (error) {
        console.error("Error fetching consultants:", error);
        return [];
    }
};

// Function to update a consultant by ID
export const apiUpdateConsultant = async (axiosPrivate, ID, consultantData) => {
    console.log("apiUpdateConsultant", consultantData);
    const api_route = `/consultants/${ID}`;

    const response = await axiosPrivate.patch(api_route, consultantData);

    const data = response.data;
    console.log(data);

    if(data.success)
        return data.data.consultant;

    return null;
};
