import React, { useState } from "react";
import { Modal} from "react-bootstrap";
import useSearchConsultants from "../../hooks/consultants/useSearchConsultants";
import SearchForm from "../SearchForm";
import ConsultantSummaryTable from "./ConsultantSummaryTable";
//modal component to search consultants
const SearchConsultantsModal = ({ show, handleClose }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const { isFetching, data, refetch} = useSearchConsultants(searchTerm);
  
  const handleSearch = (term) => {
    setSearchTerm(term);
    refetch();
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  return (
    <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Search Consultants</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <SearchForm onSearch={handleSearch} onClear={handleClear} />
      {isFetching && <p>Loading...</p>}
      {data && data.length !== 0 && (
        <div className="searchResults">
          <ConsultantSummaryTable data={data} />
        </div>
      )}
      {data && data.length === 0 && (
        <div>
          No consultants found.
        </div>
      )}
      </Modal.Body>
    </Modal>
  );
};

export default SearchConsultantsModal;
