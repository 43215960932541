import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUpdateUser } from "../../api/users";
import { toast } from 'react-toastify';
import useAxiosPrivate from "../useAxiosPrivate";

const useUpdateUser = () => {
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (userData) => apiUpdateUser(axiosPrivate, userData._id, userData),
        onSuccess: (updatedUser) => {
            queryClient.setQueryData(["users", updatedUser._id], updatedUser);
            queryClient.invalidateQueries(["users"]);
        },
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error updating user.');
            }
        }
    });
};

export default useUpdateUser;
