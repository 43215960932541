import React from "react";
import { Dropdown, Form } from "react-bootstrap";
// Dropdown list component to sort tasks
const SortTaskDropdownList = ({ sortField, handleSortFieldChange }) => {
  return (
    <Dropdown.Item as="div">
      <Form.Label>Sort By</Form.Label>
      <Form.Check
        type="radio"
        label="Created At"
        name="sortField"
        value="createdAt"
        checked={sortField === "createdAt"}
        onChange={handleSortFieldChange}
      />
      <Form.Check
        type="radio"
        label="Updated At"
        name="sortField"
        value="updatedAt"
        checked={sortField === "updatedAt"}
        onChange={handleSortFieldChange}
      />
      <Form.Check
        type="radio"
        label="Deadline"
        name="sortField"
        value="deadline"
        checked={sortField === "deadline"}
        onChange={handleSortFieldChange}
      />
      <Form.Check
        type="radio"
        label="Completed At"
        name="sortField"
        value="Completed At"
        checked={sortField === "Completed At"}
        onChange={handleSortFieldChange}
      />
      <Form.Check
        type="radio"
        label="Last Viewed At"
        name="sortField"
        value="seen.last_viewed_at"
        checked={sortField === "seen.last_viewed_at"}
        onChange={handleSortFieldChange}
      />
    </Dropdown.Item>
  );
};
export default SortTaskDropdownList;
