import React from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import SearchUsers from './SearchUsers';
// Assigned To Section component
const AssignedToSection = ({ selectedUser, setSelectedUser }) => {
    const handleAddUser = (user) => {
        setSelectedUser([...selectedUser, user]);
    };

    const handleRemoveUser = (index) => {
        setSelectedUser(selectedUser.filter((_, i) => i !== index));
    };
    return (
        <>
            <Form.Group className="mb-3" controlId="assigned_to">
                <Form.Label>Assigned To<span style={{ color: "red" }}>*</span></Form.Label>
                <SearchUsers handleResult={handleAddUser} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="selectedUser">
            <Form.Label>Selected User</Form.Label>
            {selectedUser?.map((user, index) => (
                <InputGroup className="mb-2" key={index}>
                    <Form.Control
                        type="text"
                        value={user?.username || ""}
                        readOnly
                    />
                    <Button variant="outline-danger" onClick={() => handleRemoveUser(index)}>
                        <XLg />
                    </Button>
                </InputGroup>
            ))}
            </Form.Group>
        </>
    );
};

export default AssignedToSection;
