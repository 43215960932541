import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiCreateStakeholder } from "../../api/stakeholders";
import { toast } from 'react-toastify';
import useAxiosPrivate from "../useAxiosPrivate";

const useCreateStakeholder = () => {
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (stakeholderData) => apiCreateStakeholder(axiosPrivate, stakeholderData),
        onSuccess: (newStakeholder) => {
            toast.success("stakeholder created successfully!");
            queryClient.setQueryData(["stakeholders", newStakeholder._id], newStakeholder);
            queryClient.invalidateQueries(["stakeholders"]);
        },
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error creating stakeholder');
            }
        }
    });
};

export default useCreateStakeholder;
