import React from "react";
import { Dropdown, Form } from "react-bootstrap";
//Dropdown list componnet to filter the discussions
const FilterDiscussionDropdownList = ({ filters, handleFilterChange }) => {
  return (
    <>
      <Dropdown.Item as="div">
        <Form.Check
          type="checkbox"
          label="Created By Me"
          name="createdByMe"
          checked={filters.createdByMe}
          onChange={handleFilterChange}
        />
      </Dropdown.Item>
      <Dropdown.Item as="div">
        <Form.Check
          type="checkbox"
          label="Lead By Me"
          name="leadByMe"
          checked={filters.LeadByMe}
          onChange={handleFilterChange}
        />
      </Dropdown.Item>
      <Dropdown.Item as="div">
        <Form.Check
          type="checkbox"
          label="Participate by Me"
          name="participatedByMe"
          checked={filters.participatedByMe}
          onChange={handleFilterChange}
        />
      </Dropdown.Item>
    </>
  );
};

export default FilterDiscussionDropdownList;
