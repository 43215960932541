import React, { useState } from "react";
import PageHeader from "../../components/PageHeader";
import { Container, Tabs, Tab, Button, Row, Col } from "react-bootstrap";
import Loader from "../../components/Loader/Loader";
import StakeholderDetailsModal from "../../components/Stakeholders/StakeholderDetailsModal";
import StakeholdersTable from "../../components/Stakeholders/StakeholdersTable";
import useFetchStakeholders from "../../hooks/stakeholders/useFetchStakeholders";
import useUpdateStakeholder from "../../hooks/stakeholders/useUpdateStakeholder";
import "react-toastify/dist/ReactToastify.css";
import { Search } from "react-bootstrap-icons";
import SearchStakeholdersModal from "../../components/Stakeholders/SearchStakeholdersModal";
const StakeholdersPage = () => {
  const pageTitle = "Stakeholders";
  const [selectedStakeholder, setSelectedStakeholder] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const { isLoading, error, data } = useFetchStakeholders(
    convertFiltersToParams(activeTab)
  );
  const filteredData = data?.filter(stakeholder => stakeholder.status !== "Deleted");
  const handleShowDetails = (stakeholder) => {
    setSelectedStakeholder(stakeholder);
    setShowDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedStakeholder(null);
  };
  const { mutate } = useUpdateStakeholder();

  const handleArchive = (stakeholder) => {
    mutate({ _id: stakeholder._id, status: "Deleted" });
  };

  const handleResumeArchive = (stakeholder) => {
    mutate({ _id: stakeholder._id, status: "Active" });
  };

  const handleRoleChange = (stakeholder, newRole) => {
    mutate({ _id: stakeholder._id, role: newRole });
  };
  if (isLoading) return <Loader />;

  if (error) return "An error occured: " + error.message;

  return (
    <>
      <Container className="page-container">
        <PageHeader title={pageTitle} className="ems-page-title" />
        <Row className="mb-3">
          <Col>
            <Button
              variant="outline-secondary iconButton"
              onClick={() => setShowSearchModal(true)}
            >
              <Search /> Search
            </Button>
          </Col>
        </Row>
        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
          <Tab eventKey="all" title="All">
            <StakeholdersTable
              data={filteredData}
              handleShowDetails={handleShowDetails}
              handleRoleChange={handleRoleChange}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
            />
          </Tab>
          <Tab eventKey="Contact" title="Contact">
            <StakeholdersTable
              data={data}
              handleShowDetails={handleShowDetails}
              handleRoleChange={handleRoleChange}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
            />
          </Tab>
          <Tab eventKey="Other" title="Other">
            <StakeholdersTable
              data={data}
              handleShowDetails={handleShowDetails}
              handleRoleChange={handleRoleChange}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
            />
          </Tab>
          <Tab eventKey="Deleted" title="Deleted">
            <StakeholdersTable
              data={data}
              handleShowDetails={handleShowDetails}
              handleRoleChange={handleRoleChange}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
            />
          </Tab>
        </Tabs>
        <StakeholderDetailsModal
          show={showDetailsModal}
          handleClose={handleCloseDetailsModal}
          stakeholder={selectedStakeholder}
        />
        <SearchStakeholdersModal
          show={showSearchModal}
          handleClose={() => setShowSearchModal(false)}
        />
      </Container>
    </>
  );
};

const convertFiltersToParams = (status) => {
  let params = {};
  if (status && status === "Deleted") {
    params["status"] = status;
    return params;
  }
  if (status && status !== "all") params["role"] = status;
  return params;
};

export default StakeholdersPage;
