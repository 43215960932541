import React from "react";
import { Dropdown, Form } from "react-bootstrap";
const SortOrderDropdownList = ({ sortOrder, handleSortOrderChange }) => {
  return(
    <>
      <Dropdown.Item as="div">
        <Form.Label>Sort Order</Form.Label>
        <Form.Check
          type="radio"
          label="Ascending"
          name="sortOrder"
          value="asc"
          checked={sortOrder === "asc"}
          onChange={handleSortOrderChange}
        />
        <Form.Check
          type="radio"
          label="Descending"
          name="sortOrder"
          value="desc"
          checked={sortOrder === "desc"}
          onChange={handleSortOrderChange}
        />
      </Dropdown.Item>
      ;
    </>
  );
};
export default SortOrderDropdownList;
