import { Modal, Button } from "react-bootstrap";
import React from "react";
import EngagementDetailsTable from "./EngagementDetailsTable";
import ConsultantDetailsTable from "./../Consultants/ConsultantDetailsTable";
import OrganizationDetailsTable from "../Organizations/OrganizationDetailsTable";
import StakeholderDetailsTable from "./../Stakeholders/StakeholderDetailsTable";
import "../../css/DetailsModal.css";
//modal component to display engagement details
const EngagementDetailsModal = ({ show, handleClose, engagement }) => {
  if (!engagement) return null;
  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Engagement Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EngagementDetailsTable
            engagement={engagement}
          />
          <h5>
            <b>Consultant</b>
          </h5>
          <ConsultantDetailsTable consultant={engagement?.consultant} />
          <h5>
            <b>Client</b>
          </h5>
          <OrganizationDetailsTable organization={engagement?.client} />
          <h5>
            <b>Contacts</b>
          </h5>
          <StakeholderDetailsTable stakeholder={engagement?.contacts} />
          <h5>
            <b>Stakeholders</b>
          </h5>
          <StakeholderDetailsTable stakeholder={engagement?.stakeholders} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EngagementDetailsModal;
