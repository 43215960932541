import React, {useState} from "react";
import { Table, Button} from "react-bootstrap";
import { PencilSquare, ArrowRightSquare } from "react-bootstrap-icons";
import UpdateStakeholderModal from './UpdateStakeholderModal';
import StakeholderStatusBadge from "./StakeholderStatusBadge";
import { Link } from "react-router-dom";
//table component to display stakeholder details in stakeholder details page
const StakeholderDetailsTable = ({ stakeholder }) => {
  const stakeholderArray = Array.isArray(stakeholder)
    ? stakeholder
    : [stakeholder];
    const [showEditStakeholderModal, setShowEditStakeholderModal] = useState(false);
    const [selectedStakeholder, setSelectedStakeholder] = useState(null);
    const handleUpdateStakeholder = (stakeholder) => {
        setSelectedStakeholder(stakeholder);
      setShowEditStakeholderModal(true);
      };
  
      const handleCloseUpdateStakeholder = () => {
      setShowEditStakeholderModal(false);
      setSelectedStakeholder(null);
      };
  return (
    <>
    <Table bordered>
      <tbody>
        {stakeholderArray.map((stakeholder, index) => (
          <React.Fragment key={index}>
            <tr>
              <th></th>
              <td><Button
              variant="outline-secondary"
              className="me-2"
              onClick={() => handleUpdateStakeholder(stakeholder)}
            >
              <PencilSquare />
            </Button> <Link
                to={`/stakeholders/${stakeholder?._id}`}
                className="btn btn-outline-secondary me-2"
                target="_blank"
              >
                <ArrowRightSquare />
              </Link></td>
          </tr>
            <tr>
              <th>Name</th>
              <td>{stakeholder?.name} <StakeholderStatusBadge status={stakeholder?.status} role={stakeholder?.role}/>
              </td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{stakeholder?.email}</td>
            </tr>
            <tr>
              <th>Phone Number</th>
              <td>{stakeholder?.phone_number}</td>
            </tr>
            {stakeholder?.organization?.organization_name && <tr>
              <th>Organization</th>
              <td>{stakeholder?.organization?.organization_name}</td>
            </tr>}
            <tr>
              <th>Position</th>
              <td>{stakeholder?.position}</td>
            </tr>
            <tr>
              <th>Notes</th>
              <td dangerouslySetInnerHTML={{ __html: stakeholder?.notes }}></td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </Table>
    <UpdateStakeholderModal
    show={showEditStakeholderModal}
    handleClose={handleCloseUpdateStakeholder}
    stakeholder={selectedStakeholder}
/>
</>
  );
};

export default StakeholderDetailsTable;
