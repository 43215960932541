import { useState } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Form, Button } from "react-bootstrap"
import useAuth from "../../hooks/useAuth"

import "./loginPage.css"

//assets
import logo from "../../assets/logos/twg.png"

import { apiLogin } from "../../api/auth.js"

const LoginPage = () => {

    const {auth, setAuth} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
   
    const from = location.state?.from?.pathname || "/";
    

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loginErrorMessage, setLoginErrorMessage] = useState(false)

    const handleLogin = async (event) => {
        event.preventDefault()
        if(username == null || password == null || username == "" || password == "")
        {
            setLoginErrorMessage("Must provide username and password.")
            return
        }
        await apiLogin({username, password}, (response) => {
            const accessToken = response.data.data.accessToken
            localStorage.setItem('auth', response.data.data.id);
            setAuth({user: {username: response.data.data.username, id: response.data.data.id}, accessToken})
            navigate(from, { replace: true });
        }, (error) => {
            setLoginErrorMessage("Login error. Please try again later.")
        })
    }

    return (
        <>
            {!!auth?.user ? <Navigate to="/dashboard" replace={true} /> :
            <div className="login-box">
                <Form>
                    <div className="login-header-box">
                        <img className="login-logo" src={logo}></img>
                        <h3 className="login-header">EMS</h3>
                    </div>
                    <div className="login-fields-box">
                        
                            <Form.Group className="mb-3" controlId="username">
                                <Form.Control value={username} onChange={(event) => setUsername(event.target.value)} type="email" placeholder="Username" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Control value={password} onChange={(event) => setPassword(event.target.value)} type="password" placeholder="Password" />
                            </Form.Group>
                            
                        
                    </div>
                    <div className="login-button-box">
                                {loginErrorMessage ? <p className="form-error">{loginErrorMessage}</p> : <></>}
                                <Button variant="light" onClick={(event) => handleLogin(event)}>Login</Button>
                    </div>
                </Form>

            </div>
            }
        </>
    )
}

export default LoginPage;