import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUpdateEngagement } from "../../api/engagements";
import { toast } from 'react-toastify';
import useAxiosPrivate from "../useAxiosPrivate";

const useUpdateEngagement = () => {
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (engagementData) => apiUpdateEngagement(axiosPrivate, engagementData._id, engagementData),
        onSuccess: (updatedEngagement) => {
            queryClient.setQueryData(["engagements", updatedEngagement._id], updatedEngagement);
            queryClient.invalidateQueries(["engagements"]);
        },
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error updating engagement.');
            }
        }
    });
};

export default useUpdateEngagement;
