import React, {useState} from "react";
import { Table, Button } from "react-bootstrap";
import { PencilSquare, ArrowRightSquare } from "react-bootstrap-icons";
import OrganizationStatusBadge from "./OrganizationStatusBadge";
import UpdateOrganizationModal from './UpdateOrganizationModal';
import { Link } from "react-router-dom";
//table component to display organization details in organization details page
const OrganizationDetailsTable = ({
  organization,
}) => {
  const organizationArray = Array.isArray(organization)
    ? organization
    : [organization];
    const [showEditClientModal, setShowEditClientModal] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const handleUpdateClient = (organization) => {
        setSelectedOrganization(organization);
        setShowEditClientModal(true);
        };
    
        const handleCloseUpdateClient = () => {
        setShowEditClientModal(false);
        setSelectedOrganization(null);
        };
  return (
    <>
      <Table bordered>
        <tbody>
          {organizationArray.map((organization, index) => (
            <React.Fragment key={index}>
                <tr>
                <th></th>
                <td><Button
            variant="outline-secondary"
            className="me-2"
            onClick={() => handleUpdateClient(organization)}
          >
            <PencilSquare />
          </Button><Link
                to={`/organizations/${organization?._id}`}
                className="btn btn-outline-secondary me-2"
                target="_blank"
              >
                <ArrowRightSquare />
              </Link></td>
          
              </tr>
              <tr>
                <th>Name</th>
                <td>{organization?.organization_name} <OrganizationStatusBadge status={organization?.status} /></td>
              </tr>
              <tr>
                <th>Acronym</th>
                <td>{organization?.acronym}</td>
              </tr>
              <tr>
                <th>Division</th>
                <td>{organization?.division}</td>
              </tr>
              <tr>
                <th>Department</th>
                <td>{organization?.department}</td>
              </tr>
              <tr>
                <th>Notes</th>
                <td dangerouslySetInnerHTML={{ __html: organization?.notes }}></td>
              </tr>

              <tr>
                <th>Office Locations</th>
                <td>{organization?.office_locations}</td>
              </tr>
              <tr>
                <th>Service Regions</th>
                <td>{organization?.service_regions}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      <UpdateOrganizationModal
                show={showEditClientModal}
                handleClose={handleCloseUpdateClient}
                organization={selectedOrganization}
            />
    </>
  );
};

export default OrganizationDetailsTable;
