import React from "react";
import { Table } from "react-bootstrap";
import { ArrowRightSquare } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import TaskStatusBadge from "./TaskStatusBadge";
// Table component to display the tasks summary
const TaskSummaryTable = ({ data }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Title</th>
          <th>Deadline</th>
          <th>Notes</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((task) => (
          <tr key={task?._id}>
            <td>
              {task.title}
              <TaskStatusBadge
                type={task?.type}
                status={task?.status}
                priority={task?.priority}
                viewed={task?.seen.viewed}
              />
            </td>
            <td>{new Date(task.deadline).toISOString().split("T")[0]}</td>
            <td dangerouslySetInnerHTML={{ __html: task?.notes }}></td>
            <td className="table-actions">
              <Link
                to={`/tasks/${task?._id}`}
                className="btn btn-outline-secondary me-2"
                target="_blank"
              >
                <ArrowRightSquare />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TaskSummaryTable;
