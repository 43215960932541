import React from "react";
import { Form } from "react-bootstrap";
import ClientSection from "../Organizations/ClientSection";
import InputMask from "react-input-mask";
// Form to create a new stakeholder
const CreateStakeholderForm = ({
  errors,
  register,
  role,
  selectedOrganization,
  setSelectedOrganization,
  showCreateOrganizationModal,
  setShowCreateOrganizationModal,
}) => {
  return (
    <>
      <Form.Group className="mb-3" controlId="name">
        <Form.Label>
          Name <span style={{ color: "red" }}>*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Name"
          {...register("name", { required: "Name is required" })}
          isInvalid={!!errors.name}
        />
        <Form.Control.Feedback type="invalid">
          {errors.name && errors.name.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="phone_number">
        <Form.Label>Phone Number</Form.Label>
        <InputMask
          mask="(999) 999-9999"
          {...register("phone_number", {
            pattern: {
              value: /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/,
              message: "Invalid phone number format",
            },
          })}
        >
          {(inputProps) => (
            <Form.Control
              type="text"
              placeholder="Enter Phone Number"
              isInvalid={!!errors.phone_number}
              {...inputProps}
            />
          )}
        </InputMask>
        <Form.Control.Feedback type="invalid">
          {errors.phone_number && errors.phone_number.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="role">
        <Form.Label>Role</Form.Label>
        <Form.Select
          aria-label="role"
          {...register("role")}
          defaultValue={role}
        >
          <option value="Contact">Contact</option>
          <option value="Other">Other</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3" controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter Email"
          {...register("email", {
            pattern: {
              value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
              message: "Invalid email format",
            },
          })}
          isInvalid={!!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email && errors.email.message}
        </Form.Control.Feedback>
      </Form.Group>
      <ClientSection
        selectedOrganization={selectedOrganization}
        setSelectedOrganization={setSelectedOrganization}
        showCreateOrganizationModal={showCreateOrganizationModal}
        setShowCreateOrganizationModal={setShowCreateOrganizationModal}
      />
      <Form.Group className="mb-3" controlId="position">
        <Form.Label>Position</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Position"
          {...register("position")}
        />
      </Form.Group>
    </>
  );
};

export default CreateStakeholderForm;
