import React from 'react';
import { Badge } from 'react-bootstrap';
//component to display the type, status, priority and viewed status of the task in a badge
const TaskStatusBadge = ({ type, status, priority, viewed }) => {
  let bg = null;
  let bg1 = null;
  let bg2 = null;
  const bg3 = viewed ? 'info' : null;

  switch (type) {
    case 'Regular':
      bg = 'info';
      break;
    case 'Recurring':
      bg = 'success';
      break;
    default:
      bg = null;
  }
  switch (status) {
    case 'Todo':
      bg1 = 'success';
      break;
    case 'InProgress':
      bg1 = 'primary';
      break;
    case 'Completed':
      bg1= 'secondary';
      break;
    default:
      bg1 = null;
  }
  switch (priority) {
    case 'High':
      bg2 = 'danger';
      break;
    case 'Medium':
      bg2 = 'warning';
      break;
    case 'Low':
      bg2 = 'success';
      break;
    default:
      bg2 = null;
  }

  return (
    <>
      {bg && (<Badge bg={bg} className="me-2">
        {type}
      </Badge>
      )}
      {bg1 && (<Badge bg={bg1} className="me-2">
        {status}
      </Badge>
      )}
      {bg2 && (<Badge bg={bg2} className="me-2">
        {priority}
      </Badge>
      )}
      {bg3 && (<Badge bg={bg3} className="me-2">
        viewed
      </Badge>
      )}
    </>
  );
};

export default TaskStatusBadge;
