import React from "react";
import { Table, Button, Form } from "react-bootstrap";
import { Trash, ArrowRightSquare } from "react-bootstrap-icons";
import OrganizationStatusBadge from "./OrganizationStatusBadge";
import { Link } from "react-router-dom";
// Table component to display organizations in organizations page
const OrganizationsTable = ({
  data,
  handleShowDetails,
  handleStatusChange,
  handleArchive,
  handleResumeArchive,
}) => {
    const statusOptions = ["Organization", "Client"];
    const handleDeleteClick = (organization) => {
      if (window.confirm("Are you sure you want to delete this organization?")) {
          handleArchive(organization);
      }
  };
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Acronym</th>
          <th>Division</th>
          <th>Department</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((organization) => (
          <tr key={organization._id}>
            <td>
              <Button
                variant="link"
                onClick={() => handleShowDetails(organization)}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                {organization.organization_name}
              </Button>
              <OrganizationStatusBadge status={organization.status} />
            </td>
            <td>{organization.acronym}</td>
            <td>{organization.division}</td>
            <td>{organization.department}</td>
            <td> <Form.Select
                value={organization.status}
                onChange={(e) => handleStatusChange(organization, e.target.value)}
              >
                {statusOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select></td>
            <td className="table-actions">
              <Button
                variant="outline-secondary"
                className="me-2"
                onClick={() =>
                  organization.status === "Deleted"
                    ? handleResumeArchive(organization)
                    : handleDeleteClick(organization)
                }
              >
               <Trash />
              </Button>
              <Link
                to={`/organizations/${organization?._id}`}
                className="btn btn-outline-secondary me-2"
                target="_blank"
              >
                <ArrowRightSquare />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default OrganizationsTable;
