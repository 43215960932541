
export const apiSearchOpps = async (axiosPrivate, searchTerm, numItems = 10, batchNum = 1) => {
    const api_route = "/opps/search?searchTerm=" + searchTerm + "&limit=" + numItems + "&batchNum=" + batchNum
    
    const response = await axiosPrivate.get(api_route)

    const data = await response.data

    if(data.success){
        return data.opps
    }
    
    return []   
}