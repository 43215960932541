import React, { useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useUpdateConsultant from "../../hooks/consultants/useUpdateConsultant";
import "react-toastify/dist/ReactToastify.css";
import CreateConsultantForm from "./CreateConsultantForm";
import "../../css/DetailsModal.css";
//modal component to update consultant
const UpdateConsultantModal = ({ show, handleClose, consultant }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: consultant,
  });

  useEffect(() => {
    if (consultant) {
      setValue("name", consultant?.name);
      setValue("phone_number", consultant?.phone_number);
      setValue("email", consultant?.email);
      setValue("company_name", consultant?.company_name);
      setValue("status", consultant?.status);
      setValue("oms_id", consultant?.oms_id);
    }
  }, [consultant, setValue]);

  const filterNullValues = (data) => {
    return Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== null)
    );
  };
  const clearModal = () => {
    reset();
  };
  const handleCloseUpdate = () => {
    clearModal();
    handleClose();
  };
  const submitForm = (data) => {
    data = {
      ...data,
      _id: consultant?._id,
    };
    const filteredData = filterNullValues(data);
    mutate(filteredData);
  };

  const { mutate } = useUpdateConsultant();

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleCloseUpdate}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Consultant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(submitForm)}>
            <CreateConsultantForm
              errors={errors}
              register={register}
            />
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="outline-secondary" onClick={clearModal}>
                Clear
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={handleSubmit(submitForm)}
              >
                Update
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateConsultantModal;
