//get stakeholders by filters
export const apiGetStakeholders = async (axiosPrivate, filters) => {
    const api_route = "/stakeholders"
    const urlParams = new URLSearchParams(filters).toString();
    const fullUrl = `${api_route}?${urlParams}`;
    
    console.log(`Full API Route: ${fullUrl}`);
    const response = await axiosPrivate.get(api_route, { params: filters })

    const data = response.data
    
    if(data.success)
    return data.data.stakeholders

    
    return []   
}

//get stakeholder by ID
export const apiGetStakeholderByID = async (axiosPrivate, ID) => {
    const api_route = `/stakeholders/${ID}`;
    const response = await axiosPrivate.get(api_route)

    const data = response.data
    
    if(data.success)
        return data.data.stakeholder

    
    return []   
}

//get stakeholders by organization id
export const apiGetStakeholdersByOrganization = async (axiosPrivate, organization_id) => {
    const api_route = `/stakeholders?organization=${organization_id}`;
    console.log(`Full API Route: ${api_route}`);
    const response = await axiosPrivate.get(api_route)

    const data = response.data
    console.log(data)
    if(data.success)
        return data.data.stakeholders

    
    return []   
}

//create new stakeholder
export const apiCreateStakeholder = async (axiosPrivate, stakeholderData) => {
    console.log("apiCreateStakeholder", stakeholderData)
    const api_route = "/stakeholders"

    const response = await axiosPrivate.post(api_route, stakeholderData)

    const data = response.data
    console.log(data)

    if(data.success)
        return data.stakeholder

    return null
}

//search for stakeholders by a search term
export const apiSearchStakeholders = async (axiosPrivate, searchTerm) => {
    const api_route = "/stakeholders/search?searchTerm=" + searchTerm;
    try {
        const response = await axiosPrivate.get(api_route);
        const data = response.data;

        if (data && data.success) {
            return data.data.stakeholders;
        } 
    } catch (error) {
        console.error("Error fetching stakeholders:", error);
        return [];
    }
};

//update stakeholder by ID
export const apiUpdateStakeholder = async (axiosPrivate, ID, stakeholderData) => {
    console.log("apiUpdateStakeholder", stakeholderData)
    const api_route = `/stakeholders/${ID}`;

    const response = await axiosPrivate.patch(api_route, stakeholderData)

    const data = response.data
    console.log(data)

    if(data.success)
        return data.data.stakeholder

    return null
}