import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { PlusLg } from "react-bootstrap-icons";
import useSearchUsers from "../../hooks/users/useSearchUsers";
import SearchForm from "../SearchForm";
//display search results for users
const SearchUsers = ({ handleResult }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { isFetching, data, refetch} = useSearchUsers(searchTerm);
  const handleSearch = (term) => {
    setSearchTerm(term);
    refetch();
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  return (
    <>
    <SearchForm onSearch={handleSearch} onClear={handleClear} />
      {isFetching && <p>Loading...</p>}
      {data && data.length !== 0 && (
        <div className="searchResults">
          <Table>
            <thead>
              <tr>
                <th>Username</th>
                <th>Role</th>
                <th>omsUserId</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((user) => (
                <tr key={user._id}>
                  <td>{user.username}</td>
                  <td>{user.role}</td>
                  <td>{user.omsUserId}</td>
                  <td><Button
                    size="sm"
                    variant="outline-success"
                    onClick={() => handleResult(user)}
                  >
                    <PlusLg />
                  </Button></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {data && data.length === 0 && (
        <div>
          No users found.
        </div>
      )}
    </>
  );
};

export default SearchUsers;
