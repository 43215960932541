import React from "react";
import { Dropdown, Form } from "react-bootstrap";
// Dropdown list component to sort engagements
const SortEngagementDropdownList = ({ sortField, handleSortFieldChange }) => {
  return (
    <Dropdown.Item as="div">
      <Form.Label>Sort By</Form.Label>
      <Form.Check
        type="radio"
        label="Created At"
        name="sortField"
        value="createdAt"
        checked={sortField === "createdAt"}
        onChange={handleSortFieldChange}
      />
      <Form.Check
        type="radio"
        label="Updated At"
        name="sortField"
        value="updatedAt"
        checked={sortField === "updatedAt"}
        onChange={handleSortFieldChange}
      />
      <Form.Check
        type="radio"
        label="Date Awarded"
        name="sortField"
        value="dateAwarded"
        checked={sortField === "dateAwarded"}
        onChange={handleSortFieldChange}
      />
      <Form.Check
        type="radio"
        label="Start Date"
        name="sortField"
        value="startDate"
        checked={sortField === "startDate"}
        onChange={handleSortFieldChange}
      />
      <Form.Check
        type="radio"
        label="End Date"
        name="sortField"
        value="endDate"
        checked={sortField === "endDate"}
        onChange={handleSortFieldChange}
      />
    </Dropdown.Item>
  );
};
export default SortEngagementDropdownList;
