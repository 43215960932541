import React, { useState } from "react";
import { Modal} from "react-bootstrap";
import useSearchStakeholders from "../../hooks/stakeholders/useSearchStakeholders";
import SearchForm from "../SearchForm";
import StakeholderSummaryTable from "./StakeholderSummaryTable";
//modal component to search stakeholders
const SearchStakeholdersModal = ({ show, handleClose }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const { isFetching, data, refetch} = useSearchStakeholders(searchTerm);
  const handleSearch = (term) => {
    setSearchTerm(term);
    refetch();
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Search Stakeholders</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SearchForm onSearch={handleSearch} onClear={handleClear} />
      {isFetching && <p>Loading...</p>}
      {data && data.length !== 0 && (
        <div className="searchResults">
          <StakeholderSummaryTable data={data} />
        </div>
      )}
      {data && data.length === 0 && (
        <div>
          No results found.
        </div>
      )}
      </Modal.Body>
    </Modal>
  );
};

export default SearchStakeholdersModal;
