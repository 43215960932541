import React, { useState } from "react";
import { Modal} from "react-bootstrap";
import useSearchOrganizations from "../../hooks/organizations/useSearchOrganizations";
import SearchForm from "../SearchForm";
import OrganizationSummaryTable from "./OrganizationSummaryTable";
//modal component to search organizationsx
const SearchOrganizationsModal = ({ show, handleClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { isFetching, data, refetch } = useSearchOrganizations(searchTerm);

  const handleSearch = (term) => {
    setSearchTerm(term);
    refetch();
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Search Organizations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SearchForm onSearch={handleSearch} onClear={handleClear} />
        {isFetching && <p>Loading...</p>}
        {data && data.length !== 0 && (
          <div className="searchResults">
            <OrganizationSummaryTable data={data} />
          </div>
        )}
        {data && data.length === 0 && (
          <div>No organizations found.</div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SearchOrganizationsModal;
