import React from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { PlusCircle, XLg } from 'react-bootstrap-icons';
import SearchConsultants from './SearchConsultants';
import CreateConsultantModal from './CreateConsultantModal';
// Component to manage the array of selected consultants
const ConsultantArraySection = ({ 
    selectedConsultants, 
    setSelectedConsultants, 
    showCreateConsultantModal, 
    setShowCreateConsultantModal 
}) => {
    const handleAddConsultant = (consultant) => {
            setSelectedConsultants([...selectedConsultants, consultant]);
    };

    const handleRemoveConsultant = (index) => {
        setSelectedConsultants(selectedConsultants.filter((_, i) => i !== index));
    };

    return (
        <>
            <Form.Group className="mb-3" controlId="consultant">
                <Form.Label>Participated Consultants</Form.Label>
                <SearchConsultants handleResult={handleAddConsultant} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="createConsultant">
                <Button variant="link" onClick={() => setShowCreateConsultantModal(true)}>
                    <PlusCircle /> Create new consultant
                </Button>
            </Form.Group>
            <Form.Group className="mb-3" controlId="selectedConsultant">
                <Form.Label>Selected Participated Consultants</Form.Label>
                    {selectedConsultants.map((consultant, index) => (
                        <InputGroup className="mb-2" key={index}>
                            <Form.Control
                                type="text"
                                value={consultant?.name || ""}
                                readOnly
                            />
                            <Button variant="outline-danger" onClick={() => handleRemoveConsultant(index)}>
                                <XLg />
                            </Button>
                        </InputGroup>
                    ))}
            </Form.Group>
            <CreateConsultantModal 
                show={showCreateConsultantModal} 
                setShow={setShowCreateConsultantModal} 
                handleResult={handleAddConsultant} 
            />
        </>
    );
};

export default ConsultantArraySection;
