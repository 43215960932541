import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import {  PlusLg } from "react-bootstrap-icons";
import useSearchDiscussions from "../../hooks/discussions/useSearchDiscussions";
import DiscussionStatusBadge from "./DiscussionStatusBadge";
import SearchForm from "../SearchForm";
//table component to display search results of discussions.
const SearchDiscussions = ({ handleResult }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { isFetching, data, refetch} = useSearchDiscussions(searchTerm);
  const handleSearch = (term) => {
    setSearchTerm(term);
    refetch();
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  return (
    <>
     <SearchForm onSearch={handleSearch} onClear={handleClear} />
      {isFetching && <p>Loading...</p>}
      {data && data.length !== 0 && (
        <div className="searchResults">
          <Table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Discussion Date</th>
                <th>Lead</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((discussion) => (
                <tr key={discussion?._id}>
                  <td>{discussion?.title} <DiscussionStatusBadge
                type={discussion?.type}
                status={discussion?.status}
              /></td>
                  <td>{new Date(discussion?.discussion_date).toISOString().split("T")[0]}</td>
                  <td>{discussion?.lead?.username}</td>
                  <Button
                    size="sm"
                    variant="outline-success"
                    onClick={() => handleResult(discussion)}
                  >
                    <PlusLg />
                  </Button>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {data && data.length === 0 && (
        <div>
          No discussions found.
        </div>
      )}
    </>
  );
};

export default SearchDiscussions;
