//packages
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

//components
import Navbar from "./components/EMSNavbar/EMSNavbar"


//pages
import DashboardPage from "./pages/Dashboard/DashboardPage"
import EngagementsPage from "./pages/Engagements/EngagementsPage"
import StakeholdersPage from "./pages/Stakeholders/StakeholdersPage"
import ConsultantsPage from "./pages/Consultants/ConsultantsPage"
import TasksPage from "./pages/Tasks/TasksPage"
import ClientsPage from "./pages/Clients/ClientsPage"
import SettingsPage from './pages/Settings/SettingsPage';
import DiscussionsPage from './pages/Discussions/DiscussionsPage';
import LoginPage from "./pages/Login/LoginPage"
import "./css/ems.css"
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import useAuth from "./hooks/useAuth"
import PersistAuth from './components/PersistAuth';
import EngagementDetailsPage from './pages/Engagements/EngagementDetailsPage';
import TaskDetailsPage from './pages/Tasks/TaskDetailsPage';
import DiscussionDetailsPage from './pages/Discussions/DiscussionDetailsPage';
import ConsultantDetailsPage from './pages/Consultants/ConsultantDetailsPage';
import StakeholderDetailsPage from './pages/Stakeholders/StakeholderDetailsPage';
import OrganizationDetailsPage from './pages/Clients/OrganizationDetailsPage';
import SearchResultsPage from "./pages/SearchResultsPage";
import NotificationsPage from './pages/NotificationPage';

const queryClient = new QueryClient()

const App = () => {

  const { auth } = useAuth();

  return (
    <QueryClientProvider client={queryClient}>

      <div className="App">
        <BrowserRouter>
          {!!auth?.user && <Navbar />}
          <Routes>
            <Route index element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route element={<PersistAuth />}>
              <Route element={<ProtectedRoute isAllowed={!!auth?.user} />}>
                <Route exact path="/dashboard" element={<DashboardPage />} />
                <Route exact path="/engagements" element={<EngagementsPage />} />
                <Route exact path="/engagements/:id" element={<EngagementDetailsPage />} />
                <Route exact path="/stakeholders" element={<StakeholdersPage />} /> 
                <Route exact path="/stakeholders/:id" element={<StakeholderDetailsPage />} />
                <Route exact path="/consultants" element={<ConsultantsPage />} />
                <Route exact path="/consultants/:id" element={<ConsultantDetailsPage />} />
                <Route exact path="/clients" element={<ClientsPage />} />
                <Route exact path="/organizations/:id" element={<OrganizationDetailsPage />} />
                <Route exact path="/notifications" element={<NotificationsPage />} />
                <Route exact path="/tasks" element={<TasksPage />} />
                <Route exact path="/tasks/:id" element={<TaskDetailsPage />} />
                <Route exact path="/discussions" element={<DiscussionsPage />} />
                <Route exact path="/discussions/:id" element={<DiscussionDetailsPage />} />
                <Route exact path="/settings" element={<SettingsPage />} />
                <Route path="/search-results" element={<SearchResultsPage />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </div>

    </QueryClientProvider>
  );
}

export default App;
