import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiCreateConsultant } from "../../api/consultants";
import { toast } from 'react-toastify';
import useAxiosPrivate from "../useAxiosPrivate";

const useCreateConsultant = () => {
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (consultantData) => apiCreateConsultant(axiosPrivate, consultantData),
        onSuccess: (newConsultant) => {
            toast.success("Consultant created successfully!");
            queryClient.setQueryData(["consultants", newConsultant._id], newConsultant);
            queryClient.invalidateQueries(["consultants"]);
        },
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error creating consultant.');
            }
        }
    });
}

export default useCreateConsultant;