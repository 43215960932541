import axios from '../api/axios'

export const apiLogin = async (data, callback, errorCallback) => {
    const api_route = "/auth/login"

    axios.post(
        api_route,
        data, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    ).then((response) => {
        callback(response)
    }).catch((error) => {
        errorCallback(error)
    })
}