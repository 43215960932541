import React, { useState, useEffect, useRef } from "react";
import TaskDetailsTable from "../../components/Tasks/TaskDetailsTable";
import useFetchTaskById from "../../hooks/tasks/useFetchTaskById";
import useFetchDiscussionById from "../../hooks/discussions/useFetchDiscussionById";
import useFetchEngagementById from "../../hooks/engagements/useFetchEngagementById";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import ReactQuill from "react-quill";
import useUpdateTask from "../../hooks/tasks/useUpdateTask";
import EngagementDetailsTable from "../../components/Engagements/EngagementDetailsTable";
import DiscussionDetailsTable from "../../components/Discussions/DiscussionDetailsTable";
import ModificationHistoryTable from "../../components/ModificationHistoryTable";
import "react-quill/dist/quill.snow.css";
import { debounce } from "lodash";

const TaskDetailsPage = () => {
  const { id } = useParams();
  const [notes, setNotes] = useState("");
  const { mutate } = useUpdateTask();

  const {
    isLoading,
    error,
    data: task,
  } = useFetchTaskById(id);

  const relatedEngagementId = task?.related_engagement?._id;
  const {
    isLoading: isLoading1,
    error: error1,
    data: related_engagement,
  } = useFetchEngagementById(relatedEngagementId || '');

  const relatedDiscussionId = task?.related_discussion?._id;
  const {
    isLoading: isLoading2,
    error: error2,
    data: related_discussion,
  } = useFetchDiscussionById(relatedDiscussionId || '');

  useEffect(() => {
    if (task) {
      setNotes(task.notes);
    }
  }, [task]);

  const debouncedMutate = useRef(
    debounce((newNotes) => {
      mutate({ _id: id, notes: newNotes });
    }, 300)
  ).current;

  const handleNoteChange = (value) => {
    setNotes(value);
    debouncedMutate(value);
  };

  if (isLoading || (isLoading1 && relatedEngagementId)|| (isLoading2&&relatedDiscussionId)) return <Loader />;
  if (error) return <p>An error occurred: {error.message}</p>;
  if (error1) return <p>An error occurred: {error1.message}</p>;
  if (error2) return <p>An error occurred: {error2.message}</p>;

  return (
    <Container className="mt-3">
        <Row className="mt-3">
            <Col>
            <h4>Task Details</h4>
            </Col>
        </Row>
      <Row className="mt-3">
        <Col>
          <Tabs
            defaultActiveKey="details"
            id="task-details-tabs"
            className="mb-3"
          >
            <Tab eventKey="details" title="Details">
              <ReactQuill
                value={notes}
                onChange={handleNoteChange}
                theme="snow"
              />
              <TaskDetailsTable task={task} />
            </Tab>
            <Tab eventKey="engagement" title="Related Engagement">
            {related_engagement ? (<EngagementDetailsTable engagement={related_engagement} />
             ) : (
              <p>No related engagement.</p>
            )}
            </Tab>
            <Tab eventKey="discussion" title="Related Discussion">
            {related_discussion ? (<DiscussionDetailsTable discussion={related_discussion} />
             ) : (
              <p>No related discussion.</p>
            )}
            </Tab>
            <Tab eventKey="modification_history" title="Modification History">
              <ModificationHistoryTable element={task} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default TaskDetailsPage;
