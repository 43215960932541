import React from "react";
import { Form } from "react-bootstrap";
// Form component to create/update an organization
const CreateOrganizationForm = ({ errors, register }) => {
  return (
    <>
      <Form.Group className="mb-3" controlId="name">
        <Form.Label>
          Name <span style={{ color: "red" }}>*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Name"
          {...register("organization_name", { required: "Name is required" })}
          isInvalid={!!errors.organization_name}
        />
        <Form.Control.Feedback type="invalid">
          {errors.organization_name && errors.organization_name.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="status">
        <Form.Label>Status</Form.Label>
        <Form.Select aria-label="status" {...register("status")}>
          <option value="Client">Client</option>
          <option value="Organization">Organization</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3" controlId="division">
        <Form.Label>Division</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Division"
          {...register("division")}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="department">
        <Form.Label>Department</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Department"
          {...register("department")}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="acronym">
        <Form.Label>Acronym</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Acronym"
          {...register("acronym")}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="office_locations">
        <Form.Label>Office Locations</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Office Locations"
          {...register("office_locations")}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="service_regions">
        <Form.Label>Service Regions</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Service Regions"
          {...register("service_regions")}
        />
      </Form.Group>
    </>
  );
};

export default CreateOrganizationForm;
