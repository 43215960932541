import React from 'react';
import { Badge } from 'react-bootstrap';
//component to display the status of the consultant in a badge
const ConsultantStatusBadge = ({ status }) => {
  let bg = null;

  switch (status) {
    case 'Pending':
      bg = 'info';
      break;
    case 'Active':
      bg = 'success';
      break;
    case 'Inactive':
        bg = 'dark';
      break;
    default:
      bg = null;
  }

  return (
    <>
      {bg && (<Badge bg={bg} className="me-2">
        {status}
      </Badge>
      )}
    </>
  );
};

export default ConsultantStatusBadge;
