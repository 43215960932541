import React from 'react';
import { Badge } from 'react-bootstrap';
// Component to display the status of the organization in a badge
const OrganizationStatusBadge = ({ status }) => {
  let bg = null;

  switch (status) {
    case 'Organization':
      bg = 'info';
      break;
    case 'Client':
      bg = 'success';
      break;
    default:
      bg = null;
  }

  return (
    <>
      {bg && (<Badge bg={bg} className="me-2">
        {status}
      </Badge>)}
    </>
  );
};

export default OrganizationStatusBadge;
