import React from 'react';
import { Table } from 'react-bootstrap';
import { ArrowRightSquare } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import OrganizationStatusBadge from './OrganizationStatusBadge';
//table component to display organizations summary
const OrganizationSummaryTable = ({ data }) => {
    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                  <th>Name</th>
                  <th>Division</th>
                  <th>Department</th>
                  <th>Acronym</th>
                  <th>Actions</th>
                </tr>
            </thead>
            <tbody>
            {data?.map((organization) => (
                  <tr key={organization._id}>
                    <td>{organization.organization_name} <OrganizationStatusBadge status={organization?.status} /></td>
                    <td>{organization.division}</td>
                    <td>{organization.department}</td>
                    <td>{organization.acronym}</td>
                    <td>
                      <Link
                        to={`/organizations/${organization._id}`}
                        className="btn btn-outline-secondary me-2"
                        target="_blank"
                      >
                        <ArrowRightSquare />
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default OrganizationSummaryTable;
