//get engagements by filters
export const apiGetEngagements = async (axiosPrivate, filters) => {
    const api_route = "/engagements"
    const urlParams = new URLSearchParams(filters).toString();
    const fullUrl = `${api_route}?${urlParams}`;
    
    console.log(`Full API Route: ${fullUrl}`);
    const response = await axiosPrivate.get(api_route, { params: filters })

    const data = response.data
    
    if(data.success)
        return data.data.engagements

    
    return []   
}

//get engagements by consultant id
export const apiGetEngagementsByConsultant = async (axiosPrivate, consultant_id) => {
    const api_route = `/engagements?consultant=${consultant_id}`;
    console.log(`Full API Route: ${api_route}`);

    const response = await axiosPrivate.get(api_route);

    const data = response.data
    
    if(data.success)
        return data.data.engagements

    
    return []   
}

//get engagements by client id  
export const apiGetEngagementsByClient = async (axiosPrivate, client_id) => {
    const api_route = `/engagements?client=${client_id}`;
    console.log(`Full API Route: ${api_route}`);

    const response = await axiosPrivate.get(api_route);

    const data = response.data
    
    if(data.success)
        return data.data.engagements

    
    return []   
}

//get engagements by account manager id
export const apiGetEngagementsByAccountManager = async (axiosPrivate, user_id) => {
    const api_route = `/engagements?account_manager=${user_id}`;
    console.log(`Full API Route: ${api_route}`);

    const response = await axiosPrivate.get(api_route);

    const data = response.data
    
    if(data.success)
        return data.data.engagements

    
    return []   
}

//get engagements by contact id 
export const apiGetEngagementsByContact = async (axiosPrivate, contact_id) => {
    const api_route = `/engagements?contact=${contact_id}`;
    console.log(`Full API Route: ${api_route}`);

    const response = await axiosPrivate.get(api_route);

    const data = response.data
    
    if(data.success)
        return data.data.engagements

    
    return []   
}

//get engagements by stakeholder id 
export const apiGetEngagementsByStakeholder = async (axiosPrivate, stakeholder_id) => {
    const api_route = `/engagements?stakeholder=${stakeholder_id}`;
    console.log(`Full API Route: ${api_route}`);

    const response = await axiosPrivate.get(api_route);

    const data = response.data
    
    if(data.success)
        return data.data.engagements

    
    return []   
}

//get engagements by id
export const apiGetEngagementByID = async (axiosPrivate, ID) => {
    const api_route = `/engagements/${ID}`;
    const response = await axiosPrivate.get(api_route)

    const data = response.data
    if(data.success)
        return data.data.engagement
    return []   
}

//create new engagement
export const apiCreateEngagement = async (axiosPrivate, engagementData) => {
    console.log("apiCreateEngagement", engagementData)
    const api_route = "/engagements"

    const response = await axiosPrivate.post(api_route, engagementData)

    const data = response.data
    console.log(data)

    if(data.success)
        return data.engagement

    return null
}

//update engagement by id
export const apiUpdateEngagement = async (axiosPrivate, ID, engagementData) => {
    console.log("apiUpdateEngagement", engagementData)
    const api_route = `/engagements/${ID}`;

    const response = await axiosPrivate.patch(api_route, engagementData)

    const data = response.data
    console.log(data)

    if(data.success)
        return data.data.engagement

    return null
}

//search engagements by search term 
export const apiSearchEngagements = async (axiosPrivate, searchTerm) => {
    const api_route = "/engagements/search?searchTerm=" + searchTerm;
    try {
        const response = await axiosPrivate.get(api_route);
        const data = response.data;

        if (data && data.success) {
            return data.data.engagements;
        } 
    } catch (error) {
        console.error("Error fetching engagements:", error);
        return [];
    }
};