import React, { useState, useEffect, useRef } from "react";
import OrganizationDetailsTable from "../../components/Organizations/OrganizationDetailsTable";
import useFetchOrganizationById from "../../hooks/organizations/useFetchOrganizationById";
import useFetchStakeholdersByOrganization from "../../hooks/stakeholders/useFetchStakeholdersByOrganization";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import ReactQuill from "react-quill";
import useUpdateOrganization from "../../hooks/organizations/useUpdateOrganization";
import StakeholderSummaryTable from "../../components/Stakeholders/StakeholderSummaryTable";
import EngagementSummaryTable from "../../components/Engagements/EngagementSummaryTable";
import useFetchEngagementsByClient from "../../hooks/engagements/useFetchEngagementsByClient";
import "react-quill/dist/quill.snow.css";
import { debounce } from "lodash";

const OrganizationDetailsPage = () => {
  const { id } = useParams();
  const [notes, setNotes] = useState("");
  const { mutate } = useUpdateOrganization();

  const {
    isLoading,
     error,
    data: organization,
  } = useFetchOrganizationById(id);
  const {
    isLoading: isLoading1,
    error: error1,
    data: stakeholders,
  } = useFetchStakeholdersByOrganization(id);
  const {
    isLoading2,
    error2,
    data: related_engagements,
  } = useFetchEngagementsByClient(id);

  useEffect(() => {
    if (organization) {
      setNotes(organization.notes);
    }
  }, [organization]);

  const debouncedMutate = useRef(
    debounce((newNotes) => {
      mutate({ _id: id, notes: newNotes });
    }, 300)
  ).current;

  const handleNoteChange = (value) => {
    setNotes(value);
    debouncedMutate(value);
  };

  if (isLoading|| isLoading1 || isLoading2) return <Loader />;
  if (error) return <p>An error occurred: {error.message}</p>;
  if (error1) return <p>An error occurred: {error1.message}</p>;
    if (error2) return <p>An error occurred: {error2.message}</p>;

  return (
    <Container className="mt-3">
        <Row className="mt-3">
            <Col>
            <h4>Organization Details</h4>
            </Col>
        </Row>
      <Row className="mt-3">
        <Col>
          <Tabs
            defaultActiveKey="details"
            id="organization-details-tabs"
            className="mb-3"
          >
            <Tab eventKey="details" title="Details">
              <ReactQuill
                value={notes}
                onChange={handleNoteChange}
                theme="snow"
              />
              <OrganizationDetailsTable organization={organization} />
            </Tab>
            <Tab eventKey="engagement" title="Related Engagement">
              <EngagementSummaryTable data={related_engagements} />
            </Tab>
            <Tab eventKey="stakeholder" title="Stakeholders">
            <StakeholderSummaryTable data={stakeholders} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationDetailsPage;
