import React, { useState } from "react";
import { Modal} from "react-bootstrap";
import useSearchEngagements from "../../hooks/engagements/useSearchEngagements";
import SearchForm from "../SearchForm";
import EngagementSummaryTable
 from "./EngagementSummaryTable";
 //modal component to search engagements
const SearchEngagementsModal = ({ show, handleClose }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const { isFetching, data, refetch} = useSearchEngagements(searchTerm);
  const handleSearch = (term) => {
    setSearchTerm(term);
    refetch();
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Search Engagements</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SearchForm onSearch={handleSearch} onClear={handleClear} />
      {isFetching && <p>Loading...</p>}
      {data && data.length !== 0 && (
        <div className="searchResults">
          <EngagementSummaryTable data={data} />
        </div>
      )}
      {data && data.length === 0 && (
        <div>
          No engagements found.
        </div>
      )}
     </Modal.Body>
    </Modal>
  );
};

export default SearchEngagementsModal;
