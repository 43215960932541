
const PageHeader = (props) => {
    const {title} = props
    return (
        <>
        <h5>{title}</h5>
        </>
    )
}

export default PageHeader;