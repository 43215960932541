import { useQuery } from "@tanstack/react-query";
import { apiGetUserNotifications } from "../../api/users";
import useAxiosPrivate from "../useAxiosPrivate";
import { toast } from 'react-toastify';

const useFetchUsersNotifications = (userId, params) => {
    const axiosPrivate = useAxiosPrivate();

    return useQuery({
        queryKey: ['userNotififcations', userId, params],
        queryFn: async () => apiGetUserNotifications(axiosPrivate, userId, params),
        refetchInterval: 5000,
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error fetching user notifications');
            }
        }
    });
};

export default useFetchUsersNotifications;
