import React from "react";
import { Table } from "react-bootstrap";
// Table component to display modification history
const ModificationHistoryTable = ({ element }) => {
  if (!element || !element.modification_history) return null;

  return (
        <Table bordered>
          <thead>
            <tr>
              <th>Date</th>
              <th>Modified By</th>
              <th>Changes</th>
            </tr>
          </thead>
          <tbody>
          {[...element.modification_history].reverse().map((entry, index) => (
              <tr key={index}>
                <td>{new Date(entry.modifiedAt).toLocaleString()}</td>
                <td>{entry.modifiedBy?.username || "Unknown"}</td>
                <td>
                  {Object.entries(entry.changes).map(([field, value], i) => (
                    <div key={i}>
                      <strong>{field}:</strong> {JSON.stringify(value)}
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
  );
};

export default ModificationHistoryTable;
