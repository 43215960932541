import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { PlusLg } from "react-bootstrap-icons";
import useSearchOrganizations from "../../hooks/organizations/useSearchOrganizations";
import OrganizationStatusBadge from "./OrganizationStatusBadge";
import SearchForm from "../SearchForm"; // Import the SearchForm component
//table component to display search results for organizations
const SearchOrganizations = ({ handleResult }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { isFetching, data, refetch } = useSearchOrganizations(searchTerm);

  const handleSearch = (term) => {
    setSearchTerm(term);
    refetch();
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  return (
    <>
      <SearchForm onSearch={handleSearch} onClear={handleClear} />
      {isFetching && <p>Loading...</p>}
      {data && data.length !== 0 && (
        <div className="searchResults">
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Division</th>
                <th>Department</th>
                <th>Acronym</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((organization) => (
                <tr key={organization._id}>
                  <td>{organization.organization_name} <OrganizationStatusBadge status={organization?.status} /></td>
                  <td>{organization.division}</td>
                  <td>{organization.department}</td>
                  <td>{organization.acronym}</td>
                  <td>
                    <Button
                      size="sm"
                      variant="outline-success"
                      onClick={() => handleResult(organization)}
                    >
                      <PlusLg />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {data && data.length === 0 && (
        <div>
          No organizations found.
        </div>
      )}
    </>
  );
};

export default SearchOrganizations;
