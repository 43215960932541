import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import {  PlusLg } from "react-bootstrap-icons";
import useSearchEngagements from "../../hooks/engagements/useSearchEngagements";
import EngagememtStatusBadge from "./EngagementStatusBadge";
import SearchForm from "../SearchForm";
//component to display search results for engagements
const SearchEngagements = ({ handleResult }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const { isFetching, data, refetch} = useSearchEngagements(searchTerm);
  const handleSearch = (term) => {
    setSearchTerm(term);
    refetch();
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  return (
    <>
     <SearchForm onSearch={handleSearch} onClear={handleClear} />
      {isFetching && <p>Loading...</p>}
      {data && data.length !== 0 && (
        <div className="searchResults">
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Consultant</th>
                <th>Account Manager</th>
                <th>Client</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((engagement) => (
                <tr key={engagement?._id}>
                  <td>{engagement?.displayName} <EngagememtStatusBadge state={engagement?.state} is_extended={engagement?.is_extended} /> </td>
                  <td>{engagement?.consultant?.name}</td>
                  <td>{engagement?.account_manager?.username}</td>
                  <td>{engagement?.client?.organization_name}</td>
                  <Button
                    size="sm"
                    variant="outline-success"
                    onClick={() => handleResult(engagement)}
                  >
                    <PlusLg />
                  </Button>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {data && data.length === 0 && (
        <div>
          No engagements found.
        </div>
      )}
    </>
  );
};

export default SearchEngagements;
