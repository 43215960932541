import { useQuery } from "@tanstack/react-query";
import { apiGetEngagementsByContact } from "../../api/engagements";
import useAxiosPrivate from "../useAxiosPrivate";
import { toast } from 'react-toastify';

const useFetchEngagementsByContact = (contact_id) => {
    const axiosPrivate = useAxiosPrivate();
    return useQuery({
        queryKey: ['engagements', 'contact', contact_id],
        queryFn: async () => apiGetEngagementsByContact(axiosPrivate, contact_id),
        enabled: !!contact_id,
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error fetching engagements');
            }
        }
    });
};

export default useFetchEngagementsByContact;
