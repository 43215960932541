import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import {  PlusLg } from "react-bootstrap-icons";
import useSearchConsultants from "../../hooks/consultants/useSearchConsultants";
import ConsultantStatusBadge from "./ConsultantStatusBadge";
import SearchForm from "../SearchForm";
//form component to show the search results of consultants
const SearchConsultants = ({ handleResult }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const { isFetching, data, refetch} = useSearchConsultants(searchTerm);
  
  const handleSearch = (term) => {
    setSearchTerm(term);
    refetch();
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  return (
    <>
      <SearchForm onSearch={handleSearch} onClear={handleClear} />
      {isFetching && <p>Loading...</p>}
      {data && data.length !== 0 && (
        <div className="searchResults">
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((consultant) => (
                <tr key={consultant?._id}>
                  <td>{consultant?.name} <ConsultantStatusBadge status={consultant?.status} /></td>
                  <td>{consultant?.email}</td>
                  <td>{consultant?.phone_number}</td>
                  <Button
                    size="sm"
                    variant="outline-success"
                    onClick={() => handleResult(consultant)}
                  >
                    <PlusLg />
                  </Button>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {data && data.length === 0 && (
        <div>
          No consultants found.
        </div>
      )}
    </>
  );
};

export default SearchConsultants;
