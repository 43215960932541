// Import necessary modules and components from React and React Bootstrap
import React, { useState, useEffect } from 'react';
import { Dropdown, Badge, Button } from "react-bootstrap";
import { Bell } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import useUpdateUserNotification from "../../hooks/users/useUpdateUserNotification";
import notificationSound from '../../assets/walmer_alert_1.mp3';

// NotificationDropdown component to handle user notifications
const NotificationDropdown = ({ notifications, auth }) => {
  const navigate = useNavigate();
  const { mutate: updateNotification } = useUpdateUserNotification();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Filter notifications to find unseen and unnotified notifications
  const unseenNotifications = notifications?.filter(notification => !notification.seen) || [];
  const unnotifiedNotifications = notifications?.filter(notification => !notification.notified) || [];

  // Handle viewing a notification
  const handleViewNotification = (notification) => {
    navigate(notification.link);
  };

  // Play a sound and update notifications as notified when new notifications arrive
  useEffect(() => {
    if (unnotifiedNotifications.length > 0) {
      const audio = new Audio(notificationSound);
      audio.play();
      unnotifiedNotifications.forEach(notification => {
        updateNotification({ _id: notification._id, user: auth?.user?.id, notified: true });
      });
    }
  }, [unnotifiedNotifications.length, auth?.user?.id]);

  // Mark notifications as seen when the dropdown is opened
  useEffect(() => {
    if (isDropdownOpen) {
      unseenNotifications.forEach(notification => {
        updateNotification({ _id: notification._id, user: auth?.user?.id, seen: true });
      });
    }
  }, [isDropdownOpen, auth?.user?.id]);

  // Navigate to the notifications page
  const toggleShowAll = (e) => {
    e.preventDefault();
    navigate("/notifications");
  };

  // Toggle the dropdown open and closed
  const handleDropdownClick = () => {
    (isDropdownOpen)? setIsDropdownOpen(false): setIsDropdownOpen(true);
  };

  return (
    <Dropdown className="notification-dropdown" onToggle={handleDropdownClick}>
      <Dropdown.Toggle as={Button} variant="link" id="notification-dropdown">
        <Bell size={16}/>
        {unseenNotifications.length > 0 && (
          <Badge pill bg="danger" className="notification-badge">
            {unseenNotifications.length}
          </Badge>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {/* Display the first 5 notifications */}
        {(notifications.slice(0, 5)).map(notification => (
          <Dropdown.Item
            key={notification._id}
            onClick={() => handleViewNotification(notification)}
            style={{ fontWeight: notification.seen ? 'normal' : 'bold' }}
          >
            {notification.message}
          </Dropdown.Item>
        ))}
        <Dropdown.Item onClick={toggleShowAll}>
          View all
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
