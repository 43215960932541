import { Modal, Button } from "react-bootstrap";
import React from "react";
import StakeholderDetailsTable from "./StakeholderDetailsTable";
import "../../css/DetailsModal.css";
//modal to display the details of a stakeholder
const StakeholderDetailsModal = ({ show, handleClose, stakeholder }) => {
  if (!stakeholder) return null;
  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Stakeholder Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StakeholderDetailsTable
            stakeholder={stakeholder}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StakeholderDetailsModal;
