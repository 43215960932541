import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import DiscussionStatusBadge from "./DiscussionStatusBadge";
import UpdateDiscussionModal from "./UpdateDiscussionModal";
import { PencilSquare, ArrowRightSquare } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
//component to display discussion details in a table
const DiscussionDetailsTable = ({ discussion }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const handleUpdateDiscussion = () => {
    setShowEditModal(true);
  };
  const handleCloseUpdateModal = () => {
    setShowEditModal(false);
  };
  return (
    <>
      <Table bordered>
        <tbody>
          <tr>
            <th></th>
            <td>
              <Button
                variant="outline-secondary"
                className="me-2"
                onClick={() => handleUpdateDiscussion()}
              >
                <PencilSquare />
              </Button>
              <Link
                to={`/discussions/${discussion?._id}`}
                className="btn btn-outline-secondary me-2"
                target="_blank"
              >
                <ArrowRightSquare />
              </Link>
            </td>
          </tr>
          <tr>
            <th>Title</th>
            <td>
              {discussion?.title}{" "}
              <DiscussionStatusBadge
                type={discussion?.type}
                status={discussion?.status}
              />
            </td>
          </tr>
          <tr>
            <th>Creator</th>
            <td>{discussion?.created_by?.username}</td>
          </tr>
          <tr>
            <th>Discussion Date</th>
            <td>
              {discussion?.discussion_date
                ? new Date(discussion?.discussion_date)
                    .toISOString()
                    .split("T")[0]
                : ""}
            </td>
          </tr>
          <tr>
            <th>Lead</th>
            <td>{discussion?.lead?.username}</td>
          </tr>
          <tr>
            <th>Length</th>
            <td>{discussion?.length}</td>
          </tr>
          <tr>
            <th>Participated Users</th>
            <td>
              {discussion?.participated_users &&
              discussion?.participated_users.length > 0
                ? discussion?.participated_users
                    .map((user) => user.username)
                    .join(", ")
                : ""}
            </td>
          </tr>
          <tr>
            <th>Participated Consultants</th>
            <td>
              {discussion?.participated_consultants &&
              discussion?.participated_consultants.length > 0
                ? discussion?.participated_consultants
                    .map((consultant) => consultant.name)
                    .join(", ")
                : ""}
            </td>
          </tr>
          <tr>
            <th>Participated Stakeholders</th>
            <td>
              {discussion?.participated_stakeholders &&
              discussion?.participated_stakeholders.length > 0
                ? discussion?.participated_stakeholders
                    .map((stakeholder) => stakeholder.name)
                    .join(", ")
                : ""}
            </td>
          </tr>
          <tr>
            <th>Notes</th>
            <td dangerouslySetInnerHTML={{ __html: discussion?.notes }}></td>
          </tr>
        </tbody>
      </Table>
      <UpdateDiscussionModal
        show={showEditModal}
        handleClose={handleCloseUpdateModal}
        discussion={discussion}
      />
    </>
  );
};

export default DiscussionDetailsTable;
