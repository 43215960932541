import React from 'react';
import { Badge } from 'react-bootstrap';
//component to display the status and the role of the stakeholder in a badge
const StakeholderStatusBadge = ({ status, role }) => {
  let bg = null;

  switch (role) {
    case 'Contact':
      bg = 'info';
      break;
    case 'Other':
      bg = 'success';
      break;
    default:
      bg = null;
  }
  if (status === 'Deleted') {
    bg = null;
  }
  return (
    <>
      {bg && (<Badge bg={bg} className="me-2">
        {role}
      </Badge>
      )}
    </>
  );
};

export default StakeholderStatusBadge;
