import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { PencilSquare, ArrowRightSquare } from "react-bootstrap-icons";
import ConsultantStatusBadge from "./ConsultantStatusBadge";
import UpdateConsultantModal from "./UpdateConsultantModal";
import { Link } from "react-router-dom";
//component to display consultant details in a table
const ConsultantDetailsTable = ({
  consultant,
}) => {
  const consultantArray = Array.isArray(consultant) ? consultant : [consultant];
  const [showEditConsultantModal, setShowEditConsultantModal] = useState(false);
  const [selectedConsultant, setSelectedConsultant] = useState(null);
  const handleUpdateConsultant = (consultant) => {
    setSelectedConsultant(consultant);
    setShowEditConsultantModal(true);
  };

  const handleCloseUpdateConsultant = () => {
    setShowEditConsultantModal(false);
    setSelectedConsultant(null);
  };
  return (
    <>
      <Table bordered>
        <tbody>
          {consultantArray.map((consultant, index) => (
            <React.Fragment key={index}>
              <tr>
                <th></th>
                <td>
                  <Button
                    variant="outline-secondary"
                    className="me-2"
                    onClick={() => handleUpdateConsultant(consultant)}
                  >
                    <PencilSquare />
                  </Button>
                  <Link
                to={`/consultants/${consultant?._id}`}
                className="btn btn-outline-secondary me-2"
                target="_blank"
              >
                <ArrowRightSquare />
              </Link>
                </td>
              </tr>
              <tr>
                <th>Name</th>
                <td>
                  {consultant?.name}
                  <ConsultantStatusBadge status={consultant?.status} />
                </td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{consultant?.email}</td>
              </tr>
              <tr>
                <th>Phone Number</th>
                <td>{consultant?.phone_number}</td>
              </tr>
              <tr>
                <th>Company Name</th>
                <td>{consultant?.company_name}</td>
              </tr>
              <tr>
                <th>Notes</th>
                <td dangerouslySetInnerHTML={{ __html: consultant?.notes }}></td>
              </tr>
              <tr>
                <th>OMS_ID</th>
                <td>{consultant?.oms_id}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      <UpdateConsultantModal
        show={showEditConsultantModal}
        handleClose={handleCloseUpdateConsultant}
        consultant={selectedConsultant}
      />
    </>
  );
};

export default ConsultantDetailsTable;
