import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { PlusLg } from "react-bootstrap-icons";
import useSearchStakeholders from "../../hooks/stakeholders/useSearchStakeholders";
import StakeholderStatusBadge from "./StakeholderStatusBadge";
import SearchForm from "../SearchForm";
//component to display the search results of stakeholders
const SearchStakeholders = ({ handleResult, role }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const { isFetching, data, refetch} = useSearchStakeholders(searchTerm);
  const filtered_data = data?.filter(stakeholder => !role || stakeholder.role === role);
  const handleSearch = (term) => {
    setSearchTerm(term);
    refetch();
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  return (
    <>
      <SearchForm onSearch={handleSearch} onClear={handleClear} />
      {isFetching && <p>Loading...</p>}
      {filtered_data && filtered_data.length !== 0 && (
        <div className="searchResults">
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Position</th>
                <th>Organization</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filtered_data?.map((stakeholder) => (
                <tr key={stakeholder._id}>
                  <td>{stakeholder.name} <StakeholderStatusBadge status={stakeholder.status} role={stakeholder.role}/></td>
                  <td>{stakeholder.phone_number}</td>
                  <td>{stakeholder.email}</td>
                  <td>{stakeholder.position}</td>
                  <td>{stakeholder.organization?.organization_name}</td>
                  <Button
                    size="sm"
                    variant="outline-success"
                    onClick={() => handleResult(stakeholder)}
                  >
                    <PlusLg />
                  </Button>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {filtered_data && filtered_data.length === 0 && (
        <div>
          No results found.
        </div>
      )}
    </>
  );
};

export default SearchStakeholders;
