import axios from '../api/axios';
import useAuth from './useAuth';
import useLogout from './useLogout';

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const logout = useLogout()

    const refresh = async () => {
        let refreshError = false

        const response = await axios.get('/auth/refresh', {
            withCredentials: true,
            
        }).catch((error)=>{refreshError = true})
        
        if(refreshError){
            logout()
            return 
        }
        // localStorage.setItem('auth', response.data.data.id);
        setAuth(prev => {
            return { ...prev, user: {username: response.data.data.username, id: response.data.data.id}, accessToken: response.data.data.accessToken }
        });
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;