import { Outlet } from "react-router-dom"
import { useState, useEffect, useRef } from "react"
import useRefreshToken from "../hooks/useRefreshToken"
import useAuth from "../hooks/useAuth"
import Loader from "./Loader/Loader"

const PersistAuth = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const attemptedRefresh = useRef(false)
    const { auth } = useAuth();

    useEffect(()=>{
        //return so not run twice
        if(attemptedRefresh.current){
            return
        }

        attemptedRefresh.current = true

        const verifyRefreshToken = async() => {
            try{
                await refresh();
            }
            catch (error){
                console.log(error)
            }
            finally{
                setIsLoading(false)
            }
        }

        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
    }, [])

    return (
        <>
        {
            isLoading ? <Loader/>
            : <Outlet />
        }
        </>
    )
}

export default PersistAuth