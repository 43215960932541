import React, { useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Search, XLg } from "react-bootstrap-icons";
//search bar with a search and clear button
const SearchForm = ({ onSearch, onClear }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const clearSearch = () => {
    setSearchTerm("");
    onClear();
  };

  return (
    <InputGroup className="mb-3">
      <Form.Control
        placeholder="Search..."
        aria-label="Search"
        aria-describedby="basic-addon2"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ maxWidth: "100%" }}
      />
      <Button
        variant="outline-secondary iconButton"
        id="button-addon2"
        onClick={() => onSearch(searchTerm)} 
      >
        <Search />
      </Button>
      <Button
        variant="outline-secondary iconButton"
        id="button-addon2"
        onClick={clearSearch}
      >
        <XLg />
      </Button>
    </InputGroup>
  );
};

export default SearchForm;
