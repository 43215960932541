import { useQuery } from "@tanstack/react-query";
import { apiGetConsultants } from "../../api/consultants"
import useAxiosPrivate from "../useAxiosPrivate";
import { toast } from 'react-toastify';

const useFetchConsultants = (filters) => {
    const axiosPrivate = useAxiosPrivate();

    return useQuery({
        queryKey: ['consultants', filters],
        enabled: !!filters,
        queryFn: async () => apiGetConsultants(axiosPrivate, filters),
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error fetching consultants');
            }
        }
    });
};

export default useFetchConsultants;