import { Modal, Button } from "react-bootstrap";
import React from "react";
import TaskDetailsTable from "./TaskDetailsTable";
import DiscussionDetailsTable from "../Discussions/DiscussionDetailsTable";
import EngagementDetailsTable from "../Engagements/EngagementDetailsTable";
import useFetchEngagementById from "../../hooks/engagements/useFetchEngagementById";
import useFetchDiscussionById from "../../hooks/discussions/useFetchDiscussionById";
import Loader from "../Loader/Loader";
import "../../css/DetailsModal.css";
//modal to display the details of a task
const TaskDetailsModal = ({ show, handleClose, task }) => {
  const relatedEngagementId = task?.related_engagement?._id;
  const {
    isLoading,
    error,
    data: related_engagement,
  } = useFetchEngagementById(relatedEngagementId || '');
  const relatedDiscussionId = task?.related_discussion?._id;
  const {
    isLoading: isLoading1,
    error: error1,
    data: related_discussion,
  } = useFetchDiscussionById(relatedDiscussionId || '');

  if (!task) return null;

  if ((isLoading && relatedEngagementId) || (isLoading1&&relatedDiscussionId)) return <Loader />;
  if (error) return <p>An error occurred: {error.message}</p>;
  if (error1) return <p>An error occurred: {error1.message}</p>;
  
  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Task Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TaskDetailsTable task={task} />
          {related_discussion && <h5><b>Related Discussion</b></h5>}
          {related_discussion && <DiscussionDetailsTable discussion={related_discussion} />}
          {related_engagement && <h5><b>Related Engagement</b></h5>}
          {related_engagement && <EngagementDetailsTable engagement={related_engagement} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TaskDetailsModal;
