import PageHeader from "../../components/PageHeader";
import { Container, Tabs, Tab, Row, Col, Button } from "react-bootstrap";
import Loader from "../../components/Loader/Loader";
import UpdateDiscussionModal from "../../components/Discussions/UpdateDiscussionModal";
import DiscussionDetailsModal from "../../components/Discussions/DiscussionDetailsModal";
import React, { useState, useContext } from "react";
import DiscussionsTable from "../../components/Discussions/DiscussionsTable";
import useFetchDiscussions from "../../hooks/discussions/useFetchDiscussions";
import useUpdateDiscussion from "../../hooks/discussions/useUpdateDiscussion";
import AuthContext from "../../context/AuthProvider";
import DiscussionFilters from "../../components/Discussions/DiscussionFilters";
import { Search } from "react-bootstrap-icons";
import SearchDiscussionsModal from "../../components/Discussions/SearchDiscussionsModal"; 

const DiscussionsPage = () => {
    const pageTitle = "Discussions";
    const [activeTab, setActiveTab] = useState("all");
    const [showEditModal, setShowEditModal] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [selectedDiscussion, setSelectedDiscussion] = useState(null);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [filters, setFilters] = useState({
        type: false,
        createdByMe: false,
        leadByMe: false,
        participatedByMe: false
    });
    const [sortField, setSortField] = useState("createdAt");
    const [sortOrder, setSortOrder] = useState("desc");

    const { auth } = useContext(AuthContext);
    const { isLoading, error, data } = useFetchDiscussions(convertFiltersToParams(filters, activeTab, auth, sortField, sortOrder));
    const filteredData = data?.filter(discussion => discussion.status !== "Deleted");  
    const handleShowDetails = (discussion) => {
        setSelectedDiscussion(discussion);
        setShowDetailsModal(true);
    };

    const handleCloseDetailsModal = () => {
        setShowDetailsModal(false);
        setSelectedDiscussion(null);
    };

    const handleUpdateDiscussion = (discussion) => {
        setSelectedDiscussion(discussion);
        setShowEditModal(true);
    };

    const handleCloseUpdateModal = () => {
        setShowEditModal(false);
        setSelectedDiscussion(null);
    };

    const { mutate } = useUpdateDiscussion();

    const handleArchive = (discussion) => {
        mutate({ _id: discussion._id, status: "Deleted" });
    };

    const handleResumeArchive = (discussion) => {
        mutate({ _id: discussion._id, status: "Completed" });
    };

    const handleFilterChange = (e) => {
        const { name, checked, value, type } = e.target;
        setFilters({
            ...filters,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleSortFieldChange = (e) => {
        setSortField(e.target.value);
    };

    const handleSortOrderChange = (e) => {
        setSortOrder(e.target.value);
    };

    if (isLoading) return <Loader />;

    if (error) return "An error occurred: " + error.message;

    return (
        <>
            <Container className="page-container">
                <PageHeader title={pageTitle} className="ems-page-title"></PageHeader>
                <Button variant="outline-secondary iconButton" onClick={() => setShowSearchModal(true)}>
          <Search /> Search
        </Button>
                <Row className="mb-3">
                    <Col className="d-flex justify-content-end">
                        <DiscussionFilters
                            filters={filters}
                            setFilters={setFilters}
                            handleFilterChange={handleFilterChange}
                            handleSortFieldChange={handleSortFieldChange}
                            handleSortOrderChange={handleSortOrderChange}
                        />
                    </Col>
                </Row>
                <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                    <Tab eventKey="all" title="All">
                        <DiscussionsTable
                            data={filteredData}
                            handleShowDetails={handleShowDetails}
                            handleArchive={handleArchive}
                            handleResumeArchive={handleResumeArchive}
                            handleUpdateDiscussion={handleUpdateDiscussion}
                        />
                    </Tab>
                    <Tab eventKey="Meeting" title="Meeting">
                        <DiscussionsTable
                            data={data}
                            handleShowDetails={handleShowDetails}
                            handleArchive={handleArchive}
                            handleResumeArchive={handleResumeArchive}
                            handleUpdateDiscussion={handleUpdateDiscussion}
                        />
                    </Tab>
                    <Tab eventKey="Phone Call" title="Phone Call">
                        <DiscussionsTable
                            data={data}
                            handleShowDetails={handleShowDetails}
                            handleArchive={handleArchive}
                            handleResumeArchive={handleResumeArchive}
                            handleUpdateDiscussion={handleUpdateDiscussion}
                        />
                    </Tab>
                    <Tab eventKey="Email" title="Email">
                        <DiscussionsTable
                            data={data}
                            handleShowDetails={handleShowDetails}
                            handleArchive={handleArchive}
                            handleResumeArchive={handleResumeArchive}
                            handleUpdateDiscussion={handleUpdateDiscussion}
                        />
                    </Tab>
                    <Tab eventKey="Deleted" title="Deleted">
                        <DiscussionsTable
                            data={data}
                            handleShowDetails={handleShowDetails}
                            handleArchive={handleArchive}
                            handleResumeArchive={handleResumeArchive}
                            handleUpdateDiscussion={handleUpdateDiscussion}           
                        />
                    </Tab>
                </Tabs>
                <DiscussionDetailsModal
                    show={showDetailsModal}
                    handleClose={handleCloseDetailsModal}
                    discussion={selectedDiscussion}
                />
                <UpdateDiscussionModal
                    show={showEditModal}
                    handleClose={handleCloseUpdateModal}
                    discussion={selectedDiscussion}
                />
                 <SearchDiscussionsModal
          show={showSearchModal}
          handleClose={() => setShowSearchModal(false)}
        />
            </Container>
        </>
    );
};

const convertFiltersToParams = (filters, type, auth, sortField, sortOrder) => {
    let params = {};
    if (type && type === "Deleted") {
        params["status"] = "Deleted";
        return params;
    }
    if (type && type !== "all") params["type"] = type;
    if (filters.createdByMe) params["created_by"] = auth?.user?.id;
    if (filters.leadByMe) params["lead"] = auth?.user?.id;
    // if (filters.participatedByMe) params["participatedByMe"] = auth?.user?.id;
    params["sortBy"] = sortField;
    params["sortOrder"] = sortOrder;

    return params;
};

export default DiscussionsPage;
