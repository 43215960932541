import React from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Search, XLg } from "react-bootstrap-icons";
import SearchOppsModal from "../SearchOppModal/SearchOppsModal";
import ConsultantSection from "../Consultants/ConsultantSection";
import ClientSection from "../Organizations/ClientSection";
import AccountManagerSection from "../Users/AccountManagerSection";
import StakeholdersSection from "../Stakeholders/StakeholdersSection";
//form component to create/update an engagement
const CreateEngagementForm = ({
  errors,
  register,
  selectedConsultant,
  setSelectedConsultant,
  selectedOrganization,
  setSelectedOrganization,
  selectedUser,
  setSelectedUser,
  selectedOpp,
  setSelectedOpp,
  showSearchOppModal,
  setShowSearchOppModal,
  showCreateConsultantModal,
  setShowCreateConsultantModal,
  showCreateOrganizationModal,
  setShowCreateOrganizationModal,
  contacts,
  setContacts,
  showCreateContactModal,
  setShowCreateContactModal,
  stakeholders,
  setStakeholders,
  showCreateStakeholderModal,
  setShowCreateStakeholderModal,
}) => {
  return (
    <>
      <Form.Group className="mb-3" controlId="displayName">
        <Form.Label>
          Display Name <span style={{ color: "red" }}>*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Display Name"
          {...register("displayName", { required: "Display Name is required" })}
          isInvalid={!!errors.displayName}
        />
        <Form.Control.Feedback type="invalid">
          {errors.displayName?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <ConsultantSection
        selectedConsultant={selectedConsultant}
        setSelectedConsultant={setSelectedConsultant}
        showCreateConsultantModal={showCreateConsultantModal}
        setShowCreateConsultantModal={setShowCreateConsultantModal}
      />
      <ClientSection
        selectedOrganization={selectedOrganization}
        setSelectedOrganization={setSelectedOrganization}
        showCreateOrganizationModal={showCreateOrganizationModal}
        setShowCreateOrganizationModal={setShowCreateOrganizationModal}
      />
      <AccountManagerSection
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
      <StakeholdersSection
        stakeholders={contacts}
        setStakeholders={setContacts}
        showCreateStakeholderModal={showCreateContactModal}
        setShowCreateStakeholderModal={setShowCreateContactModal}
        role="Contact"
      />
      <StakeholdersSection
        stakeholders={stakeholders}
        setStakeholders={setStakeholders}
        showCreateStakeholderModal={showCreateStakeholderModal}
        setShowCreateStakeholderModal={setShowCreateStakeholderModal}
        role="Other"
      />
      <Form.Group className="mb-3" controlId="isExtended">
        <Form.Label>Is Extended</Form.Label>
        <Form.Select
          aria-label="isExtended"
          {...register("is_extended")}
          defaultValue={false}
        >
          <option value={false}>False</option>
          <option value={true}>True</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3" controlId="dateAwarded">
        <Form.Label>Date Awarded</Form.Label>
        <Form.Control type="date" {...register("dateAwarded")} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="billingType">
        <Form.Label>
          Billing Type <span style={{ color: "red" }}>*</span>
        </Form.Label>
        <Form.Select
          aria-label="billingType"
          {...register("billingType", { required: "Billing Type is required" })}
          isInvalid={!!errors.billingType}
        >
          <option value="Time and Materials">Time and Materials</option>
          <option value="Fixed Price">Fixed Price</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors.billingType?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="statement_of_work">
        <Form.Label>Statement of Work</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter Statement of Work"
          {...register("statement_of_work")}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="startDate">
        <Form.Label>Start Date</Form.Label>
        <Form.Control type="date" {...register("startDate")} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="endDate">
        <Form.Label>End Date</Form.Label>
        <Form.Control type="date" {...register("endDate")} />
      </Form.Group>
      <Form.Label>
        Opportunity <span style={{ color: "red" }}>*</span>
      </Form.Label>
      <InputGroup className="mb-3">
        <Form.Control disabled value={selectedOpp?.system_id || ""} />
        <Button
          variant="outline-secondary iconButton"
          id="button-addon2"
          onClick={() => setShowSearchOppModal(true)}
          aria-label="Search"
        >
          <Search />
        </Button>
        <Button
          variant="outline-secondary iconButton"
          id="button-addon2"
          onClick={() => setSelectedOpp(null)}
          aria-label="Clear"
        >
          <XLg />
        </Button>
      </InputGroup>
      <SearchOppsModal
        show={showSearchOppModal}
        setShow={setShowSearchOppModal}
        handleResult={setSelectedOpp}
      />
    </>
  );
};

export default CreateEngagementForm;
