import { Modal, Button, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import CreateDiscussionForm from "./CreateDiscussionForm";
import useUpdateDiscussion from "../../hooks/discussions/useUpdateDiscussion";
import "../../css/DetailsModal.css";
//modal component to update discussion
const UpdateDiscussionModal = ({ show, handleClose, discussion }) => {
    const { register, handleSubmit, reset, formState: { errors }, control, setValue } = useForm();
    const [selectedConsultants, setSelectedConsultants] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedLead, setSelectedLead] = useState(null);

    const [selectedEngagement, setSelectedEngagement] = useState(null);
    const [stakeholders, setStakeholders] = useState([]);
    const [showCreateStakeholderModal, setShowCreateStakeholderModal] = useState(false);
    const [showCreateConsultantModal, setShowCreateConsultantModal] = useState(false);
    useEffect(() => {
      if (discussion) {
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          return date.toISOString().split('T')[0];
      };
                      
            setValue("title", discussion?.title);
            setValue("type", discussion?.type);
            setValue("status", discussion?.status);
            setValue("discussion_date", formatDate(discussion?.discussion_date));
            setValue("length",discussion?.length);
            setSelectedLead(discussion?.lead);
            setSelectedConsultants(discussion?.participated_consultants);
            setSelectedEngagement(discussion?.related_engagement);
            setStakeholders(discussion?.participated_stakeholders);
            setSelectedUsers(discussion?.participated_users);
        }
    }, [discussion, setValue]);

  const filterNullValues = (data) => {
    return Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== null)
    );
  };



  const clearModal = () => {
    reset();
    setSelectedLead(null);
    setSelectedConsultants([]);
    setSelectedUsers([]);
    setSelectedEngagement(null);
    setStakeholders([]);
    setShowCreateStakeholderModal(false);
    setShowCreateConsultantModal(false);
};

  const handleCloseUpdate = () => {
    clearModal();
    handleClose();
};

  const { mutate } = useUpdateDiscussion();
  const submitForm = (data) => {
    data = { 
        ...data, 
        _id: discussion._id,
        lead: selectedLead?._id,
        related_engagement: selectedEngagement?._id,
        participated_consultants: selectedConsultants?.map(consultant => consultant._id),
        participated_users: selectedUsers?.map(user => user._id),
        participated_stakeholders: stakeholders?.map(stakeholder => stakeholder._id),
    };
    const filteredData = filterNullValues(data);
    mutate(filteredData);
    setShowCreateStakeholderModal(false);
    setShowCreateConsultantModal(false);
};



  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleCloseUpdate}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Discussion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(submitForm)}>
            <CreateDiscussionForm
                        errors={errors}
                        register={register}
                        control={control}
                        selectedConsultants={selectedConsultants}
                        setSelectedConsultants={setSelectedConsultants}
                        selectedLead={selectedLead}
                        setSelectedLead={setSelectedLead}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        selectedEngagement={selectedEngagement}
                        setSelectedEngagement={setSelectedEngagement}
                        showCreateConsultantModal={showCreateConsultantModal}
                        setShowCreateConsultantModal={setShowCreateConsultantModal}
                        stakeholders={stakeholders}
                        setStakeholders={setStakeholders}
                        showCreateStakeholderModal={showCreateStakeholderModal}
                        setShowCreateStakeholderModal={setShowCreateStakeholderModal}
            />

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="outline-secondary" onClick={clearModal}>
                Clear
              </Button>
              <Button variant="primary" onClick={handleSubmit(submitForm)}>
                Update Discussion
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateDiscussionModal;
