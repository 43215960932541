import React from "react";
import { Table, Button, Form } from "react-bootstrap";
import { Trash, ArrowRightSquare } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import ConsultantStatusBadge from "./ConsultantStatusBadge";
//component to display all consultants in a table in consultant page
const ConsultantsTable = ({
  data,
  handleShowDetails,
  handleStatusChange,
  handleArchive,
  handleResumeArchive,
}) => {
  const statusOptions = ["Pending", "Active", "Inactive"];
  const handleDeleteClick = (consultant) => {
    if (window.confirm("Are you sure you want to delete this consultant?")) {
        handleArchive(consultant);
    }
};
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Status</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Company Name</th>
          <th>OMS ID</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((consultant) => (
          <tr key={consultant?._id}>
            <td>
              <Button
                variant="link"
                onClick={() => handleShowDetails(consultant)}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                {consultant?.name}
              </Button>
              <ConsultantStatusBadge status={consultant?.status} />
            </td>
            <td>
              <Form.Select
                value={consultant?.status}
                onChange={(e) => handleStatusChange(consultant, e.target.value)}
              >
                {statusOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </td>
            <td>{consultant?.phone_number}</td>
            <td>{consultant?.email}</td>
            <td>{consultant?.company_name}</td>
            <td>{consultant?.oms_id}</td>
            <td className="table-actions">
              <Button
                variant="outline-secondary"
                className="me-2"
                onClick={() =>
                  consultant?.status === "Deleted"
                    ? handleResumeArchive(consultant)
                    : handleDeleteClick(consultant)
                }
              >
                <Trash />
              </Button>
              <Link
                to={`/consultants/${consultant?._id}`}
                className="btn btn-outline-secondary me-2"
                target="_blank"
              >
                <ArrowRightSquare />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ConsultantsTable;
