import { Modal, Button, Form } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import CreateTaskForm from "./CreateTaskForm";
import useCreateTask from "../../hooks/tasks/useCreateTask";
import "../../css/DetailsModal.css";
//modal component to create task
const CreateTaskModal = ({ show, setShow, selectedDiscussion, selectedEngagement, setSelectedDiscussion, setSelectedEngagement }) => {
    const { register, handleSubmit, reset, formState: { errors }, control } = useForm();
    const [selectedUsers, setSelectedUsers] = useState([]);

    const filterNullValues = (data) => {
        return Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== null  && value !== ''));
    };

    const clearModal = () => {
        reset();
        setSelectedUsers([]);
        setSelectedEngagement(null);
        setSelectedDiscussion(null);
    };

    const handleClose = () => {
        setShow(false);
        clearModal();
    };

    const { mutate } = useCreateTask();

    const submitForm = (data) => {
        data = { 
            ...data, 
            related_engagement: selectedEngagement?._id,
            related_discussion: selectedDiscussion?._id,
            assigned_to: selectedUsers.map(user => user._id),
        };
        
        const filteredData = filterNullValues(data);
        console.log(filteredData?.repeated_period);
        mutate(filteredData);
    };

    return (
        <Modal size="lg" show={show} onHide={handleClose} backdrop="static" keyboard={false} dialogClassName="custom-modal">
            <Modal.Header closeButton>
                <Modal.Title>Create Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form data-test-id="create-task-form" onSubmit={handleSubmit(submitForm)}>
                    <CreateTaskForm
                        errors={errors}
                        register={register}
                        control={control}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        selectedEngagement={selectedEngagement}
                        setSelectedEngagement={setSelectedEngagement}
                        selectedDiscussion={selectedDiscussion}
                        setSelectedDiscussion={setSelectedDiscussion}
                    />
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                        <Button variant="outline-secondary" onClick={clearModal}>Clear</Button>
                        <Button variant="primary" type="submit" onClick={handleSubmit(submitForm)}>Create Task</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateTaskModal;
