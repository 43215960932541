import React, { useState } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CreateEngagementForm from './CreateEngagementForm';
import 'react-toastify/dist/ReactToastify.css';
import useCreateEngagement from "../../hooks/engagements/useCreateEngagement";
import "../../css/DetailsModal.css";
//modal component to create engagement
const CreateEngagementModal = ({ show, setShow }) => {
    const [showSearchOppModal, setShowSearchOppModal] = useState(false);
    const [showCreateConsultantModal, setShowCreateConsultantModal] = useState(false);
    const [showCreateOrganizationModal, setShowCreateOrganizationModal] = useState(false);
    const { register, handleSubmit, reset, formState: { errors }, control } = useForm();
    const [selectedConsultant, setSelectedConsultant] = useState(null);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedOpp, setSelectedOpp] = useState(null);
    const [stakeholders, setStakeholders] = useState([]);
    const [showCreateStakeholderModal, setShowCreateStakeholderModal] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [showCreateContactModal, setShowCreateContactModal] = useState(false);

    const filterNullValues = (data) => {
        return Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== null));
    };

    const clearModal = () => {
        reset();
        setSelectedConsultant(null);
        setSelectedOrganization(null);
        setStakeholders([]);
        setContacts([]);
        setSelectedOpp(null);
        setSelectedUser(null);
        setShowSearchOppModal(false);
        setShowCreateConsultantModal(false);
        setShowCreateOrganizationModal(false);
        setShowCreateStakeholderModal(false);
        setShowCreateContactModal(false);
    };

    const handleClose = () => {
        clearModal();
        setShow(false);
    };

    const { mutate } = useCreateEngagement();
    const submitForm = (data) => {
        data = { 
            ...data, 
            opp: selectedOpp?._id, 
            consultant: selectedConsultant?._id, 
            client: selectedOrganization?._id, 
            account_manager: selectedUser?._id, 
            contacts: contacts.map(contact => contact._id), 
            stakeholders: stakeholders.map(stakeholder => stakeholder._id)
        };
        const filteredData = filterNullValues(data);
        mutate(filteredData);
        setShowSearchOppModal(false);
        setShowCreateConsultantModal(false);
        setShowCreateOrganizationModal(false);
        setShowCreateStakeholderModal(false);
        setShowCreateContactModal(false);
    };

    return (
        <Modal size="lg" show={show} onHide={handleClose} backdrop="static" keyboard={false} dialogClassName="custom-modal">
            <Modal.Header closeButton>
                <Modal.Title>Create Engagement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form data-test-id="create-engagement-form" onSubmit={handleSubmit(submitForm)}>
                    <CreateEngagementForm
                        errors={errors}
                        register={register}
                        control={control}
                        selectedConsultant={selectedConsultant}
                        setSelectedConsultant={setSelectedConsultant}
                        selectedOrganization={selectedOrganization}
                        setSelectedOrganization={setSelectedOrganization}
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                        selectedOpp={selectedOpp}
                        setSelectedOpp={setSelectedOpp}
                        showSearchOppModal={showSearchOppModal}
                        setShowSearchOppModal={setShowSearchOppModal}
                        showCreateConsultantModal={showCreateConsultantModal}
                        setShowCreateConsultantModal={setShowCreateConsultantModal}
                        showCreateOrganizationModal={showCreateOrganizationModal}
                        setShowCreateOrganizationModal={setShowCreateOrganizationModal}
                        contacts={contacts}
                        setContacts={setContacts}
                        showCreateContactModal={showCreateContactModal}
                        setShowCreateContactModal={setShowCreateContactModal}
                        stakeholders={stakeholders}
                        setStakeholders={setStakeholders}
                        showCreateStakeholderModal={showCreateStakeholderModal}
                        setShowCreateStakeholderModal={setShowCreateStakeholderModal}
                    />
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="outline-secondary" onClick={clearModal}>
                            Clear
                        </Button>
                        <Button variant="primary" type="submit">
                            Create Engagement
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateEngagementModal;
