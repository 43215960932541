import React from "react";
import { Table, Button, Form } from "react-bootstrap";
import { Trash, ArrowRightSquare } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import EngagementStatusBadge from "./EngagementStatusBadge";
// Table component to display engagements in the engagement page
const EngagementsTable = ({
  data,
  handleShowDetails,
  handleArchive,
  handleResumeArchive,
  handleStateChangeWithReason,
}) => {
  const stateOptions = ["Offer", "Initiation", "Active", "Closed", "Deleted"];
  const handleDeleteClick = (engagement) => {
    if (window.confirm("Are you sure you want to delete this engagement?")) {
        handleArchive(engagement);
    }
};
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>State</th>
          <th>Consultant</th>
          <th>Account Manager</th>
          <th>Client</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((engagement) => (
          <tr key={engagement?._id}>
            <td>
              <Button
                variant="link"
                onClick={() => handleShowDetails(engagement)}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                {engagement?.displayName}
              </Button>
              <EngagementStatusBadge
                state={engagement?.state}
                is_extended={engagement?.is_extended}
              />
            </td>
            <td>
              <Form.Select
                value={engagement?.state}
                onChange={(e) =>
                  handleStateChangeWithReason(engagement, e.target.value)
                }
              >
                {stateOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
              {engagement?.state === "Closed" && (
                <div>{engagement?.reason_for_close}</div>
              )}
            </td>
            <td>{engagement?.consultant?.name}</td>
            <td>{engagement?.account_manager?.username}</td>
            <td>{engagement?.client?.organization_name}</td>
            <td>
            {engagement?.startDate
                ? new Date(engagement?.startDate).toISOString().split("T")[0]
                : ""}
            </td>
            <td>{engagement?.endDate
                ? new Date(engagement?.endDate).toISOString().split("T")[0]
                : ""}</td>
            <td className="table-actions">
              <Button
                variant="outline-secondary"
                className="me-2"
                onClick={() =>
                  engagement?.state === "Deleted"
                    ? handleResumeArchive(engagement)
                    : handleDeleteClick(engagement)
                }
              >
                <Trash />
              </Button>
              <Link
                to={`/engagements/${engagement?._id}`}
                className="btn btn-outline-secondary me-2"
                target="_blank"
              >
                <ArrowRightSquare />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default EngagementsTable;
