import React, { useState, useContext } from "react";
import PageHeader from "../../components/PageHeader";
import { Container, Tabs, Tab, Button, Row, Col } from "react-bootstrap";
import Loader from "../../components/Loader/Loader";
import ConsultantDetailsModal from "../../components/Consultants/ConsultantDetailsModal";
import ConsultantsTable from "../../components/Consultants/ConsultantsTable";
import SearchConsultantsModal from "../../components/Consultants/SearchConsultantsModal";
import useFetchConsultants from "../../hooks/consultants/useFetchConsultants";
import useUpdateConsultant from "../../hooks/consultants/useUpdateConsultant";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../../context/AuthProvider";
import { Search } from "react-bootstrap-icons";

const ConsultantsPage = () => {
  const pageTitle = "Consultants";
  const [selectedConsultant, setSelectedConsultant] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const { auth } = useContext(AuthContext);
  const { isLoading, error, data } = useFetchConsultants(
    convertFiltersToParams(activeTab, auth)
  );
  const filteredData = data?.filter(consultant => consultant.status !== "Deleted");  
  const handleShowDetails = (consultant) => {
    setSelectedConsultant(consultant);
    setShowDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedConsultant(null);
  };

  const { mutate } = useUpdateConsultant();

  const handleArchive = (consultant) => {
    mutate({ _id: consultant._id, status: "Deleted" });
  };

  const handleResumeArchive = (consultant) => {
    mutate({ _id: consultant._id, status: "Pending" });
  };

  const handleStatusChange = (consultant, newStatus) => {
    mutate({ _id: consultant._id, status: newStatus });
  };
  if (isLoading) return <Loader />;

  if (error) return "An error occured: " + error.message;

  return (
    <>
      <Container className="page-container">
        <PageHeader title={pageTitle} className="ems-page-title" />
        <Row className="mb-3">
          <Col>
            <Button
              variant="outline-secondary iconButton"
              onClick={() => setShowSearchModal(true)}
            >
              <Search /> Search
            </Button>
          </Col>
        </Row>
        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
          <Tab eventKey="all" title="All">
            <ConsultantsTable
              data={filteredData}
              handleShowDetails={handleShowDetails}
              handleStatusChange={handleStatusChange}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
            />
          </Tab>
          <Tab eventKey="Pending" title="Pending">
            <ConsultantsTable
              data={data}
              handleShowDetails={handleShowDetails}
              handleStatusChange={handleStatusChange}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
            />
          </Tab>
          <Tab eventKey="Active" title="Active">
            <ConsultantsTable
              data={data}
              handleShowDetails={handleShowDetails}
              handleStatusChange={handleStatusChange}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
            />
          </Tab>
          <Tab eventKey="Inactive" title="Inactive">
            <ConsultantsTable
              data={data}
              handleShowDetails={handleShowDetails}
              handleStatusChange={handleStatusChange}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
            />
          </Tab>
          <Tab eventKey="Deleted" title="Deleted">
            <ConsultantsTable
              data={data}
              handleShowDetails={handleShowDetails}
              handleStatusChange={handleStatusChange}
              handleArchive={handleArchive}
              handleResumeArchive={handleResumeArchive}
            />
          </Tab>
        </Tabs>
        <ConsultantDetailsModal
          show={showDetailsModal}
          handleClose={handleCloseDetailsModal}
          consultant={selectedConsultant}
        />
        <SearchConsultantsModal
          show={showSearchModal}
          handleClose={() => setShowSearchModal(false)}
        />
      </Container>
    </>
  );
};

const convertFiltersToParams = (status, auth) => {
  let params = {};
  if (status && status !== "all") params["status"] = status;
  return params;
};

export default ConsultantsPage;
