import { useQuery } from "@tanstack/react-query";
import { apiSearchOrganizations } from "../../api/organizations"
import useAxiosPrivate from "../useAxiosPrivate";
import { toast } from 'react-toastify';

const useSearchOrganizations = (searchTerm) => {
    const axiosPrivate = useAxiosPrivate();

    return useQuery({
        queryKey: ["organizationSearch", searchTerm],
        enabled: false,
        queryFn: async () => apiSearchOrganizations(axiosPrivate, searchTerm),
        onError: (error) => {
            if (error.response && error.response.status === 422) {
                const errors = error.response.data.errors;
                errors.forEach(err => {
                    toast.error(`${err.path}: ${err.msg}`);
                });
            } else {
                toast.error('Error searching organizations');
            }
        }
    });
};

export default useSearchOrganizations;