
import "./loader.css"

const Loader = () => {
    return (
        <>
        <div className="loading-wrapper">
            <div className="lds-dual-ring">

            </div>
        </div>
        </>
    )
}

export default Loader