import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import TaskStatusBadge from "./TaskStatusBadge";
import UpdateTaskModal from "./UpdateTaskModal";
import { PencilSquare, ArrowRightSquare } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
//table component to display the details of a task in task details page
const TaskDetailsTable = ({ task }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const handleUpdateTask = () => {
    setShowEditModal(true);
  };
  const handleCloseUpdateModal = () => {
    setShowEditModal(false);
  };
  return (
    <>
      <Table bordered>
        <tbody>
          <tr>
            <th></th>
            <td>
              <Button
                variant="outline-secondary"
                className="me-2"
                onClick={() => handleUpdateTask()}
              >
                <PencilSquare />
              </Button>
              <Link
                to={`/tasks/${task?._id}`}
                className="btn btn-outline-secondary me-2"
                target="_blank"
              >
                <ArrowRightSquare />
              </Link>
            </td>
          </tr>
          <tr>
            <th>Title</th>
            <td>
              {task?.title} <TaskStatusBadge type={task?.type} status={task?.status} priority={task?.priority} viewed={task?.seen.viewed} />
            </td>
          </tr>
          <tr>
            <th>Assigned To</th>
            <td>
              {task?.assigned_to && task?.assigned_to.length > 0
                ? task?.assigned_to.map((person) => person.username).join(", ")
                : ""}
            </td>
          </tr>
          <tr>
            <th>Created By</th>
            <td>{task?.created_by?.username}</td>
          </tr>
          {task?.state === "completed" && (
            <tr>
              <tr>
                <th>Completed By</th>
                <td>{task?.completed_by?.username}</td>
              </tr>
              <tr>
                <th>Completed_at</th>
                <td>
                {task?.task?.completed_at
                  ? new Date(task?.completed_at).toISOString().split("T")[0]
                  : ""}
              </td>
              </tr>
            </tr>
          )}
          <tr>
            <th>Deadline</th>
            <td>
              {task?.deadline
                ? new Date(task?.deadline).toISOString().split("T")[0]
                : ""}
            </td>
          </tr>
          <tr>
            <th>Reminder</th>
            <td>{task?.reminder? new Date(task?.reminder).toISOString().split("T")[0]
                : ""}</td>
          </tr>
          <tr>
            <th>Last View At</th>
            <td>
              {task?.seen?.last_viewed_date
                ? new Date(task?.seen.last_viewed_date).toISOString().split("T")[0]
                : ""}
            </td>
          </tr>
          <tr>
            <th>Repeated Period</th>
            <td>{task?.repeated_period}</td>
          </tr>
          <tr>
            <th>Related Engagement</th>
            <td>{task?.related_engagement?.displayName}</td>
          </tr>
          <tr>
            <th>Related Discussion</th>
            <td>{task?.related_discussion?.title}</td>
          </tr>
          <tr>
            <th>Notes</th>
            <td dangerouslySetInnerHTML={{ __html: task?.notes }}></td>
          </tr>
        </tbody>
      </Table>
      <UpdateTaskModal
        show={showEditModal}
        handleClose={handleCloseUpdateModal}
        task={task}
      />
    </>
  );
};

export default TaskDetailsTable;
