import React from "react";
import { Dropdown, Form } from "react-bootstrap";
// Dropdown list component to filter tasks
const FilterTaskDropdownList = ({ filters, handleFilterChange }) => {
  return (
    <>
      <Dropdown.Item as="div">
        <Form.Check
          type="checkbox"
          label="Viewed"
          name="viewed"
          checked={filters.viewed}
          onChange={handleFilterChange}
        />
      </Dropdown.Item>
      <Dropdown.Item as="div">
        <Form.Check
          type="checkbox"
          label="Not Viewed"
          name="notViewed"
          checked={filters.notViewed}
          onChange={handleFilterChange}
        />
      </Dropdown.Item>
      <Dropdown.Item as="div">
        <Form.Label>Priority</Form.Label>
        <Form.Check
          type="radio"
          label="High"
          name="priority"
          value="High"
          checked={filters.priority === "High"}
          onChange={handleFilterChange}
        />
        <Form.Check
          type="radio"
          label="Medium"
          name="priority"
          value="Medium"
          checked={filters.priority === "Medium"}
          onChange={handleFilterChange}
        />
        <Form.Check
          type="radio"
          label="Low"
          name="priority"
          value="Low"
          checked={filters.priority === "Low"}
          onChange={handleFilterChange}
        />
        <Form.Check
          type="radio"
          label="Show All"
          name="priority"
          value=""
          checked={filters.priority === ""}
          onChange={handleFilterChange}
        />
      </Dropdown.Item>
      <Dropdown.Item as="div">
        <Form.Label>Type</Form.Label>
        <Form.Check
          type="radio"
          label="Regular"
          name="type"
          value="Regular"
          checked={filters.type === "Regular"}
          onChange={handleFilterChange}
        />
        <Form.Check
          type="radio"
          label="Recurring"
          name="type"
          value="Recurring"
          checked={filters.type === "Recurring"}
          onChange={handleFilterChange}
        />
        <Form.Check
          type="radio"
          label="Show All"
          name="type"
          value=""
          checked={filters.type === ""}
          onChange={handleFilterChange}
        />
      </Dropdown.Item>
      <Dropdown.Item as="div">
        <Form.Check
          type="checkbox"
          label="Created By Me"
          name="createdByMe"
          checked={filters.createdByMe}
          onChange={handleFilterChange}
        />
      </Dropdown.Item>
      <Dropdown.Item as="div">
        <Form.Check
          type="checkbox"
          label="Assigned To Me"
          name="assignedToMe"
          checked={filters.assignedToMe}
          onChange={handleFilterChange}
        />
      </Dropdown.Item>
      <Dropdown.Item as="div">
        <Form.Check
          type="checkbox"
          label="Completed by Me"
          name="completedByMe"
          checked={filters.completedByMe}
          onChange={handleFilterChange}
        />
      </Dropdown.Item>
    </>
  );
};

export default FilterTaskDropdownList;
