import React from "react";
import { Form, Button } from "react-bootstrap";
import { PlusCircle } from "react-bootstrap-icons";
import SearchConsultants from "./SearchConsultants";
import CreateConsultantModal from "./CreateConsultantModal";
//component to manage the selected consultant
const ConsultantSection = ({
  selectedConsultant,
  setSelectedConsultant,
  showCreateConsultantModal,
  setShowCreateConsultantModal,
}) => {
  return (
    <>
      <Form.Group className="mb-3" controlId="consultant">
        <Form.Label>
          Consultant<span style={{ color: "red" }}>*</span>
        </Form.Label>
        <SearchConsultants handleResult={setSelectedConsultant} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="createConsultant">
        <Button
          variant="link"
          onClick={() => setShowCreateConsultantModal(true)}
        >
          <PlusCircle /> Create new consultant
        </Button>
      </Form.Group>
      <Form.Group className="mb-3" controlId="selectedConsultant">
        <Form.Label>Selected Consultant</Form.Label>
        <Form.Control
          type="text"
          value={selectedConsultant?.name || ""}
          readOnly
        />
      </Form.Group>
      <CreateConsultantModal
        show={showCreateConsultantModal}
        setShow={setShowCreateConsultantModal}
        handleResult={setSelectedConsultant}
      />
    </>
  );
};

export default ConsultantSection;
